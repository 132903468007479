import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'react-i18nify';
import SectionHeader from './SectionHeader';
import Paper from './CudaPaper';
import { QuarantineIcon } from './icons';
import QuarantineTable from '../containers/quarantine/Table';
import QuarantineActionBar from './quarantine/ActionBar';

const rangeOptions = [
  {
    key: 'day',
    label: 'Last 24 hours',
  },
  {
    key: 'week',
    label: 'Last 7 days',
  },
  {
    key: 'month',
    label: 'Last 30 days',
  },
];

class QuarantineComponent extends Component {
  state = {
    selected: [],
  };

  setSelected = selected => {
    this.setState({
      selected: selected,
    });
  };

  componentDidMount = () => {
    this.props.initData();
  };

  handleQuery = query => {
    this.props.handleQuery(query);
  };

  render() {
    const { classes, updateRange, query, selectedIndex } = this.props;

    return (
      <DocumentTitle title={I18n.t('components.quarantine.page_title')}>
        <div>
          <SectionHeader
            icon={<QuarantineIcon />}
            title={I18n.t('components.quarantine.title')}
            help={true}
          />
          <Grid id="quarantine" container justify="space-between" spacing={24}>
            <Grid item xs={12}>
              <QuarantineActionBar
                updateRange={updateRange}
                query={query}
                handleQuery={this.handleQuery}
                rangeOptions={rangeOptions}
                selectedIndex={selectedIndex}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <QuarantineTable
                  selected={this.state.selected}
                  setSelected={this.setSelected}
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}
QuarantineComponent.propTypes = {
  query: PropTypes.string.isRequired,
  handleQuery: PropTypes.func.isRequired,
  updateRange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    height: '40px',
    float: 'right',
    fontWeight: 'normal',
    paddingLeft: '36px',
    paddingRight: '36px',
    margin: '0 5px',
  },
});

export default withStyles(styles)(QuarantineComponent);
