import { connect } from 'react-redux';
import EditPolicyModal from '../../components/advanced_filtering/EditPolicyModal';

import {
  // TYPE_OPTIONS,
  editRule,
  closeRule,
} from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  // const typeIndex = state.advanced_filtering.ruleModal.selectedTypeIndex;
  // let disabled = true;

  // if (TYPE_OPTIONS[typeIndex]['key'] === 'categories') {
  //   disabled =
  //     state.advanced_filtering.ruleModal.categories.length > 0 ? false : true;
  // } else if (TYPE_OPTIONS[typeIndex]['key'] === 'domains') {
  //   disabled =
  //     state.advanced_filtering.ruleModal.domains.length > 0 ? false : true;
  // } else if (TYPE_OPTIONS[typeIndex]['key'] === 'url') {
  //   disabled = state.advanced_filtering.ruleModal.url.length > 0 ? false : true;
  // }

  return {
    disabled: false,
    loading: state.advanced_filtering.selectedRule.loading,
    overflow: Boolean(state.advanced_filtering.selectedRule.display),
    showModal: state.advanced_filtering.selectedRule.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: rule => {
    dispatch(editRule(rule));
  },
  closeDialog: () => {
    dispatch(closeRule());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPolicyModal);
