import { connect } from 'react-redux';
import CategoryPolicy from '../../components/basic_filtering/CategoryPolicy';
import {
  updatedSelectedCategories,
  setSelectedCategories,
} from '../../actions/basic_filtering';

const mapStateToProps = state => ({
  selected: state.basic_filtering.categoryConfig.selected,
  editSelected: state.basic_filtering.categoryConfig.editSelected,
  superCategories: state.basic_filtering.superCategories,
  selectedCategories: state.basic_filtering.categoryConfig.categories,
  levels: state.basic_filtering.categoryLevels,
  customPolicies: state.basic_filtering.customPolicies,
});

const mapDispatchToProps = dispatch => ({
  updateCategories: (selected, added, removed) => {
    dispatch(updatedSelectedCategories(selected, added, removed));
  },
  setCategories: (selected, categories) => {
    dispatch(setSelectedCategories(selected, categories));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryPolicy);
