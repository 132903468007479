import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { I18n } from 'react-i18nify';

import CudaTableTitle from '../CudaTableTitle';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  pathColumn: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  fileColumn: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tableTitle: {
    '& > th': {
      borderTop: '1px solid #DDDDDD',
    },
  },
  reportTableBody: {
    '& > tr': {
      height: '40px',
    },
  },
});

class PieTableComponent extends Component {
  state = {
    icons: ['desc', 'none', 'none', 'none', 'none'],
  };

  render() {
    const { classes, entries, col1, col2 } = this.props;

    return (
      <div className={classes.root}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableTitle}>
              <TableCell>
                <CudaTableTitle value={I18n.t('shared.' + col1)} />
              </TableCell>
              <TableCell>
                <CudaTableTitle value={I18n.t('shared.' + col2)} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.reportTableBody}>
            {entries.map((n, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>{n['key'] || 'ANON'}</TableCell>
                  <TableCell>{n['doc_count']}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

PieTableComponent.propTypes = {
  entries: PropTypes.array.isRequired,
  col1: PropTypes.string.isRequired,
  col2: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'ReportsPieTableComponent' })(
  PieTableComponent
);
