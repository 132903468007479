import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import SectionHeader from './SectionHeader';
import { PeopleIcon } from './icons';

import UserTypeSelector from '../containers/user_authentication/UserTypeSelector';
import LocalUsers from '../containers/user_authentication/LocalUsers';
import DirectoryServices from './user_authentication/DirectoryServices';

import LocalUserPrompt from './user_authentication/LocalUserPrompt';

import Modal from './modal/Modal';

class UserAuthentication extends React.Component {
  componentDidMount = () => {
    const { initData } = this.props;
    initData();
  };

  render() {
    const {
      classes,
      userType,
      loading,
      closePrompt,
      confirmPrompt,
      configured,
      modal,
    } = this.props;

    return (
      <DocumentTitle title={I18n.t('userAuthentication.pageTitle')}>
        <React.Fragment>
          <SectionHeader
            icon={<PeopleIcon secondaryColor="#0088CE" />}
            title={I18n.t('userAuthentication.title')}
          >
            <Translate value="userAuthentication.subheader" />
          </SectionHeader>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <UserTypeSelector disabled={loading} />
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              {(() =>
                ({
                  directory: (
                    <DirectoryServices
                      configured={configured}
                      loading={loading}
                    />
                  ),
                  local: <LocalUsers loading={loading} />,
                }[userType]))()}
            </Grid>
          </Grid>
          <Modal
            showModal={modal.show && modal.name === 'confirmTypeChange'}
            title={I18n.t('userAuthentication.configureLocal')}
            saveBtnText={I18n.t('userAuthentication.continue')}
            actionBtnClass={classes.deleteBtn}
            contentClass={classes.shortModal}
            closeDialog={closePrompt}
            processing={modal.processing}
            buttonAction={confirmPrompt}
          >
            <LocalUserPrompt />
          </Modal>
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

const styles = {
  divider: {
    marginTop: '8px',
  },
  deleteBtn: {
    backgroundColor: '#DD1E31',
    '&:hover': {
      backgroundColor: '#B71C1C',
    },
  },
  shortModal: {
    width: '500px',
  },
};

UserAuthentication.propTypes = {};

export default withStyles(styles, { name: 'UserAuthentication' })(
  UserAuthentication
);
