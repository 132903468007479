import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import { Switch, Route } from 'react-router-dom';
import { I18n, Translate } from 'react-i18nify';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import classNames from 'classnames';

import Accounts from './containers/Accounts';
import Dashboard from './containers/Dashboard';
import AuditLog from './containers/AuditLog';
import ATPSettings from './ATPSettings';
import ATPLogs from './containers/ATPLogs';
import WebFilteringLogs from './containers/WebFilteringLogs';
import { connect } from 'react-redux';
import './Main.css';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CudaNavigator from './containers/CudaNavigator';
import AccountSettings from './components/AccountSettings';
import Quarantine from './containers/Quarantine';
import MSPDashboard from './containers/MSPDashboard';
import CudaLink from './components/CudaLink';
import Header from './components/Header';
import Footer from './components/Footer';
import AccountFooter from './components/AccountFooter';
import SessionModal from './containers/SessionModal';
import Notifications from './containers/Notifications';
import {
  retrieveAccount,
  unselectAccount,
  deleteSession,
} from './actions/account';
import Auth from './lib/Auth';
import Reports from './containers/Reports';
import Devices from './containers/Devices';
import BasicFilter from './containers/BasicFilter';
import AdvancedFilter from './containers/AdvancedFilter';
import StatusBar from './containers/MainStatusBar';
import SmbAuditLog from './containers/SmbAuditLog';
import DeployAgent from './containers/DeployAgent';
import SMBAccountSettings from './containers/SMBAccountSettings';
import BlockPageSettings from './containers/BlockPageSettings';

import UserAuthentication from './containers/UserAuthentication';

import { STAGE, PRIMARY_DNS, SECONDARY_DNS } from './lib/Env';

import {
  GaugeIcon,
  BackArrowIcon,
  CloseIcon,
  AuditLogIcon,
  AccountIcon,
} from './components/icons';

const styles = {
  wrapper: {
    width: '100% !important',
    margin: '0 !important',
    position: 'relative',
    minHeight: '100%',
  },
  bodyWrapper: {
    padding: '0px 16px 16px 16px !important',
  },
  container: {
    padding: '0px !important',
    margin: '0px 0 48px 0 !important',
    width: '100% !important',
  },
  header: {
    padding: '0 !important',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '55px',
  },
  mspNav: {
    marginTop: '55px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EEEEEE',
    boxShadow: '0 2px 0 0 #DDDDDD',
    position: 'relative',
  },
  navHeader: {
    padding: '6px',
    borderBottom: '1px solid #DDDDDD',
  },
  closeHeader: {
    padding: '6px',
    justifyContent: 'flex-end',
    borderBottom: '1px solid #DDDDDD',
  },
  navHeaderName: {
    marginLeft: '25px',
    fontSize: '19px',
    lineHeight: '28px',
    fontWeight: 300,
  },
  backIcon: {
    marginTop: '4px',
  },
  footer: {
    padding: '24px 0px 15px 0px !important',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '64px',
  },
  buttonNavRoot: {
    bottom: '-2px',
    backgroundColor: '#EEEEEE',
    position: 'absolute',
    height: '62px',
  },
  root: {
    borderColor: '#DDDDDD !important',
    borderBottom: '2px solid',
    minWidth: '100px',
  },
  selected: {
    borderColor: '#0088CE !important',
    color: 'black',
  },
  btnLabel: {
    margin: '5px 0px -5px 0px',
    fontSize: '12px',
    lineHeight: '14px',
  },
  marginTop: {
    marginTop: '60px !important',
  },
  closeText: {
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '19px',
    marginTop: '1px',
    textTransform: 'uppercase',
    color: '#000000',
  },
  closeButton: {
    cursor: 'pointer',
    float: 'right',
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      height: 16,
      width: 16,
      marginLeft: '16px',
    },
  },
  dnsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > div': {
      margin: '0 5px',
    },
  },
  dnsRow: {
    display: 'flex',
    alignItems: 'center',
  },
  dnsText: {
    fontSize: '14px',
    color: 'rgba(0,0,0,0.7)',
    paddingRight: '5px',
  },
  dnsNumeric: {
    fontSize: '14px',
  },
  navWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
};

const mspNavMap = ['/msp_dashboard', '/accounts', '/audit_log'];
let previousPage = '';

class Main extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  componentDidMount() {
    const dtoken = Auth.get_sso_token();
    this.props.retrieveAccount(
      dtoken.email,
      dtoken.account,
      dtoken.accounts,
      dtoken.services,
      dtoken.sub,
      dtoken.zoneinfo
    );
  }

  logOut() {
    this.props.deleteSession(Auth.get_token());
    Auth.logout();
    this.props.history.push('/login');
  }

  handleClose() {
    if (previousPage && previousPage !== '/authenticator') {
      this.props.history.goBack();
    } else if (this.props.account.msp) {
      this.props.history.push('/accounts');
    } else {
      this.props.history.push('/dashboard');
    }
  }

  navigate(index) {
    this.props.history.push(mspNavMap[index]);
  }

  render() {
    const classes = this.props.classes;
    const { account, unselectAccount } = this.props;

    if (this.props.location.pathname === '/route_view') {
      if (this.props.account.msp) {
        this.props.history.push('/accounts');
      } else {
        this.props.history.push('/dashboard');
      }
    }

    // Log the page views for Google Analytics
    ReactGA.pageview(
      '/' + STAGE + this.props.location.pathname + this.props.location.search
    );
    const mspIndex = mspNavMap.indexOf(this.props.location.pathname);
    const mspLevel = mspIndex !== -1;
    const container = classNames(classes.container, {
      [classes.marginTop]: !mspLevel,
    });
    const accountSettings =
      this.props.location.pathname === '/account_settings';
    if (this.props.location.state && this.props.location.state.from) {
      previousPage = this.props.location.state.from;
    }

    return (
      <Grid container className={classes.wrapper}>
        <Grid item xs={12} className={classes.header}>
          <Header
            trial={account.trial}
            logout={() => {
              this.logOut();
            }}
          />
        </Grid>
        <Grid item className={container}>
          {mspLevel ? (
            <Grid container className={classes.mspNav}>
              <BottomNavigation
                value={mspIndex}
                onChange={(event, index) => {
                  this.navigate(index);
                }}
                showLabels
                classes={{ root: classes.buttonNavRoot }}
              >
                {account.subscriptions.atp ? (
                  <BottomNavigationAction
                    data-test-id="overviewTab"
                    label={I18n.t(
                      'components.dashboard.overview'
                    ).toUpperCase()}
                    icon={<GaugeIcon />}
                    classes={{
                      root: classes.root,
                      selected: classes.selected,
                      label: classes.btnLabel,
                    }}
                  />
                ) : null}
                <BottomNavigationAction
                  data-test-id="accountsTab"
                  label={I18n.t('components.dashboard.accounts').toUpperCase()}
                  icon={<AccountIcon />}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                    label: classes.btnLabel,
                  }}
                />
                <BottomNavigationAction
                  data-test-id="auditLogTab"
                  label={I18n.t('components.dashboard.auditLog').toUpperCase()}
                  icon={<AuditLogIcon />}
                  classes={{
                    root: classes.root,
                    selected: classes.selected,
                    label: classes.btnLabel,
                  }}
                />
              </BottomNavigation>
            </Grid>
          ) : null}
          <div className={classes.bodyWrapper}>
            {accountSettings ? (
              <Grid container className={classes.closeHeader}>
                <div
                  data-test-id="account-settings-close"
                  className={classes.closeButton}
                  onClick={() => {
                    this.handleClose();
                  }}
                >
                  <Translate
                    value="shared.close"
                    className={classes.closeText}
                  />
                  <CloseIcon color="#5F5F5F" />
                </div>
              </Grid>
            ) : null}
            {!mspLevel && !accountSettings && account.selected ? (
              <Grid container className={classes.navHeader}>
                {account.msp && (
                  <Grid item xs={6} className={classes.navWrapper}>
                    <CudaLink
                      data-test-id="backBtn"
                      to="/accounts"
                      onClick={() => {
                        unselectAccount();
                      }}
                    >
                      <BackArrowIcon className={classes.backIcon} />
                    </CudaLink>
                    <span
                      data-test-id="accountName"
                      className={classes.navHeaderName}
                    >
                      {account.name}
                    </span>
                  </Grid>
                )}
                {account.subscriptions.dns && (
                  <Grid
                    item
                    xs={account.msp ? 6 : 12}
                    className={classes.dnsWrapper}
                  >
                    <div
                      className={classes.dnsRow}
                      data-test-id="primaryDnsWrapper"
                    >
                      <Translate
                        value="shared.primaryDns"
                        tag="div"
                        className={classes.dnsText}
                      />
                      <span className={classes.dnsNumeric}>{PRIMARY_DNS}</span>
                    </div>
                    <div
                      className={classes.dnsRow}
                      data-test-id="secondaryDnsWrapper"
                    >
                      <Translate
                        value="shared.secondaryDns"
                        tag="div"
                        className={classes.dnsText}
                      />
                      <span className={classes.dnsNumeric}>
                        {SECONDARY_DNS}
                      </span>
                    </div>
                  </Grid>
                )}
              </Grid>
            ) : null}
            <Switch>
              <Route exact path="/msp_dashboard" component={MSPDashboard} />
              <Route exact path="/accounts" component={Accounts} />
              <Route exact path="/audit_log" component={AuditLog} />
              <Route
                exact
                path="/account_settings"
                component={AccountSettings}
              />
              {account.selected ? <MainRoute classes={classes} /> : null}
            </Switch>
          </div>
        </Grid>
        <StatusBar />
        <SessionModal />
        <Grid item xs={12} className={classes.footer}>
          <Footer />
          <AccountFooter
            accountID={account.selected || account.id}
            serial={account.serial}
          />
        </Grid>
      </Grid>
    );
  }
}

const MainRoute = ({ classes, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Grid id="cuda-body" container spacing={24}>
        <Grid item xs={4} md={3} lg={2}>
          <CudaNavigator />
        </Grid>
        <Grid item xs={8} md={9} lg={10} className={classes.content}>
          <Switch>
            <Route exact path="/dashboard" component={Dashboard} />
            <Route path="/atp" component={ATPSettings} />
            <Route path="/atp_logs" component={ATPLogs} />
            <Route path="/web_filtering_logs" component={WebFilteringLogs} />
            <Route path="/notifications" component={Notifications} />
            <Route path="/quarantine" component={Quarantine} />
            <Route path="/reports" component={Reports} />
            <Route path="/devices" component={Devices} />
            <Route path="/basic_filtering" component={BasicFilter} />
            <Route path="/advanced_filtering" component={AdvancedFilter} />
            <Route path="/account_audit_logs" component={SmbAuditLog} />
            <Route path="/deploy" component={DeployAgent} />
            <Route path="/customization" component={SMBAccountSettings} />
            <Route path="/block_page_settings" component={BlockPageSettings} />
            <Route path="/users" component={UserAuthentication} />
          </Switch>
        </Grid>
      </Grid>
    )}
  />
);

const mapStateToProps = (state, ownProps) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteSession: token => dispatch(deleteSession(token)),
  unselectAccount: () => dispatch(unselectAccount()),
  retrieveAccount: (email, account, accounts, services, user_id, time_zone) => {
    dispatch(
      retrieveAccount(email, account, accounts, services, user_id, time_zone)
    );
  },
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { name: 'eps-main' })(Main)));
