import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import ActivateForm from '../signup/ActivateForm';

const ActivateModal = ({
  classes,
  showModal,
  closeModal,
  processing,
  submitHandler,
}) => (
  <Dialog open={showModal} maxWidth={false} onEscapeKeyDown={closeModal}>
    <DialogContent>
      <div className={classes.content}>
        <ActivateForm
          cancel={closeModal}
          submit={submitHandler}
          processing={processing}
        />
      </div>
    </DialogContent>
  </Dialog>
);

const styles = {
  content: {
    maxWidth: '450px',
    padding: '20px',
  },
};

ActivateModal.propTypes = {
  classes: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  submitHandler: PropTypes.func.isRequired,
};

export default withStyles(styles, { name: 'ActivateModalStyles' })(
  ActivateModal
);
