export const LOAD_BASIC_POLICIES = 'LOAD_BASIC_POLICIES';
export const BASIC_FILTERING_UPDATE_SEARCH = 'BASIC_FILTERING_UPDATE_SEARCH';
export const BASIC_FILTERING_GET_ALL_POLICY = 'BASIC_FILTERING_GET_ALL_POLICY';
export const BASIC_FILTERING_LOADING = 'BASIC_FILTERING_LOADING';
export const GET_BASIC_FILTERING_SUCCESS = 'GET_BASIC_FILTERING_SUCCESS';
export const GET_BASIC_FILTERING_ERROR = 'GET_BASIC_FILTERING_ERROR';
export const CREATE_LOCATION_POLICY = 'CREATE_LOCATION_POLICY';
export const EDIT_CATEGORY_POLICY = 'EDIT_CATEGORY_POLICY';
export const OPEN_CATEGORY_MODAL = 'OPEN_CATEGORY_MODAL';
export const OPEN_EXCEPTION_MODAL = 'OPEN_EXCEPTION_MODAL';
export const EDIT_EXCEPTION_POLICY = 'EDIT_EXCEPTION_POLICY';
export const OPEN_LOCATION_MODAL = 'OPEN_LOCATION_MODAL';
export const EDIT_LOCATION_POLICY = 'EDIT_LOCATION_POLICY';
export const REMOVE_LOCATION_POLICY = 'REMOVE_LOCATION_POLICY';
export const BASIC_FILTER_RESET_CONFIG = 'BASIC_FILTER_RESET_CONFIG';
export const BASIC_FILTER_CHECK_TRAFFIC = 'BASIC_FILTER_CHECK_TRAFFIC';
export const BASIC_FILTER_CHECK_TRAFFIC_SUCCESS =
  'BASIC_FILTER_CHECK_TRAFFIC_SUCCESS';
export const BASIC_FILTER_SET_ALL_NOT_SEEN = 'BASIC_FILTER_SET_ALL_NOT_SEEN';

export const OPEN_ADD_NETWORK_MODAL = 'OPEN_ADD_NETWORK_MODAL';
export const CLOSE_ADD_NETWORK_MODAL = 'CLOSE_ADD_NETWORK_MODAL';

export const NO_TRAFFIC_SEEN = -1;

export const BASIC_FILTERING_DUPLICATE_DOMAIN_ERROR =
  'BASIC_FILTERING_DUPLICATE_DOMAIN_ERROR';
export const BASIC_FILTERING_BAD_DOMAIN_ERROR =
  'BASIC_FILTERING_BAD_DOMAIN_ERROR';
export const BASIC_FILTERING_UPDATE_SELECTED_CATEGORIES =
  'BASIC_FILTERING_UPDATE_SELECTED_CATEGORIES';
export const BASIC_FILTERING_SET_CATEGORIES = 'BASIC_FILTERING_SET_CATEGORIES';
export const BASIC_FILTERING_UPDATE_EXCEPTIONS =
  'BASIC_FILTERING_UPDATE_EXCEPTIONS';
export const BASIC_FILTERING_SET_EXCEPTIONS = 'BASIC_FILTERING_SET_EXCEPTIONS';
export const BASIC_FILTERING_ORDER_EXCEPTIONS =
  'BASIC_FILTERING_ORDER_EXCEPTIONS';
export const BASIC_FILTERING_FILTER_EXCEPTIONS =
  'BASIC_FILTERING_FILTER_EXCEPTIONS';
export const BASIC_FILTERING_UPDATE_LOCATION_NAME =
  'BASIC_FILTERING_UPDATE_LOCATION_NAME';
export const BASIC_FILTERING_SORT_NETWORKS = 'BASIC_FILTERING_SORT_NETWORKS';
export const BASIC_FILTERING_UPDATE_NETWORKS =
  'BASIC_FILTERING_UPDATE_NETWORKS';

export const NETWORK_POLICY_CREATED = 'NETWORK_POLICY_CREATED';
export const DYNAMIC_IP_SHOW_MODAL = 'DYNAMIC_IP_SHOW_MODAL';
export const DYNAMIC_IP_HIDE_MODAL = 'DYNAMIC_IP_HIDE_MODAL';

export const BASIC_FILTERING_UPDATE_IP_TYPE = 'BASIC_FILTERING_UPDATE_IP_TYPE';

export const STORE_LOADED_CATS = 'STORE_LOADED_CATS';

export const ALLOW = 'allow';
export const BLOCK = 'block';

export const IP = 'IP';
export const ACTIVITY = 'ACTIVITY';
export const NAME = 'NAME';
export const CAT = 'CAT';
export const EXCEPTION = 'EXCEPTION';
export const IPTYPE = 'IPTYPE';
export const HIGHLIGHT_LOCATION = 'HIGHLIGHT_LOCATION';

const twentyMins = 1200 * 1000;

export const setHighlightedRule = rule => ({
  type: HIGHLIGHT_LOCATION,
  rule,
});

export const storeLoadedCats = (categories, supercategories) => {
  const { levels } = _formatCats(categories, supercategories);
  return {
    type: STORE_LOADED_CATS,
    categories,
    supercategories,
    levels,
  };
};

const _formatCats = (cats, supercategories) => {
  const superCats = {};
  const superCatArr = [];
  const levels = {
    low: [],
    medium: [],
    high: [],
    custom: [],
  };

  Object.keys(cats).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(superCats, cats[key].parent)) {
      superCats[cats[key].parent] = {
        categories: [],
        superCategory: {
          value: supercategories[cats[key].parent],
          label: supercategories[cats[key].parent],
        },
      };
    }
    if (cats[key].level) {
      if (cats[key].level === 'high') {
        levels.high.push(parseInt(key, 10));
      } else if (cats[key].level === 'medium') {
        levels.high.push(parseInt(key, 10));
        levels.medium.push(parseInt(key, 10));
      } else if (cats[key].level === 'low') {
        levels.high.push(parseInt(key, 10));
        levels.medium.push(parseInt(key, 10));
        levels.low.push(parseInt(key, 10));
      }
    }
    superCats[cats[key].parent].categories.push({
      name: cats[key].name,
      label: cats[key].display,
      value: key,
    });
  });

  Object.keys(superCats).forEach(superCat => {
    superCats[superCat].categories.sort(
      (a, b) => (a.name > b.name) - (a.name < b.name)
    );
    superCatArr.push(superCats[superCat]);
  });

  superCatArr.sort(
    (a, b) =>
      (a.superCategory.value > b.superCategory.value) -
      (a.superCategory.value < b.superCategory.value)
  );

  return { superCatArr, levels };
};

export const initBasicPolicies = (cats, supercategories) => {
  const superCats = {};
  const superCatArr = [];
  const levels = {
    low: [],
    medium: [],
    high: [],
    custom: [],
  };

  Object.keys(cats).forEach(key => {
    if (!Object.prototype.hasOwnProperty.call(superCats, cats[key].parent)) {
      superCats[cats[key].parent] = {
        categories: [],
        superCategory: {
          value: supercategories[cats[key].parent],
          label: supercategories[cats[key].parent],
        },
      };
    }
    if (cats[key].level) {
      if (cats[key].level === 'high') {
        levels.high.push(parseInt(key, 10));
      } else if (cats[key].level === 'medium') {
        levels.high.push(parseInt(key, 10));
        levels.medium.push(parseInt(key, 10));
      } else if (cats[key].level === 'low') {
        levels.high.push(parseInt(key, 10));
        levels.medium.push(parseInt(key, 10));
        levels.low.push(parseInt(key, 10));
      }
    }
    superCats[cats[key].parent].categories.push({
      name: cats[key].name,
      label: cats[key].display,
      value: key,
    });
  });

  Object.keys(superCats).forEach(superCat => {
    superCats[superCat].categories.sort(
      (a, b) => (a.name > b.name) - (a.name < b.name)
    );
    superCatArr.push(superCats[superCat]);
  });

  superCatArr.sort(
    (a, b) =>
      (a.superCategory.value > b.superCategory.value) -
      (a.superCategory.value < b.superCategory.value)
  );

  return {
    type: LOAD_BASIC_POLICIES,
    superCatArr,
    levels,
  };
};

export const updateSearch = value => ({
  type: BASIC_FILTERING_UPDATE_SEARCH,
  value,
});

export const updatedSelectedCategories = (selected, added, removed) => ({
  type: BASIC_FILTERING_UPDATE_SELECTED_CATEGORIES,
  selected,
  added,
  removed,
});

export const setSelectedCategories = (selected, categories) => ({
  type: BASIC_FILTERING_SET_CATEGORIES,
  selected,
  categories,
});

export const updateExceptions = (added, removed, selected) => ({
  type: BASIC_FILTERING_UPDATE_EXCEPTIONS,
  added,
  removed,
  selected,
});

export const badValueDomainError = () => ({
  type: BASIC_FILTERING_BAD_DOMAIN_ERROR,
});

export const domainError = () => ({
  type: BASIC_FILTERING_DUPLICATE_DOMAIN_ERROR,
});

export const setExceptions = (domains, selected) => ({
  type: BASIC_FILTERING_SET_EXCEPTIONS,
  domains,
  selected,
});

export const filterExceptions = (query, action) => ({
  type: BASIC_FILTERING_FILTER_EXCEPTIONS,
  query,
  action,
});

export const sortExceptions = (orderBy, order) => ({
  type: BASIC_FILTERING_ORDER_EXCEPTIONS,
  orderBy,
  order,
});

export const sortNetworks = (orderBy, order) => ({
  type: BASIC_FILTERING_SORT_NETWORKS,
  orderBy,
  order,
});

export const updateNetworks = (added, removed) => ({
  type: BASIC_FILTERING_UPDATE_NETWORKS,
  added,
  removed,
});

export const updateLocationName = name => ({
  type: BASIC_FILTERING_UPDATE_LOCATION_NAME,
  name,
});

export const basicFilteringLoading = () => ({
  type: BASIC_FILTERING_LOADING,
});

const _transformAddress = locations => {
  let data = [];

  for (let i in locations) {
    data.push(...locations[i]['networks']);
  }

  return data;
};

const _transformCategories = category => ({
  id: category['id'],
  name: category['name'],
  categories: category['categories'],
});

const _transofrmExceptions = exception => {
  let domains = [];
  for (let i in exception['domain_exceptions']) {
    domains.push({
      domain: exception['domain_exceptions'][i]['domain'],
      action: exception['domain_exceptions'][i]['access'],
    });
  }
  return {
    id: exception['id'],
    name: exception['name'],
    domains: domains,
  };
};

const transform = data => {
  const policies = [];
  const now = Date.now();

  for (let i = 0; i < data.length; i += 1) {
    let { lastSeen } = data[i];
    const { networkStatus } = data[i];

    if (!lastSeen) {
      const timeCreated = data[i].locations[0].time_created * 1000;
      if (now - timeCreated > twentyMins) {
        lastSeen = -1;
      }
    }

    const buckets = networkStatus.buckets || [];

    policies.push({
      id: data[i].id,
      origLocation: data[i],
      origAddresses: data[i].locations,
      addresses: _transformAddress(data[i].locations),
      name: data[i].name,
      lastSeen,
      networkStatus: buckets.reduce(
        (obj, network) => ({ ...obj, [network.key]: network.last_seen.value }),
        {}
      ),
      categories: _transformCategories(data[i].category_policy),
      exceptions: _transofrmExceptions(data[i].exception_policy),
    });
  }

  return policies;
};

export const getBasicFilteringSuccess = (
  data,
  query,
  timezone,
  sortIndex,
  sortDirection
) => {
  const policies = transform(data);
  const customPolicies = policies.map(policy => ({
    name: policy.name,
    categories: policy.categories.categories,
    domains: policy.exceptions.domains,
    id: policy.id,
  }));
  return {
    type: GET_BASIC_FILTERING_SUCCESS,
    policies,
    customPolicies,
  };
};

export const getBasicFilteringFailure = error => ({
  type: GET_BASIC_FILTERING_ERROR,
  error,
});

export const getAllLocationPolicy = () => ({
  type: BASIC_FILTERING_GET_ALL_POLICY,
});

export const createLocationPolicy = () => ({
  type: CREATE_LOCATION_POLICY,
});

export const networkCreated = (locationId, ipType) => ({
  type: NETWORK_POLICY_CREATED,
  locationId,
  ipType,
});

export const openAddNetworkModal = () => ({
  type: OPEN_ADD_NETWORK_MODAL,
});

export const closeAddNetworkModal = () => ({
  type: CLOSE_ADD_NETWORK_MODAL,
});

export const showDynamicModal = id => ({
  type: DYNAMIC_IP_SHOW_MODAL,
  id,
});

export const hideDynamicModal = () => ({
  type: DYNAMIC_IP_HIDE_MODAL,
});

export const openCategory = (config, id) => ({
  type: OPEN_CATEGORY_MODAL,
  config,
  id,
});

export const editCategoryPolicy = () => ({
  type: EDIT_CATEGORY_POLICY,
});

export const openException = (config, id) => ({
  type: OPEN_EXCEPTION_MODAL,
  config,
  id,
});

export const editExceptionPolicy = () => ({
  type: EDIT_EXCEPTION_POLICY,
});

export const openLocation = config => ({
  type: OPEN_LOCATION_MODAL,
  config,
});

export const editLocationPolicy = () => ({
  type: EDIT_LOCATION_POLICY,
});

export const removeLocationPolicy = config => ({
  type: REMOVE_LOCATION_POLICY,
  config,
});

export const updateIPType = ipType => ({
  type: BASIC_FILTERING_UPDATE_IP_TYPE,
  ipType,
});

export const resetConfig = () => ({
  type: BASIC_FILTER_RESET_CONFIG,
});

export const checkTraffic = locationIds => ({
  type: BASIC_FILTER_CHECK_TRAFFIC,
  locationIds,
});

export const checkLocationSuccess = result => ({
  type: BASIC_FILTER_CHECK_TRAFFIC_SUCCESS,
  result,
});

export const setAllTrafficAsNotSeen = () => ({
  type: BASIC_FILTER_SET_ALL_NOT_SEEN,
});
