import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';

import { I18n } from 'react-i18nify';

import Timestamp from '../containers/Timestamp';

import PaginatorActions from './table/PaginatorActions';
import EPSTableBody from './table/TableBody';
import SectionHeader from './SectionHeader';
import SearchBar from './SearchBar';
import CudaTableTitle from './CudaTableTitle';
import Paper from './CudaPaper';

import { AuditLogIcon } from './icons';

let labels = {
  ARCHIVE: 'Compressed Files',
  MSOFFICE: 'Microsoft Office Files',
  PDF: 'PDF Files',
  EXECUTABLE: 'Executable Files',
};

class AuditLogComponent extends Component {
  state = {
    order: 'desc',
    orderBy: 'timestamp',
    page: 0,
    rowsPerPage: 20,
  };

  auditChangeFuncs = {
    suspicious: (old_val, new_val) => ({
      field: 'Suspicious Files',
      value: new_val.suspicious === 'quarantine' ? 'QUARANTINE' : 'ALLOW',
    }),
    block_encrypted_archives: (old_val, new_val) => ({
      field: 'Encrypted Files',
      value: new_val.block_encrypted_archives ? 'QUARANTINE' : 'ALLOW',
    }),
    block_password_protected_doc: (old_val, new_val) => ({
      field: 'Password Protected Files',
      value: new_val.block_password_protected_doc ? 'QUARANTINE' : 'ALLOW',
    }),
    scan_removable_drive: (old_val, new_val) => ({
      field: 'Removable Drives',
      value: new_val.scan_removable_drive ? 'ON' : 'OFF',
    }),
    update_quarantine: (old_val, new_val) => {
      return {};
    },
    filetypes: (old_val, new_val) => {
      let filtered;
      let value;

      if (old_val.filetypes.length > new_val.filetypes.length) {
        filtered = old_val.filetypes.filter(
          field => new_val.filetypes.indexOf(field) === -1
        );
        value = 'OFF';
      } else {
        filtered = new_val.filetypes.filter(
          field => old_val.filetypes.indexOf(field) === -1
        );
        value = 'ON';
      }

      return {
        field: labels[filtered.join()],
        value: value,
      };
    },
    file_exclusion: (old_val, new_val) => {
      let filtered;
      let action;

      if (old_val.file_exclusion.length > new_val.file_exclusion.length) {
        filtered = old_val.file_exclusion.filter(
          field => new_val.file_exclusion.indexOf(field) === -1
        );
        action = 'Removed';
      } else {
        filtered = new_val.file_exclusion.filter(
          field => old_val.file_exclusion.indexOf(field) === -1
        );
        action = 'Added';
      }

      return {
        field: action,
        value: filtered.join(','),
      };
    },
    path_exclusion: (old_val, new_val) => {
      let filtered;
      let action;

      if (old_val.path_exclusion.length > new_val.path_exclusion.length) {
        filtered = old_val.path_exclusion.filter(
          field => new_val.path_exclusion.indexOf(field) === -1
        );
        action = 'Removed';
      } else {
        filtered = new_val.path_exclusion.filter(
          field => old_val.path_exclusion.indexOf(field) === -1
        );
        action = 'Added';
      }

      return {
        field: action,
        value: filtered.join(','),
      };
    },
    active: (old_val, new_val) => {
      return {
        lid: new_val.active ? 'resumed' : 'suspended',
        type: I18n.t('components.notifications.' + new_val.type),
      };
    },
    emails: (old_val, new_val) => {
      return {
        lid: 'email_change',
        emails: new_val.emails.join(', '),
        type: I18n.t('components.notifications.' + new_val.type),
      };
    },
    threshold: (old_val, new_val) => {
      return {
        lid: 'threshold_change',
        threshold: new_val.threshold,
        type: I18n.t('components.notifications.' + new_val.type),
      };
    },
  };

  handleQuery = (query = '') => {
    const lc = query.toLowerCase();
    let params = {};
    let accounts = [];

    if (query) {
      // match text "account names" to account ids since that's all we can query es with
      for (let [account, name] of this.props.accountNames.entries()) {
        if (name.toLowerCase().indexOf(lc) !== -1) {
          accounts.push(account);
        }
      }
      params['accounts'] = accounts.join(',');
      params['query'] = lc;
    }

    this.props.fetchLogs(params);
  };

  componentDidMount = () => {
    this.handleQuery();
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  sortHandler = field => {
    const orderBy = field;
    let order = 'desc';
    let specialMap;

    if (orderBy === 'account_id') {
      specialMap = this.props.accountNames;
    }

    if (this.state.orderBy === field && this.state.order === 'desc') {
      order = 'asc';
    }

    this.props.sortLogs(orderBy, order, specialMap);
    this.setState({ order, orderBy });
  };

  render() {
    const {
      classes,
      logs,
      loading,
      accountNames,
      logCount,
      catMapping,
      userName,
      columns,
      placeholder,
    } = this.props;
    const { order, orderBy, page, rowsPerPage } = this.state;
    const NUM_COLUMNS = 5;
    const rowsPerPageOptions = [20, 50, 100];

    return (
      <DocumentTitle
        title={I18n.t('components.audit.page_title')}
        data-test-id="audit-title"
      >
        <div>
          <SectionHeader
            icon={<AuditLogIcon />}
            title={I18n.t('components.audit.title')}
            help={true}
          />
          <Grid id="audit-log" container justify="space-between" spacing={24}>
            <Grid item xs={12} md={6}>
              <SearchBar
                data-test-id="audit-searchbar"
                height="40px"
                onChange={value => {
                  this.handleQuery(value);
                }}
                placeholder={
                  placeholder || I18n.t('components.audit.search_placeholder')
                }
                autoFocus={false}
                disabled={false}
              />
            </Grid>
            <Grid item xs={6}>
              {/*<Button variant='contained' color='primary' className={ classes.exportButton }>
                { I18n.t("shared.downloadCSV" ) }
              </Button> */}
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table data-test-id="audit-log-table">
                  <TableHead>
                    <TableRow>
                      <TableCell data-test-id="audit-header-timestamp">
                        <CudaTableTitle
                          value="shared.time"
                          sort={true}
                          toggle={orderBy === 'timestamp' ? order : 'none'}
                          onClick={() => {
                            this.sortHandler('timestamp');
                          }}
                        />
                      </TableCell>
                      {columns.account && (
                        <TableCell data-test-id="audit-header-account">
                          <CudaTableTitle
                            value="shared.account"
                            sort={true}
                            toggle={orderBy === 'account_id' ? order : 'none'}
                            onClick={() => {
                              this.sortHandler('account_id');
                            }}
                          />
                        </TableCell>
                      )}
                      <TableCell data-test-id="audit-header-user">
                        <CudaTableTitle
                          value="shared.user"
                          sort={true}
                          toggle={orderBy === 'username' ? order : 'none'}
                          onClick={() => {
                            this.sortHandler('username');
                          }}
                        />
                      </TableCell>
                      <TableCell data-test-id="audit-header-address">
                        <CudaTableTitle
                          value="shared.ip_address"
                          sort={true}
                          toggle={orderBy === 'ip_address' ? order : 'none'}
                          onClick={() => {
                            this.sortHandler('ip_address');
                          }}
                        />
                      </TableCell>
                      <TableCell data-test-id="audit-header-details">
                        <CudaTableTitle value="shared.details" sort={false} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <EPSTableBody
                    loading={loading}
                    colCount={NUM_COLUMNS}
                    rowCount={logCount}
                    lid="components.audit.noResults"
                    paginate={true}
                    page={this.state.page}
                  >
                    {!loading &&
                      logs
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((n, i) => {
                          const action = n['_source'].action;
                          let old_val;
                          let new_val;
                          let detailsArr = [];
                          let account;

                          switch (action) {
                            case 'login-successful':
                            case 'login-unsuccessful':
                            case 'support-login-successful':
                            case 'support-login-unsuccessful':
                            case 'logout-successful':
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              break;
                            case 'atp-policy-change':
                            case 'edit-notification':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              try {
                                old_val = JSON.parse(n['_source'].old_val);
                              } catch (e) {
                                old_val = {};
                              }

                              for (let field in new_val) {
                                if (
                                  new_val.hasOwnProperty(field) &&
                                  this.auditChangeFuncs.hasOwnProperty(field)
                                ) {
                                  let info = this.auditChangeFuncs[field](
                                    old_val,
                                    new_val
                                  );
                                  detailsArr.push(
                                    I18n.t(
                                      'components.audit.' + (info.lid || field),
                                      info
                                    )
                                  );
                                }
                              }
                              break;
                            case 'delete-notification':
                              try {
                                old_val = JSON.parse(n['_source'].old_val);
                              } catch (e) {
                                old_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action, {
                                  type: I18n.t(
                                    'components.notifications.' + old_val.type
                                  ),
                                })
                              );
                              break;
                            case 'update-quarantine':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = [];
                              }

                              for (let action of new_val) {
                                if (
                                  !['unquarantine', 'remove'].includes(
                                    action.method
                                  )
                                ) {
                                  continue;
                                }
                                detailsArr.push(
                                  I18n.t(
                                    'components.audit.quarantine-' +
                                      action.method,
                                    action
                                  )
                                );
                              }
                              break;
                            case 'create-notification':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action, {
                                  type: I18n.t(
                                    'components.notifications.' + new_val.type
                                  ),
                                })
                              );
                              break;
                            case 'location-policy-create':
                              let allowList = [];
                              let deniedList = [];
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = [];
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(new_val.name);
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.addresses')
                              );
                              for (let address in new_val.addresses) {
                                if (new_val.addresses.hasOwnProperty(address)) {
                                  detailsArr.push(new_val.addresses[address]);
                                }
                              }
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.categories')
                              );
                              for (let category in new_val.categories) {
                                if (
                                  new_val.categories.hasOwnProperty(category)
                                ) {
                                  detailsArr.push(
                                    catMapping['categories'][
                                      new_val.categories[category]
                                    ]['display']
                                  );
                                }
                              }
                              for (let exception in new_val.exceptions) {
                                if (
                                  new_val.exceptions.hasOwnProperty(exception)
                                ) {
                                  if (
                                    new_val.exceptions[exception]['access'] ===
                                    'allow'
                                  ) {
                                    allowList.push(
                                      new_val.exceptions[exception]['domain']
                                    );
                                  } else {
                                    deniedList.push(
                                      new_val.exceptions[exception]['domain']
                                    );
                                  }
                                }
                              }
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.allowExceptions')
                              );
                              for (let allow in allowList) {
                                if (allowList.hasOwnProperty(allow)) {
                                  detailsArr.push(allowList[allow]);
                                }
                              }
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.deniedExceptions')
                              );
                              for (let denied in deniedList) {
                                if (deniedList.hasOwnProperty(denied)) {
                                  detailsArr.push(deniedList[denied]);
                                }
                              }
                              break;
                            case 'location-policy-remove':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = [];
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action, {
                                  name: new_val['name'],
                                })
                              );
                              break;
                            case 'location-policy-location-update':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = [];
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              if (new_val['old_name'] !== new_val['new_name']) {
                                detailsArr.push(
                                  I18n.t('components.audit.oldName')
                                );
                                detailsArr.push(new_val['old_name']);
                                detailsArr.push('<br/>');
                                detailsArr.push(
                                  I18n.t('components.audit.newName')
                                );
                                detailsArr.push(new_val['new_name']);
                              } else {
                                detailsArr.push(new_val['old_name']);
                                detailsArr.push('<br/>');
                              }

                              if (new_val['added_addresses'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.addAddresses')
                                );
                                for (let add in new_val['added_addresses']) {
                                  if (
                                    new_val['added_addresses'].hasOwnProperty(
                                      add
                                    )
                                  ) {
                                    detailsArr.push(
                                      new_val['added_addresses'][add]
                                    );
                                  }
                                }
                              }

                              if (new_val['removed_addresses'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.removeAddresses')
                                );
                                for (let remove in new_val[
                                  'removed_addresses'
                                ]) {
                                  if (
                                    new_val['removed_addresses'].hasOwnProperty(
                                      remove
                                    )
                                  ) {
                                    detailsArr.push(
                                      new_val['removed_addresses'][remove]
                                    );
                                  }
                                }
                              }

                              break;
                            case 'location-policy-categories-update':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = [];
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(new_val['name']);
                              detailsArr.push('<br/>');
                              if (new_val['added_categories'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.addCategories')
                                );
                                for (let add in new_val['added_categories']) {
                                  if (
                                    new_val['added_categories'].hasOwnProperty(
                                      add
                                    ) &&
                                    new_val['added_categories'][add]
                                  ) {
                                    detailsArr.push(
                                      catMapping['categories'][
                                        new_val['added_categories'][add]
                                      ]['display']
                                    );
                                  }
                                }
                                detailsArr.push('<br/>');
                              }

                              if (new_val['removed_categories'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.removeCategories')
                                );
                                for (let remove in new_val[
                                  'removed_categories'
                                ]) {
                                  if (
                                    new_val[
                                      'removed_categories'
                                    ].hasOwnProperty(remove) &&
                                    new_val['removed_categories'][remove]
                                  ) {
                                    detailsArr.push(
                                      catMapping['categories'][
                                        new_val['removed_categories'][remove]
                                      ]['display']
                                    );
                                  }
                                }
                              }

                              break;
                            case 'location-policy-domain-patch':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = [];
                              }

                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );

                              break;
                            case 'location-policy-exceptions-update':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }

                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(new_val['name']);
                              detailsArr.push('<br/>');
                              if (new_val['added_allow_domains'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.addAllowDomains')
                                );
                                for (let add in new_val[
                                  'added_allow_domains'
                                ]) {
                                  if (
                                    new_val[
                                      'added_allow_domains'
                                    ].hasOwnProperty(add)
                                  ) {
                                    detailsArr.push(
                                      new_val['added_allow_domains'][add]
                                    );
                                  }
                                }
                                detailsArr.push('<br/>');
                              }

                              if (new_val['removed_allow_domains'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.removeAllowDomains')
                                );
                                for (let remove in new_val[
                                  'removed_allow_domains'
                                ]) {
                                  if (
                                    new_val[
                                      'removed_allow_domains'
                                    ].hasOwnProperty(remove)
                                  ) {
                                    detailsArr.push(
                                      new_val['removed_allow_domains'][remove]
                                    );
                                  }
                                }
                                detailsArr.push('<br/>');
                              }

                              if (new_val['added_deny_domains'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.addDenyDomains')
                                );
                                for (let add in new_val['added_deny_domains']) {
                                  if (
                                    new_val[
                                      'added_deny_domains'
                                    ].hasOwnProperty(add)
                                  ) {
                                    detailsArr.push(
                                      new_val['added_deny_domains'][add]
                                    );
                                  }
                                }
                                detailsArr.push('<br/>');
                              }

                              if (new_val['removed_deny_domains'].length > 0) {
                                detailsArr.push(
                                  I18n.t('components.audit.removeDeniedDomains')
                                );
                                for (let remove in new_val[
                                  'removed_deny_domains'
                                ]) {
                                  if (
                                    new_val[
                                      'removed_deny_domains'
                                    ].hasOwnProperty(remove)
                                  ) {
                                    detailsArr.push(
                                      new_val['removed_deny_domains'][remove]
                                    );
                                  }
                                }
                                detailsArr.push('<br/>');
                              }
                              break;
                            case 'account-update-timezone':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = [];
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(new_val['timezone']);
                              account = userName;
                              break;
                            case 'account-update-password':
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              account = userName;
                              break;
                            case 'ruleset-create':
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              break;
                            case 'ruleset-rule-create':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }

                              if (new_val.hasOwnProperty('group_ids')) {
                                detailsArr.push(
                                  I18n.t(
                                    'components.audit.' + action + '-group'
                                  )
                                );
                                detailsArr.push('<br/>');
                                for (let group in new_val['group_ids']) {
                                  let group_id = new_val['group_ids'][group];
                                  let display = group_id;
                                  if (new_val['rule_name']) {
                                    display = new_val['rule_name'];
                                  }
                                  detailsArr.push(display);
                                }
                                detailsArr.push('<br/>');
                                break;
                              } else if (new_val.hasOwnProperty('user_ids')) {
                                detailsArr.push(
                                  I18n.t('components.audit.' + action + '-user')
                                );
                                detailsArr.push('<br/>');
                                for (let user in new_val['user_ids']) {
                                  let user_id = new_val['user_ids'][user];
                                  let display = user_id;
                                  if (new_val['rule_name']) {
                                    display = new_val['rule_name'];
                                  }
                                  detailsArr.push(display);
                                }
                                detailsArr.push('<br/>');
                                break;
                              }

                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');

                              if (new_val['type'] === 'category') {
                                for (let cat in new_val['categories']) {
                                  detailsArr.push(
                                    catMapping['categories'][
                                      new_val['categories'][cat]
                                    ]['display']
                                  );
                                }
                              } else if (new_val['type'] === 'domain') {
                                for (let domain in new_val['domains']) {
                                  detailsArr.push(new_val['domains'][domain]);
                                }
                              } else if (new_val['type'] === 'url') {
                                for (let url in new_val['urls']) {
                                  detailsArr.push(new_val['urls'][url]);
                                }
                              }
                              detailsArr.push('<br/>');

                              break;
                            case 'ruleset-rule-update':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                                old_val = JSON.parse(n['_source'].old_val);
                              } catch (e) {
                                new_val = {};
                                old_val = {};
                              }

                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');

                              detailsArr.push(
                                I18n.t('components.audit.newVal')
                              );
                              detailsArr.push('<br/>');
                              if (new_val.hasOwnProperty('categories')) {
                                for (let cat in new_val['categories']) {
                                  detailsArr.push(
                                    catMapping['categories'][
                                      new_val['categories'][cat]
                                    ]['display']
                                  );
                                }
                              } else if (new_val.hasOwnProperty('domains')) {
                                for (let domain in new_val['domains']) {
                                  detailsArr.push(new_val['domains'][domain]);
                                }
                              } else if (new_val.hasOwnProperty('urls')) {
                                for (let url in new_val['urls']) {
                                  detailsArr.push(new_val['urls'][url]);
                                }
                              }
                              detailsArr.push('<br/>');

                              detailsArr.push(
                                I18n.t('components.audit.oldVal')
                              );
                              detailsArr.push('<br/>');
                              if (
                                old_val.hasOwnProperty('categories') &&
                                old_val['categories']
                              ) {
                                for (let cat in old_val['categories']) {
                                  detailsArr.push(
                                    catMapping['categories'][
                                      old_val['categories'][cat]
                                    ]['display']
                                  );
                                }
                              } else if (
                                old_val.hasOwnProperty('domains') &&
                                old_val['domains']
                              ) {
                                for (let domain in old_val['domains']) {
                                  detailsArr.push(old_val['domains'][domain]);
                                }
                              } else if (
                                old_val.hasOwnProperty('urls') &&
                                old_val['urls']
                              ) {
                                for (let url in old_val['urls']) {
                                  detailsArr.push(old_val['urls'][url]);
                                }
                              }
                              detailsArr.push('<br/>');
                              break;
                            case 'ruleset-rule-remove':
                              try {
                                old_val = JSON.parse(n['_source'].old_val);
                              } catch (e) {
                                old_val = {};
                              }

                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');

                              if (
                                old_val.hasOwnProperty('categories') &&
                                old_val['categories']
                              ) {
                                for (let cat in old_val['categories']) {
                                  detailsArr.push(
                                    catMapping['categories'][
                                      old_val['categories'][cat]
                                    ]['display']
                                  );
                                }
                              } else if (
                                old_val.hasOwnProperty('domains') &&
                                old_val['domains']
                              ) {
                                for (let domain in old_val['domains']) {
                                  detailsArr.push(old_val['domains'][domain]);
                                }
                              } else if (
                                old_val.hasOwnProperty('urls') &&
                                old_val['urls']
                              ) {
                                for (let url in old_val['urls']) {
                                  detailsArr.push(old_val['urls'][url]);
                                }
                              }
                              detailsArr.push('<br/>');
                              break;
                            case 'update-account':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              for (let d in new_val) {
                                detailsArr.push(`${d}: ${new_val[d]}`);
                              }
                              break;
                            case 'create-local-user':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.groupAdded', {
                                  group: new_val['group']
                                    ? new_val['group']['name']
                                    : null,
                                })
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(I18n.t('components.audit.users'));
                              detailsArr.push('<br/>');
                              if (new_val['users']) {
                                detailsArr.push(
                                  new_val['users'].reduce((string, user) => {
                                    return `${string},{user}`;
                                  })
                                );
                              }
                              break;
                            case 'create-local-group':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(new_val['name']);
                              break;
                            case 'delete-local-group':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                new_val['group']
                                  ? new_val['group']['name']
                                  : null
                              );
                              break;
                            case 'update-local-group':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.oldVal')
                              );
                              detailsArr.push(
                                new_val['group']
                                  ? new_val['group']['name']
                                  : null
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.newVal')
                              );
                              detailsArr.push(new_val['name']);
                              break;
                            case 'update-local-user':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                                old_val = JSON.parse(n['_source'].old_val);
                              } catch (e) {
                                new_val = {};
                                old_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.oldVal')
                              );
                              detailsArr.push(old_val['name']);
                              detailsArr.push('<br/>');
                              detailsArr.push(
                                I18n.t('components.audit.newVal')
                              );
                              detailsArr.push(new_val['name']);
                              break;
                            case 'delete-local-group-user':
                              try {
                                new_val = JSON.parse(n['_source'].new_val);
                              } catch (e) {
                                new_val = {};
                              }
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              detailsArr.push('<br/>');
                              detailsArr.push(I18n.t('components.audit.group'));
                              detailsArr.push(new_val['group']['name']);
                              detailsArr.push('<br/>');
                              detailsArr.push(I18n.t('components.audit.users'));
                              detailsArr.push(new_val['user']['name']);
                              break;
                            default:
                              detailsArr.push(
                                I18n.t('components.audit.' + action)
                              );
                              break;
                          }

                          const accountValue =
                            account ||
                            accountNames.get(Number(n['_source'].account_id)) ||
                            n['_source'].account_id;

                          return (
                            <TableRow
                              key={n['_id']}
                              data-test-id={`audit-row-${i}`}
                            >
                              <TableCell data-test-id={`audit-timestamp-${i}`}>
                                <Timestamp timestamp={n['_source'].timestamp} />
                                <br />
                              </TableCell>
                              {columns.account && (
                                <TableCell data-test-id={`audit-account-${i}`}>
                                  {accountValue}
                                </TableCell>
                              )}
                              <TableCell data-test-id={`audit-user-${i}`}>
                                {n['_source'].real_user_id
                                  ? 'support@barracuda.com'
                                  : n['_source'].username}
                              </TableCell>
                              <TableCell data-test-id={`audit-address-${i}`}>
                                {n['_source'].ip_address}
                              </TableCell>
                              <TableCell data-test-id={`audit-details-${i}`}>
                                {detailsArr.map((text, index) => {
                                  if (text === '<br/>') {
                                    return <br key={index} />;
                                  }
                                  return <div key={index}>{text}</div>;
                                })}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    <TableRow data-test-id="audit-log-pagination-wrapper">
                      <TablePagination
                        colSpan={NUM_COLUMNS}
                        count={logCount}
                        rowsPerPageOptions={rowsPerPageOptions}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                          'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                          'aria-label': 'Next Page',
                        }}
                        SelectProps={{
                          'data-test-id': 'audit-selection',
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        ActionsComponent={PaginatorActions}
                      />
                    </TableRow>
                  </EPSTableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}
AuditLogComponent.propTypes = {
  columns: PropTypes.object,
  placeholder: PropTypes.string,
};
AuditLogComponent.defaultProps = {
  columns: {
    account: true,
  },
};

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  exportButton: {
    margin: 0,
    height: '40px',
    float: 'right',
  },
});

export default withStyles(styles, { name: 'AuditLogStyles' })(
  AuditLogComponent
);
