import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import Auth from './lib/Auth';
import * as Env from './lib/Env';

import WebStorage from './lib/WebStorage';

class Authenticator extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  handleSubscription = (subscriptions, account_subs) => {
    this.props.updateSubscriptions(subscriptions || []);
    this.props.storeSubscriptions(subscriptions || [], account_subs || []);
  };

  storeAuthentication = (dtoken, serials = {}) => {
    this.props.storeDecodedSSOToken(dtoken); // Store for activation
    this.props.storeUserName(dtoken.name);
    this.props.retrieveAccount(
      dtoken.email,
      dtoken.account,
      dtoken.accounts,
      dtoken.services,
      dtoken.sub,
      dtoken.zoneinfo
    );
    this.props.storeSerials(serials);
  };

  failedRedirect = () => {
    const { from } = this.props.location.state;

    if (
      from.pathname.indexOf('/activate') === 0 ||
      from.pathname.indexOf('/trial') === 0
    ) {
      this.props.history.push(from);
    } else {
      this.props.history.push('/login');
    }
  };

  successRedirect = smb => {
    const { from } = this.props.location.state;

    if (
      from.pathname === '/' ||
      (from.pathname === '/activate' && from.pathname === '/trial')
    ) {
      if (smb) {
        this.props.history.push('/dashboard');
      } else {
        this.props.history.push('/accounts');
      }
    } else {
      this.props.history.push(from);
    }
  };

  handleRedirect = (success = true, smb = false) => {
    const { from } = this.props.location.state;

    if (success) {
      return this.successRedirect(smb);
    }

    this.failedRedirect();
  };

  handleEmptyRedirect = () => {
    const { from } = this.props.location.state;

    const sso = WebStorage.get(Env.SSO_SESSION_STORE);

    if (
      from.pathname.indexOf('/activate') === 0 ||
      from.pathname.indexOf('/trial') === 0
    ) {
      // TODO: Need to handle cookie case
      // if SSO token exists
      try {
        if (sso) {
          const dtoken = Auth.store_sso(sso);
          this.props.storeDecodedSSOToken(dtoken);
        }
      } catch (e) {}

      this.props.history.push(from);
    } else {
      this.props.history.push('/login');
    }
  };

  handleSSORefresh = () => {
    const { from } = this.props.location.state;
    const { handleRedirect } = this;
    const { errorMessage, showSessionModal } = this.props;

    const jwe = WebStorage.get(Env.JWE_SESSION_STORE);
    const sso = WebStorage.get(Env.SSO_SESSION_STORE);
    const refreshToken = WebStorage.get(Env.SSO_REFRESH_TOKEN);

    if (jwe && sso) {
      window
        .fetch(Env.TOKEN_VALIDATOR_URL, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            sso,
            token: jwe,
          }),
        })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Failed to validated token');
          }

          return response.json();
        })
        .then(response => {
          this.handleSubscription(
            response.subscriptions,
            response.account_subs
          );

          const dtoken = Auth.setup_sso(sso, refreshToken);
          if (Auth.timer) {
            clearTimeout(Auth.timer);
          }
          setTimeout(() => {
            showSessionModal();
          }, (dtoken['exp'] - dtoken['auth_time']) * (3 / 4) * 1000);
          Auth.store_token(jwe);

          return Auth.refresh_token();
        })
        .then(
          response => {
            const account = WebStorage.get(Env.ACCOUNT);
            const name = WebStorage.get(Env.ACCOUNT_NAME);

            const dtoken = Auth.get_sso_token();
            this.storeAuthentication(dtoken, response.serials);
            this.handleRedirect();
          },
          () => {
            handleRedirect(false);
          }
        );
    } else {
      this.handleEmptyRedirect();
    }
  };

  componentDidMount() {
    const { from } = this.props.location.state;
    const { handleRedirect } = this;
    const { errorMessage } = this.props;

    const jwe = WebStorage.get(Env.JWE_SESSION_STORE);
    const sso = WebStorage.get(Env.SSO_SESSION_STORE);
    const refreshToken = WebStorage.get(Env.SSO_REFRESH_TOKEN);

    window
      .fetch(Env.COOKIE_VALIDATOR_URL, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify({}),
      })
      .then(response => {
        return response.json();
      })
      .then(response => {
        // Checked for cookies but nothing was sent.
        if (response.statusCode === 200 && response.empty) {
          this.handleSSORefresh();
          return;
        }

        if (response.statusCode !== 200) {
          const error = JSON.parse(response.body);
          errorMessage(error.code, error.error);
          handleRedirect(false);
          return;
        }

        const account = WebStorage.get(Env.ACCOUNT);
        const name = WebStorage.get(Env.ACCOUNT_NAME);

        const dtoken = response.payload;
        this.handleSubscription(dtoken.subscriptions, dtoken.account_subs);
        // Set up token for Support
        Auth.setup_support(dtoken);
        Auth.store_token(response['token']);

        this.storeAuthentication(dtoken, response.serials);
        // Force SMB for Support login
        this.handleRedirect(true, true);
      })
      .catch(error => {
        // For localhost development. If it errors out without a valid http status code.
        // most likely api being rejected by the server so just fall back to handling regular sso.
        this.handleSSORefresh();
      });
  }

  render() {
    return <div>Processing...</div>;
  }
}

export default Authenticator;
