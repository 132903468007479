import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-i18nify';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dialog from '@material-ui/core/Dialog';

import CudaMenu from '../components/CudaMenu';
import { WindowsIcon, DeviceIcon } from '../components/icons';
import SectionHeader from '../components/SectionHeader';
import SearchBar from '../components/SearchBar';
import CudaTableTitle from '../components/CudaTableTitle';
import { CudaDialogContent, CudaDialogTitle } from '../components/CudaDialog';

import { updateSharedTimeframe } from '../actions/atp_shared';
import { initDevicesList, changeTimeframe } from '../actions/devices';
import Timestamp from './Timestamp';
import Paper from '../components/CudaPaper';

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  menu: {
    width: '150px',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  dialog: {
    overflow: 'visible',
  },
  dialogWrapper: {
    display: 'flex',
    width: '650px',
  },
  dialogLeft: {
    display: 'flex',
    width: '160px',
    backgroundColor: '#F7F7F7',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    textAlign: 'center',
  },
  dialogLeftTitle: {
    fontWeight: 600,
    lineHeight: '24px',
  },
  dialogLeftContent: {
    fontSize: '12px',
    fontWeight: 300,
  },
  leftBox: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  dialogRight: {
    backgroundColor: '#FFFFFF',
    flexGrow: 1,
    padding: '15px 40px',
  },
  dialogRightTitle: {
    fontWeight: 600,
    lineHeight: '32px',
    fontSize: '16px',
  },
  dialogRightContent: {
    fontSize: '14px',
    paddingLeft: '10px',
  },
  rightBox: {
    marginTop: '10px',
  },
  row: {
    display: 'flex',
    lineHeight: '32px',
  },
  rowHeader: {
    width: '140px',
    marginRight: '10px',
  },
  rowContent: {},
  threats: {
    textAlign: 'center',
  },
};

const options = [
  {
    key: 'day',
    label: 'Last 24 hours',
  },
  {
    key: 'week',
    label: 'Last 7 days',
  },
  {
    key: 'month',
    label: 'Last 30 days',
  },
];

class DevicesComponent extends Component {
  state = {
    sort: 'asc',
    query: '',
    show: false,
  };

  componentDidMount() {
    const { initDevicesList } = this.props;
    initDevicesList();
  }

  toggle() {
    let value = this.state.sort;
    if (value === 'desc') {
      value = 'asc';
    } else {
      value = 'desc';
    }
    this.setState({ sort: value });
  }

  updateQuery(value) {
    this.setState({ query: value });
  }

  openPopUp(device) {
    const { agent_threats } = this.props;

    this.setState({
      show: true,
      deviceName: device['name'],
      agentVersion: device['version'],
      os: device['os_version'],
      threatsDetected:
        device.key in agent_threats ? agent_threats[device.key].count : 0,
      lastThreatSeen: device.key in agent_threats && (
        <Timestamp timestamp={agent_threats[device.key].last_threat} />
      ),
    });
  }

  closePopUp() {
    this.setState({ show: false });
  }

  render() {
    const {
      classes,
      changeTimeframe,
      selectedIndex,
      agent_info,
      agent_threats,
      loading,
    } = this.props;
    const displayFiltered = agent_info.filter(a => {
      let name = a.name.toUpperCase();
      let q = this.state.query.toUpperCase();
      if (q !== '') {
        return name.match(q, 'g') !== null;
      }

      return 1;
    });

    displayFiltered.sort((a, b) => {
      if (this.state.sort === 'desc') {
        return a['name'].toLowerCase() < b['name'].toLowerCase() ? 1 : -1;
      } else {
        return a['name'].toLowerCase() < b['name'].toLowerCase() ? -1 : 1;
      }
    });

    return (
      <DocumentTitle title={I18n.t('components.devices.pageTitle')}>
        <div>
          <SectionHeader
            icon={<DeviceIcon />}
            title={I18n.t('components.devices.title')}
            options={
              <CudaMenu
                height={40}
                options={options}
                onChange={index => {
                  changeTimeframe(options[index].key, index);
                }}
                selectedIndex={selectedIndex}
              />
            }
          />
          <Grid container spacing={24} justify="space-between">
            <Grid item xs={6}>
              <SearchBar
                height="40px"
                value={this.state.query}
                onChange={value => {
                  this.updateQuery(value);
                }}
                placeholder={I18n.t('components.devices.search')}
                autoFocus={false}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <CudaTableTitle value="shared.os" />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle
                          value="shared.endpointName"
                          sort={true}
                          toggle={this.state.sort}
                          onClick={() => {
                            this.toggle();
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle value="shared.threats" center={true} />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle value="shared.lastThreat" />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle value="shared.agentVersion" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell className={classes.noResults} colSpan="6">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {!loading && displayFiltered.length === 0 ? (
                      <TableRow>
                        <TableCell className={classes.noResults} colSpan="7">
                          <Translate value="shared.noResults" />
                        </TableCell>
                      </TableRow>
                    ) : null}
                    {!loading && displayFiltered.length > 0
                      ? displayFiltered.map((n, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell>
                                <div style={{ width: '20px' }}>
                                  <WindowsIcon width="16px" height="16px" />
                                </div>
                              </TableCell>
                              <TableCell>
                                <div>
                                  <Button
                                    color="primary"
                                    onClick={() => this.openPopUp(n)}
                                  >
                                    {n.name}
                                  </Button>
                                </div>
                              </TableCell>
                              <TableCell
                                className={classes.threats}
                                style={{
                                  color:
                                    n.key in agent_threats &&
                                    agent_threats[n.key].count > 0
                                      ? '#DD1E31'
                                      : 'rgba(0,0,0,0.5)',
                                }}
                              >
                                {n.key in agent_threats
                                  ? agent_threats[n.key].count
                                  : 0}
                              </TableCell>
                              <TableCell>
                                {n.key in agent_threats && (
                                  <Timestamp
                                    timestamp={agent_threats[n.key].last_threat}
                                  />
                                )}
                              </TableCell>
                              <TableCell>{n.version}</TableCell>
                            </TableRow>
                          );
                        })
                      : null}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            className={classes.dialog}
            open={this.state.show}
            maxWidth="md"
            onEscapeKeyDown={() => this.closePopUp()}
          >
            <CudaDialogTitle onRequestClose={() => this.closePopUp()}>
              <Translate value="components.devices.info" />
            </CudaDialogTitle>
            <CudaDialogContent overflow="visible" loadingAnimation={false}>
              <div className={classes.dialogWrapper}>
                <div className={classes.dialogLeft}>
                  <div
                    className={classes.leftBox}
                    style={{ marginTop: '15px' }}
                  >
                    <WindowsIcon width="75px" height="75px" />
                  </div>
                  <div className={classes.leftBox}>
                    <div className={classes.dialogLeftTitle}>
                      <Translate value="components.devices.name" />
                    </div>
                    <div className={classes.dialogLeftContent}>
                      {this.state.deviceName}
                    </div>
                  </div>
                  <div className={classes.leftBox}>
                    <div className={classes.dialogLeftTitle}>
                      <Translate value="components.devices.version" />
                    </div>
                    <div className={classes.dialogLeftContent}>
                      {this.state.agentVersion}
                    </div>
                  </div>
                </div>
                <div className={classes.dialogRight}>
                  <div className={classes.rightBox}>
                    <div className={classes.dialogRightTitle}>
                      <Translate value="components.devices.device" />
                    </div>
                    <div className={classes.dialogRightContent}>
                      <div className={classes.row}>
                        <div className={classes.rowHeader}>
                          <Translate value="components.devices.os" />
                        </div>
                        <div className={classes.rowContent}>
                          {this.state.os}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.rightBox}>
                    <div className={classes.dialogRightTitle}>
                      <Translate value="components.devices.protection" />
                    </div>
                    <div className={classes.dialogRightContent}>
                      <div className={classes.row}>
                        <div className={classes.rowHeader}>
                          <Translate value="components.devices.threatsDetected" />
                        </div>
                        <div className={classes.rowContent}>
                          {this.state.threatsDetected}
                        </div>
                      </div>
                      <div className={classes.row}>
                        <div className={classes.rowHeader}>
                          <Translate value="components.devices.lastThreatSeen" />
                        </div>
                        <div className={classes.rowContent}>
                          {this.state.lastThreatSeen}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CudaDialogContent>
          </Dialog>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  selectedIndex: state.atp_shared.timeSelectIndex,
  agent_info: state.devices.agent_info,
  agent_threats: state.devices.agent_threats,
  loading: state.devices.loading,
  timezone: state.account.time_zone,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initDevicesList: () => {
    dispatch(initDevicesList());
  },
  changeTimeframe: (time, index) => {
    dispatch(updateSharedTimeframe(time, index));
    dispatch(changeTimeframe(time, index));
  },
});

const Devices = withRouter(
  withStyles(styles, { name: 'Devices' })(DevicesComponent)
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Devices);
