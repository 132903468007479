import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-i18nify';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Paper from './CudaPaper';
import SectionHeader from './SectionHeader';
import { BlockpageIcon } from './icons';

import SpecialValues from './block_page/SpecialValues';
import TemplateSelector from '../containers/block_page/TemplateSelector';
import BlockPageEditor from './block_page/BlockPageEditor';
import StatusBar from '../containers/block_page/StatusBar';

class BlockPageSettings extends React.Component {
  componentDidMount = () => {
    const { fetchInfo } = this.props;

    fetchInfo();
  };

  render() {
    const {
      classes,
      showPreview,
      resetDefault,
      loading,
      changed,
      template,
    } = this.props;

    return (
      <DocumentTitle title={I18n.t('blockPage.page_title')}>
        <React.Fragment>
          <SectionHeader
            icon={<BlockpageIcon />}
            title={I18n.t('blockPage.title')}
          >
            <div className={classes.subText}>
              <Translate value="blockPage.subheader" dangerousHTML />
            </div>
          </SectionHeader>
          <Grid container spacing={24}>
            <Grid item xs={8}>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <Paper padding>
                    <TemplateSelector />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    loading={loading}
                    title={
                      <div className={classes.editorTitle}>
                        <Translate value="blockPage.editor.title" />
                        <Button
                          variant="text"
                          color="primary"
                          className={classes.resetBtn}
                          disabled={changed}
                          onClick={resetDefault}
                        >
                          {I18n.t('blockPage.editor.resetToDefault')}
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.previewBtn}
                          onClick={showPreview}
                        >
                          {I18n.t('blockPage.editor.preview')}
                        </Button>
                      </div>
                    }
                  >
                    <BlockPageEditor template={template} />
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Paper title={I18n.t('blockPage.specialValues.title')} padding>
                <SpecialValues />
              </Paper>
            </Grid>
          </Grid>
          <StatusBar />
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

const styles = {
  editorTitle: {
    display: 'flex',
    padding: '20px',
    alignItems: 'center',
    '& > span': {
      flex: 1,
    },
  },
  previewBtn: {
    margin: 0,
    padding: '8px',
  },
  resetBtn: {
    minHeight: '32px',
  },
};

BlockPageSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  showPreview: PropTypes.func.isRequired,
  resetDefault: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchInfo: PropTypes.func.isRequired,
};

export default withStyles(styles, { name: 'BlockPageSettings' })(
  BlockPageSettings
);
