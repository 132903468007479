import * as Types from '../actions/devices';
import * as AppTypes from '../actions/app';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Api from './Api';

export const devices = (
  state = {
    init: false,
    loading: false,
    agent_info: [],
    agent_threats: {},
  },
  action
) => {
  switch (action.type) {
    case Types.GET_DEVICES_DATA_SUCCESS:
      return {
        ...state,
        init: true,
        loading: false,
        agent_info: action.agent_info,
        agent_threats: action.agent_threats,
      };
    case Types.GET_DEVICES_DATA_FAILURE:
      return {
        ...state,
        init: true,
        loading: false,
      };

    case Types.DEVICES_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

function* getDeviceList() {
  try {
    const store = yield select();
    yield put(Types.deviceLoading());
    const result = yield call(Api.getDeviceList, {
      page: 'devices',
      range: store.atp_shared.range,
      account_id: store.account.selected,
      time_zone: store.account.time_zone,
    });
    yield put(Types.getDeviceListSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getDeviceListFailure(e.error));
  }
}

export function* devicesReducerFlow() {
  yield takeEvery(Types.INIT_DEVICES_LIST, getDeviceList);
  yield takeLatest(Types.DEVICES_CHANGE_TIME, getDeviceList);
}
