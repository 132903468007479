import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';

import UploadModal from '../containers/smb_account_settings/UploadModal';

import SectionHeader from './SectionHeader';
import { LOGO_PATH } from '../lib/Env';
import Paper from './CudaPaper';
import { SettingsIcon } from './icons';

import GridColumn from './common/GridColumn';
import Dropdown from './common/Dropdown';

import StatusBar from '../containers/smb_account_settings/StatusBar';
import SettingsItem from './settings/SettingsItem';

class SMBAccountSettings extends React.Component {
  componentDidMount = () => {
    this.props.initData();
  };

  render() {
    const {
      classes,
      showUploadModal,
      processing,
      openUploadModal,
      closeUploadModal,
      uploadLogo,
      logo,
      autoUpdate,
      fields,
      periods,
      updatePeriod,
      updateTime,
      updateOptions,
      updateAgentUpdate,
      updateSupportStatus,
      toggleSupport,
      saving,
    } = this.props;

    const currentLogo = logo || LOGO_PATH;

    return (
      <DocumentTitle
        title={I18n.t('components.smbAccountSettings.pageTitle')}
        data-test-id="account-settings-title"
      >
        <React.Fragment>
          <SectionHeader
            icon={<SettingsIcon style={{ color: '#5F5F5F' }} />}
            title={I18n.t('components.smbAccountSettings.title')}
          />
          <Grid
            data-test-id="custom-logo-wrapper"
            spacing={24}
            container
            alignItems="flex-start"
          >
            <Grid item xs={12} md={7}>
              <Paper
                title={I18n.t('components.smbAccountSettings.customBranding')}
                subTitle={I18n.t(
                  'components.smbAccountSettings.customBrandingSub'
                )}
                loading={processing}
                padding
              >
                <Translate
                  className={classes.label}
                  value="components.smbAccountSettings.currentLogo"
                  tag="div"
                />
                <div className={classNames(classes.logoWrapper, classes.row)}>
                  {!processing && (
                    <img data-test-id="logo" src={currentLogo} alt="logo" />
                  )}
                </div>
                <div className={classNames(classes.row, classes.addRow)}>
                  <Translate
                    className={classes.label}
                    value="components.smbAccountSettings.uploadFromFile"
                  />
                  <Button
                    id="select-logo-file"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    onClick={() => {
                      openUploadModal();
                    }}
                  >
                    {I18n.t('components.smbAccountSettings.selectFile')}
                  </Button>
                </div>
                <Translate
                  className={classes.subText}
                  value="components.smbAccountSettings.maxHeight"
                  tag="div"
                />
                <Translate
                  className={classes.subText}
                  value="components.smbAccountSettings.maxWidth"
                  tag="div"
                />
              </Paper>
            </Grid>
            <GridColumn xs={12} md={5} spacing={24}>
              <Grid item xs={12}>
                <Paper
                  title={I18n.t('components.smbAccountSettings.updateTitle')}
                  subTitle={I18n.t(
                    'components.smbAccountSettings.updateSubTitle'
                  )}
                  loading={processing}
                  padding
                >
                  <Translate
                    className={classes.label}
                    value="components.smbAccountSettings.applyUpdates"
                    tag="div"
                  />
                  <Dropdown
                    data-test-id="agent-update-type"
                    value={updateOptions.find(
                      option => option.value === fields.updateSetting
                    )}
                    options={updateOptions}
                    isDisabled={saving}
                    className={classNames(
                      fields.updateSetting === 'time' && classes.updateType
                    )}
                    onChange={updateAgentUpdate}
                  />
                  {fields.updateSetting === 'time' && (
                    <React.Fragment>
                      <Translate
                        className={classes.label}
                        value="components.smbAccountSettings.updateAt"
                        tag="div"
                      />
                      <div className={classes.timeWrapper}>
                        <TextField
                          data-test-id="agent-update-hour"
                          value={fields.hour}
                          name="hour"
                          disabled={saving}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              root: classNames(
                                classes.input,
                                classes.shortInput
                              ),
                            },
                          }}
                          onChange={updateTime}
                        />
                        <span className={classes.colon}>:</span>
                        <TextField
                          data-test-id="agent-update-minute"
                          value={fields.minute}
                          name="minute"
                          disabled={saving}
                          InputProps={{
                            disableUnderline: true,
                            classes: {
                              root: classNames(
                                classes.input,
                                classes.shortInput
                              ),
                            },
                          }}
                          onChange={updateTime}
                        />
                        <Dropdown
                          data-test-id="agent-update-period"
                          value={periods.find(
                            option => option.value === fields.period
                          )}
                          options={periods}
                          isDisabled={saving}
                          className={classes.amDropdown}
                          onChange={updatePeriod}
                        />
                        <Translate
                          className={classes.timeText}
                          value="components.smbAccountSettings.localTime"
                        />
                      </div>
                    </React.Fragment>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  title={I18n.t('components.smbAccountSettings.supportAccess')}
                  subTitle={I18n.t(
                    'components.smbAccountSettings.supportAccessSub'
                  )}
                  loading={processing}
                  padding
                >
                  <SettingsItem
                    text={
                      <Translate
                        value="components.smbAccountSettings.supportAccess"
                        className={classes.label}
                      />
                    }
                    toggled={fields.support_enabled}
                    onToggle={toggleSupport}
                    padding={false}
                    disabled={saving}
                    labels={['components.shared.on', 'components.shared.off']}
                    customClasses={{
                      labelWrapper: classes.labelWrapper,
                    }}
                  />
                </Paper>
              </Grid>
            </GridColumn>
          </Grid>
          <UploadModal
            show={showUploadModal}
            closeDialog={closeUploadModal}
            uploadLogo={uploadLogo}
            processing={processing}
          />
          <StatusBar />
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

SMBAccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  openUploadModal: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  initData: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
};

const styles = theme => ({
  subText: {
    color: theme.colors.secondaryText,
    fontSize: '12px',
    lineHeight: '20px',
    marginLeft: '150px',
  },
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
  button: {
    margin: 0,
    padding: '8px 20px',
  },
  row: {
    display: 'flex',
    marginBottom: '16px',
  },
  addRow: {
    alignItems: 'center',
    '& $label': {
      marginBottom: 0,
      flex: '0 0 150px',
    },
  },
  logoWrapper: {
    border: '1px solid #DDDDDD',
    minHeight: '100px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      maxWidth: '240px',
      maxHeight: '240px',
    },
  },
  logoRuleText: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  dropzone: {
    marginBottom: '16px',
  },
  modalRow: {
    marginBottom: '16px',
  },
  updateType: {
    marginBottom: '16px',
  },

  input: {
    border: '1px solid #DDDDDD',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.06)',
    borderRadius: '2px',
    paddingLeft: '6px',
    paddingRight: '6px',
    fontSize: '14px',
    '& > input': {
      lineHeight: '20px',
      padding: '5px ',
    },
  },
  shortInput: {
    width: '30px',
  },

  timeText: {
    fontSize: '14px',
  },
  colon: {
    margin: '0 4px',
    lineHeight: '20px',
  },
  amDropdown: {
    margin: '0 16px',
    width: '75px',
  },
  timeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default withStyles(styles, { name: 'SMBAccountSettings' })(
  SMBAccountSettings
);
