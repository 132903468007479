import React from 'react';
import { I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';

const StatusBarComponent = ({
  classes,
  result = 'success',
  messageLid,
  message,
  show = true,
  saving = false,
  cancelAction = undefined,
  submitAction = undefined,
}) =>
  show && (
    <Fade in={show}>
      <div className={classes.wrapper} data-test-id="status-bar">
        <div style={{ flex: 1 }} />
        <div style={{ display: 'flex' }} data-test-id="message-wrapper">
          {saving && (
            <LinearProgress
              data-test-id="linear-progress"
              className={classes.progress}
              classes={{ barColorPrimary: classes.primary }}
            />
          )}
          {!saving && (
            <ErrorIcon
              data-test-id="error-icon"
              classes={{ colorPrimary: classes[result] }}
              color="primary"
              className={classes.statusIcon}
            />
          )}
          <span className={classes.messageText} data-test-id="message">
            {messageLid && (I18n.t(messageLid) || messageLid)}
            {message}
          </span>
        </div>
        <div style={{ flex: 1 }} data-test-id="action-wrapper">
          {cancelAction && (
            <Button
              data-test-id="cancel"
              variant="contained"
              color="secondary"
              className={classes.previewBtn}
              onClick={cancelAction}
            >
              Cancel
            </Button>
          )}
          {submitAction && (
            <Button
              data-test-id="save"
              variant="contained"
              color="primary"
              className={classes.previewBtn}
              onClick={submitAction}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </Fade>
  );

const styles = {
  success: {
    color: '#5BA71B',
  },
  failure: {
    color: '#DD1E31',
  },
  statusIcon: {
    height: '24px',
    width: '24px',
    marginRight: '8px',
  },
  messageText: {
    lineHeight: '24px',
  },
  hidden: {
    display: 'none',
  },
  wrapper: {
    zIndex: 1,
    backgroundColor: '#FFFFFF',
    width: '100%',
    position: 'fixed',
    padding: '12px',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    boxShadow: '0 8px 17px 0 rgba(0,0,0,0.3), 0 6px 20px 0 rgba(0,0,0,0.19)',
  },
  progress: {
    height: '4px',
    backgroundColor: '#95CC66',
    top: 0,
    position: 'absolute',
    width: '100%',
  },
  primary: {
    backgroundColor: '#FFFFFF',
  },
};

export default withStyles(styles, { name: 'StatusBarStyles' })(
  StatusBarComponent
);
