import { connect } from 'react-redux';
import ExistingUserSelectAccount from '../../components/signup/ExistingUserSelectAccount';
import { deleteSession } from '../../actions/account';
import { storeAccount, setLogin, clearSession } from '../../actions/signup';
import Auth from '../../lib/Auth';

const mapStateToProps = state => ({
  accounts: state.signup.accounts,
  msp: state.signup.msp,
  email: (state.signup.sso && state.signup.sso.email) || '',
  processing: state.activate.processing,
});

const mapDispatchToProps = dispatch => ({
  deleteSession: token => {
    Auth.logout();
    dispatch(deleteSession(token));
    dispatch(setLogin(false));
    dispatch(clearSession());
  },
  saveInfo: accountId => {
    dispatch(storeAccount(accountId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingUserSelectAccount);
