import { call, put, select, takeEvery } from 'redux-saga/effects';
import { I18n } from 'react-i18nify';

import Api from './Api';
import * as Types from '../actions/notifications';
import * as AppTypes from '../actions/app';
import { storeLoadedCats } from '../actions/basic_filtering';

export default (
  state = {
    isProcessing: false,
    errors: {},
    notifications: [],
    loading: false,
    openModal: '',
    editData: {},
    typeOptions: [
      {
        key: 'basic-filtering',
        label: I18n.t('components.notifications.basicFiltering'),
        type: 'label',
      },
      {
        key: 'blocked_category',
        label: I18n.t('components.notifications.blockedCategory'),
        conditionLabel: I18n.t('components.notifications.requests24Hours'),
        subscriptions: ['dns'],
      },
      {
        key: 'command_control',
        label: I18n.t('components.notifications.commandControl'),
        conditionLabel: I18n.t('components.notifications.requests24Hours'),
        subscriptions: ['dns'],
      },
      {
        key: 'advanced-threat-filtering',
        label: I18n.t('components.notifications.advancedThreat'),
        type: 'label',
      },
      {
        key: 'device_installed',
        label: I18n.t('components.notifications.device_installed'),
        conditionLabel: I18n.t(
          'components.notifications.agentsInstalled24Hours'
        ),
        subscriptions: ['atp', 'af'],
      },
      // {
      //   key: 'threat_detected',
      //   label: I18n.t('components.notifications.threat_detected'),
      //   conditionLabel: I18n.t('components.notifications.threats24Hours'),
      //   subscriptions: ['atp'],
      // },
    ],
    error: '',
  },
  action
) => {
  let secondaryOptions;

  switch (action.type) {
    case Types.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.result.notifications,
      };
    case Types.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };
    case Types.UPDATE_NOTIFICATION:
    case Types.CREATE_NOTIFICATION:
      return {
        ...state,
        isProcessing: true,
      };
    case Types.NOTIFICATION_HIDE_MODAL:
      return {
        ...state,
        openModal: '',
      };
    case Types.NOTIFICATION_UPDATE_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    case Types.START_NOTIFICATION_CREATE:
      return {
        ...state,
        errors: {},
        openModal: 'create',
        editData: {},
      };
    case Types.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        openModal: '',
        error: '',
      };
    case Types.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        openModal: '',
      };
    case Types.NOTIFICATION_UPDATE_OPTIONS:
      return {
        ...state,
        editData: {
          ...state.editData,
          options: action.options,
        },
      };
    case Types.CHANGE_NOTIFICATION_TYPE:
      return {
        ...state,
        editData: {
          ...state.editData,
          type: action.notificationType,
        },
      };
    case Types.UPDATE_NOTIFICATION_FAILURE:
    case Types.CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
      };
    case Types.EDIT_NOTIFICATION:
      try {
        secondaryOptions = JSON.parse(action.notification.options);
      } catch (e) {
        secondaryOptions = {};
      }
      return {
        ...state,
        errors: {},
        openModal: 'edit',
        editData: {
          ...action.notification,
          options: secondaryOptions,
          original_emails: [...action.notification.email_subscribers],
        },
      };
    case Types.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        error: '',
      };
    default:
      return state;
  }
};

function* getNotifications() {
  try {
    const store = yield select();
    const catMap = store.basic_filtering.catMapping;

    if (!catMap.loaded) {
      const catResult = yield call(Api.getData, { page: 'get_basic_policies' });
      yield put(
        storeLoadedCats(catResult.categories, catResult.supercategories)
      );
    }

    const result = yield call(Api.notifications.read);
    yield put(Types.getSuccess({ ...result }));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getFailure(e.error));
  }
}

function validParams(emails, threshold) {
  let valid = true;
  const errors = {};

  if (!threshold || !/^\d+$/.test(threshold)) {
    valid = false;
    errors.threshold = true;
  }

  if (emails.length === 0) {
    valid = false;
    errors.emails = true;
  }

  for (let i = 0; i < emails.length; i += 1) {
    if (!emails[i].match(/.+@.+\..+/i) || emails[i].match(/^\s*$/g)) {
      valid = false;
      errors.emails = true;
      break;
    }
  }

  return {
    valid,
    errors,
  };
}

class ValidationError extends Error {
  constructor(validationError, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }

    // Custom debugging information
    this.response = {
      data: {
        message: validationError,
      },
    };
  }
}

function* createNotification(action) {
  try {
    const paramValidation = validParams(
      action.notification.added_emails,
      action.notification.threshold
    );
    if (!paramValidation.valid) {
      yield put(Types.updateErrors(paramValidation.errors));
      throw new Error({
        response: 'Invalid values',
      });
    }
    const store = yield select();
    const result = yield call(Api.notifications.create, {
      ...action.notification,
      account_id: store.account.selected,
    });
    yield put(Types.createSuccess({ ...result }));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.createFailure(e.error));
  }
}

function* updateNotification(action) {
  try {
    const paramValidation = validParams(
      action.notification.new_emails,
      action.notification.threshold
    );
    if (!paramValidation.valid) {
      yield put(Types.updateErrors(paramValidation.errors));
      throw new ValidationError('Invalid values');
    }
    const result = yield call(
      Api.notifications.update,
      action.notification.id,
      {
        ...action.notification,
      }
    );
    yield put(Types.updateSuccess({ ...result }));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.updateFailure(e.error));
  }
}

function* suspendNotification(action) {
  try {
    const result = yield call(
      Api.notifications.update,
      action.notification.id,
      {
        type: action.notification.type,
        active: !action.notification.active,
      }
    );
    yield put(Types.suspendSuccess({ ...result }));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.suspendFailure(e.error));
  }
}

function* deleteNotification(action) {
  try {
    const result = yield call(Api.notifications.delete, action.notification.id);
    yield put(Types.removeSuccess({ ...result }));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.removeFailure(e.error));
  }
}

function* refreshNotifications() {
  yield put(Types.get());
}

export function* notificationsReducerFlow() {
  yield takeEvery(Types.CREATE_NOTIFICATION, createNotification);
  yield takeEvery(Types.UPDATE_NOTIFICATION, updateNotification);
  yield takeEvery(Types.SUSPEND_NOTIFICATION, suspendNotification);
  yield takeEvery(Types.DELETE_NOTIFICATION, deleteNotification);
  yield takeEvery(Types.GET_NOTIFICATIONS, getNotifications);

  // refresh notification data
  yield takeEvery(Types.SUSPEND_NOTIFICATION_SUCCESS, refreshNotifications);
  yield takeEvery(Types.DELETE_NOTIFICATION_SUCCESS, refreshNotifications);
  yield takeEvery(Types.UPDATE_NOTIFICATION_SUCCESS, refreshNotifications);
  yield takeEvery(Types.CREATE_NOTIFICATION_SUCCESS, refreshNotifications);
}
