import { connect } from 'react-redux';
import { getSubs } from '../../selectors/account';
import SubscriptionModule from '../../components/overview/SubscriptionModule';

const mapStateToProps = state => ({
  mspSubStats: getSubs(state),
  loading: state.account.dashboardLoading,
});

export default connect(
  mapStateToProps,
  undefined
)(SubscriptionModule);
