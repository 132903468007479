import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Translate, I18n } from 'react-i18nify';

import CudaTooltip from '../CudaTooltip';
import {
  RadioButtonCheckIcon,
  RadioButtonUncheckIcon,
  InfoIcon,
} from '../icons';

const progressStyleSheet = {
  overlay: {
    position: 'absolute',
    paddingTop: 7,
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    opacity: 0.7,
    zIndex: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const ProgressComponent = ({ classes }) => (
  <div className={classes.overlay}>
    <CircularProgress size={20} />
  </div>
);

const Progress = withStyles(progressStyleSheet, { name: 'ProgressComponent' })(
  ProgressComponent
);

const styleSheet = {
  container: {
    minHeight: 50,
    marginTop: 5,
    '& $iconItem': {
      padding: 0,
    },
  },
  text: {
    paddingLeft: 20,
    fontSize: 16,
  },
  retryLink: {
    fontSize: 14,
    margin: '0 25px 2px -20px',
    cursor: 'pointer',
    color: '#0077c8',
  },
  mainToggleText: {
    paddingLeft: 0,
    fontSize: 22,
    fontWeight: 300,
    color: 'rgba(0,0,0,0.7)',
  },
  textDisabled: {
    color: 'rgba(0,0,0,0.3)',
  },
  toggleDiv: {
    minWidth: 120,
  },
  iconDiv: {
    marginTop: 3,
  },
  iconItem: {
    padding: 0,
  },
  group: {
    flexDirection: 'row',
    height: 34,
  },
  labelWrapper: {
    maxWidth: '240px',
  },
};

const SettingsRadio = ({ classes, ...props }) => {
  const {
    value,
    radioValues = [],
    handleChange,
    disabled,
    text,
    tooltipText,
    inputUnsaved,
    inputUpdateError,
    radioUpdateError,
    radioIsUpdating,
    labels,
  } = props;
  const uniqueId = 'tooltipId-' + text;

  let infoIconColor;
  if (inputUpdateError || radioUpdateError) {
    infoIconColor = '#DD1E31';
  } else if (inputUnsaved) {
    infoIconColor = '#0088CF';
  } else {
    infoIconColor = '#888888';
  }

  let displayedTooltipText;
  if (inputUpdateError && radioUpdateError) {
    displayedTooltipText = (
      <div>
        <Translate value="settingsItem.toggleErrorTooltipText" />
        <br />
        <Translate value="settingsItem.inputErrorTooltipText" />
      </div>
    );
  } else if (inputUpdateError) {
    displayedTooltipText = (
      <Translate value="settingsItem.inputErrorTooltipText" />
    );
  } else if (radioUpdateError) {
    displayedTooltipText = (
      <Translate value="settingsItem.toggleErrorTooltipText" />
    );
  } else if (inputUnsaved) {
    displayedTooltipText = (
      <Translate value="settingsItem.inputUnsavedTooltipText" />
    );
  } else {
    displayedTooltipText = tooltipText;
  }

  const labelClassName = classNames({
    [classes.text]: true,
    [classes.textDisabled]: disabled,
  });

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      className={classes.container}
      alignItems="center"
    >
      <Grid item xs={5} className={classes.labelWrapper}>
        <div className={labelClassName}>{text}</div>
      </Grid>
      <Grid item>
        <FormControl component="fieldset" required>
          {radioIsUpdating && <Progress />}
          <RadioGroup
            aria-label="radioOption"
            name="radioOption"
            className={classes.group}
            value={value.toString()}
            onChange={handleChange}
          >
            <FormControlLabel
              value={radioValues.length > 1 ? radioValues[0] : 'true'}
              control={
                <Radio
                  checkedIcon={<RadioButtonCheckIcon />}
                  icon={<RadioButtonUncheckIcon />}
                />
              }
              label={I18n.t(labels[0]).toUpperCase()}
            />
            <FormControlLabel
              value={radioValues.length > 1 ? radioValues[1] : 'false'}
              control={
                <Radio
                  checkedIcon={<RadioButtonCheckIcon />}
                  icon={<RadioButtonUncheckIcon />}
                />
              }
              label={I18n.t(labels[1]).toUpperCase()}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item className={classes.iconItem}>
        <div className={classes.iconDiv}>
          <InfoIcon color={infoIconColor} data-tip data-for={uniqueId} />
        </div>
        <CudaTooltip id={uniqueId}>
          <span dangerouslySetInnerHTML={{ __html: displayedTooltipText }} />
        </CudaTooltip>
      </Grid>
    </Grid>
  );
};

export default withStyles(styleSheet, { name: 'SettingsRadio' })(SettingsRadio);
