import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import WarningIcon from '@material-ui/icons/Warning';
import GoodIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import moment from 'moment';

import CudaTableTitle from './../CudaTableTitle';
import TableLoading from './../table/TableLoading';
import Paper from '../CudaPaper';

class SubscriptionModule extends Component {
  componentDidMount() {
    if (this.props.initData) {
      this.props.initData();
    }
  }

  render() {
    const { classes, loading, mspSubStats, elevation } = this.props;

    return (
      <Paper className={classes.paper} elevation={elevation}>
        <div className={classes.headerSection}>
          <div className={classes.title}>
            <Translate value="shared.subscriptions" />
          </div>
        </div>
        <Table className={classes.subTable}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell padding="dense" className={classes.cell}>
                <CudaTableTitle value="shared.type" />
              </TableCell>
              <TableCell padding="dense" className={classes.cell}>
                <CudaTableTitle value="shared.licenses" />
              </TableCell>
              <TableCell padding="dense" className={classes.cell}>
                <CudaTableTitle value="shared.expires" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && mspSubStats['dns'] && (
              <TableRow className={classes.tableRowContent}>
                <TableCell padding="dense" className={classes.cell}>
                  {I18n.t('components.dashboard.skus.dns')}
                </TableCell>
                <TableCell padding="dense" className={classes.cell}>
                  {mspSubStats['dns'].capacity.toLocaleString()}
                </TableCell>
                <TableCell padding="dense" className={classes.cell}>
                  <span
                    className={classNames(classes.subStatus, {
                      [classes.error]:
                        mspSubStats['dns']['state'] === 'expired',
                    })}
                  >
                    {
                      {
                        warning: (
                          <WarningIcon className={classes.warningIcon} />
                        ),
                        expired: <ErrorIcon className={classes.expiredIcon} />,
                        good: <GoodIcon className={classes.goodIcon} />,
                      }[mspSubStats['dns']['state']]
                    }
                    {moment(mspSubStats['dns'].expiredate).format('MM/DD/YY')}
                  </span>
                </TableCell>
              </TableRow>
            )}
            {!loading && mspSubStats['atp'] && mspSubStats['wca'] && (
              <TableRow className={classes.tableRowContent}>
                <TableCell padding="dense" className={classes.cell}>
                  {I18n.t('shared.advancedFiltering')}
                </TableCell>
                <TableCell padding="dense" className={classes.cell}>
                  {mspSubStats['atp'].capacity.toLocaleString()}
                </TableCell>
                <TableCell padding="dense" className={classes.cell}>
                  <span
                    className={classNames(classes.subStatus, {
                      [classes.error]:
                        mspSubStats['atp']['state'] === 'expired',
                    })}
                  >
                    {
                      {
                        warning: (
                          <WarningIcon className={classes.warningIcon} />
                        ),
                        expired: <ErrorIcon className={classes.expiredIcon} />,
                        good: <GoodIcon className={classes.goodIcon} />,
                      }[mspSubStats['atp']['state']]
                    }
                    {moment(mspSubStats['atp'].expiredate).format('MM/DD/YY')}
                  </span>
                </TableCell>
              </TableRow>
            )}
            {!loading && !mspSubStats['atp'] && !mspSubStats['wca'] && (
              <TableRow className={classes.tableRowContent}>
                <TableCell padding="dense" className={classes.cell}>
                  {I18n.t('shared.advancedFiltering')}
                </TableCell>
                <TableCell padding="dense" className={classes.cell}>
                  {I18n.t('shared.notPurchased')}
                </TableCell>
                <TableCell padding="dense" className={classes.cell}>
                  <a
                    href={'https://www.barracuda.com'}
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {I18n.t('shared.learnMore')}
                  </a>
                </TableCell>
              </TableRow>
            )}
            {((!loading && (!mspSubStats['atp'] && mspSubStats['wca'])) ||
              (mspSubStats['atp'] && !mspSubStats['wca'])) && (
              <TableRow className={classes.tableRowContent}>
                <TableCell padding="dense" className={classes.cell}>
                  {I18n.t('shared.advancedFiltering')}
                </TableCell>
                <TableCell padding="dense" className={classes.cell} />
                <TableCell padding="dense" className={classes.cell}>
                  <span className={classNames(classes.subStatus, 'expired')}>
                    <ErrorIcon className={classes.expiredIcon} />
                    {I18n.t('shared.error')}
                  </span>
                </TableCell>
              </TableRow>
            )}
            {loading && <TableLoading colSpan={3} />}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const styles = theme => ({
  cell: {
    padding: '4px 14px',
    '&:last-child': {
      paddingRight: '14px',
    },
  },
  paper: {
    border: '1px solid #DDDDDD',
  },
  error: {
    color: theme.statusColors.failure,
  },
  subStatus: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '8px',
    },
  },
  subTable: {
    '& tr': {
      height: '32px',
    },
  },
  headerSection: {
    padding: '20px 20px 5px 14px',
  },
  warningIcon: {
    fill: theme.statusColors.warning,
  },
  expiredIcon: {
    fill: theme.statusColors.failure,
  },
  goodIcon: {
    fill: theme.statusColors.success,
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  link: {
    color: '#0088CF',
    textDecoration: 'none',
  },
  headerRow: {
    '& th': {
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  tableRowContent: {
    '& td': {
      border: 'none',
    },
  },
});

SubscriptionModule.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  mspSubStats: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      capacity: PropTypes.number,
      expiredate: PropTypes.string,
    })
  ).isRequired,
  elevation: PropTypes.number,
};

SubscriptionModule.defaultProps = {
  elevation: 0,
};

export default withStyles(styles)(SubscriptionModule);
