import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';

import { CheckboxMarkedOutline } from '../icons';
import SearchBar from '../SearchBar';
import CudaMenu from '../CudaMenu';

class ExceptionFilterBar extends Component {
  state = {
    selectedAction: 0,
    query: '',
  };

  searchHandler = (query, action, selectedAction) => {
    this.props.searchHandler(query, action);
    this.setState({
      selectedAction,
      query,
    });
  };

  render() {
    const { classes } = this.props;
    const { query, selectedAction } = this.state;

    const options = [
      {
        key: 'all',
        label: I18n.t('components.basicFiltering.allExceptions'),
        icon: (
          <CheckboxMarkedOutline
            style={{
              height: '24px',
              width: '24px',
              marginRight: '4px',
              float: 'left',
            }}
          />
        ),
      },
      {
        key: 'block',
        label: I18n.t('components.basicFiltering.blockTraffic'),
        icon: (
          <BlockIcon
            style={{
              height: '24px',
              width: '24px',
              marginRight: '4px',
              float: 'left',
            }}
          />
        ),
      },
      {
        key: 'allow',
        label: I18n.t('components.basicFiltering.allowTraffic'),
        icon: (
          <DoneIcon
            style={{
              height: '24px',
              width: '24px',
              marginRight: '4px',
              float: 'left',
            }}
          />
        ),
      },
    ];

    return (
      <Grid data-test-id="exceptionFilterBar" container spacing={0}>
        <Grid item xs={7} data-test-id="exceptionSearchWrapper">
          <SearchBar
            data-test-id="exceptionSearch"
            height="32px"
            value={query}
            noShadow
            onChange={value => {
              this.searchHandler(
                value,
                options[this.state.selectedAction].key,
                this.state.selectedAction
              );
            }}
            placeholder={I18n.t('components.basicFiltering.domainPlaceholder')}
            autoFocus={false}
            disabled={false}
          />
        </Grid>
        <Grid item xs={5}>
          <div
            data-test-id="exceptionDropdownWrapper"
            className={classes.exceptionFilter}
          >
            <Translate
              value="components.basicFiltering.show"
              className={classes.filterLabel}
            />
            <CudaMenu
              data-test-id="exceptionShow"
              customClasses={[classes.root]}
              options={options}
              selectedIndex={selectedAction}
              onChange={value => {
                this.searchHandler(this.state.query, options[value].key, value);
              }}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}
ExceptionFilterBar.propTypes = {
  classes: PropTypes.object.isRequired,
  searchHandler: PropTypes.func.isRequired,
};

const styles = {
  filterLabel: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#5F5F5F',
    marginRight: '9px',
  },
  exceptionFilter: {
    float: 'right',
  },
  root: {
    width: '200px',
    display: 'inline-block',
    height: '32px',
  },
};

export default withStyles(styles, { name: 'ExceptionFilterBar' })(
  ExceptionFilterBar
);
