import { connect } from 'react-redux';
import ADGroupSelection from '../../components/advanced_filtering/ADGroupSelection';

import { onChangeAD } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  let ad = state.account.activeDirectory.map(ad => ({
    type: ad.type,
    label: ad.display_name || ad.group_name,
    value: ad.uuid,
  }));
  ad = ad.concat({ type: 'everyone', label: 'Everyone', value: 'everyone' });

  return {
    inputValue: state.advanced_filtering.ruleModal.adGroup,
    activeDirectoryOptions: ad,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: value => {
    dispatch(onChangeAD(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ADGroupSelection);
