import React from 'react';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';

import SectionHeader from './SectionHeader';
import ComboDashboard from '../containers/ComboDashboard';
import WFDashboard from '../containers/WFDashboard';
import ATPDashboard from '../containers/ATPDashboard';

import { PieChartIcon } from './icons';

const DashboardComponent = ({ subscriptions }) => (
  <DocumentTitle title={I18n.t('components.dashboard.page_title')}>
    <div>
      <SectionHeader
        icon={<PieChartIcon />}
        title={I18n.t('components.dashboard.title')}
      />
      {subscriptions.atp && subscriptions.dns && <ComboDashboard />}
      {!subscriptions.atp && (subscriptions.dns || subscriptions.wca) && <WFDashboard />}
      {subscriptions.atp && !subscriptions.dns && <ATPDashboard />}
    </div>
  </DocumentTitle>
);

DashboardComponent.propTypes = {
  subscriptions: PropTypes.object.isRequired,
};

export default DashboardComponent;
