import { all } from 'redux-saga/effects';
import { atpReducerFlow } from './atp';
import { atpLogsReducerFlow } from './atplogs';
import { accountsReducerFlow } from './account';
import { dashboardReducerFlow } from './dashboard';
import { mspDashboardReducerFlow } from './mspdashboard';
import { notificationsReducerFlow } from './notifications';
import { quarantineReducerFlow } from './quarantine';
import { reportsReducerFlow } from './reports';
import { devicesReducerFlow } from './devices';
import { auditLogReducerFlow } from './audit_log';
import { basicFilteringReducerFlow } from './basic_filtering';
import { advancedFilteringReducerFlow } from './advanced_filtering';
import { wfLogsReducerFlow } from './wf_logs';
import { loginReducerFlow } from './login';
import { activateReducerFlow } from './activate';
import { SMBAccountsReducerFlow } from './smb_account_settings';
import { signupReducerFlow } from './signup';
import { BlockPageReducerFlow } from './block_page_settings';
import { UserAuthenticationFlow } from './user_authentication';

export default function* rootSaga() {
  yield all([
    atpReducerFlow(),
    atpLogsReducerFlow(),
    accountsReducerFlow(),
    dashboardReducerFlow(),
    mspDashboardReducerFlow(),
    notificationsReducerFlow(),
    quarantineReducerFlow(),
    reportsReducerFlow(),
    devicesReducerFlow(),
    auditLogReducerFlow(),
    basicFilteringReducerFlow(),
    advancedFilteringReducerFlow(),
    wfLogsReducerFlow(),
    loginReducerFlow(),
    activateReducerFlow(),
    SMBAccountsReducerFlow(),
    signupReducerFlow(),
    BlockPageReducerFlow(),
    UserAuthenticationFlow(),
  ]);
}
