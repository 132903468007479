import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { NavLink } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import OpenIcon from '@material-ui/icons/OpenInNew';

const typeLinks = {
  wca: 'advanced_filtering',
  dns: 'basic_filtering',
};

const RuleLink = ({ classes, rule, type }) => (
  <NavLink to={`/${typeLinks[type]}?rule=${rule}`} className={classes.link}>
    {I18n.t('components.wfLogs.viewRule')}
    <OpenIcon />
  </NavLink>
);

RuleLink.propTypes = {
  classes: PropTypes.object.isRequired,
  rule: PropTypes.string,
  type: PropTypes.string,
};

RuleLink.defaultProps = {
  rule: '',
  type: 'wca',
};

const styles = theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginLeft: '8px',
    },
    color: theme.colors.link,
    textDecoration: 'none',
    fontWeight: '400',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
});

export default withStyles(styles, { name: 'RuleLink' })(RuleLink);
