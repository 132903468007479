export const SUBMIT_EMAIL = 'SUBMIT_EMAIL';
export const SUBMIT_EMAIL_SUCCESS = 'SUBMIT_EMAIL_SUCCESS';
export const SUBMIT_EMAIL_FAILURE = 'SUBMIT_EMAIL_FAILURE';
export const UPDATE_EMAIL_ADDRESS = 'UPDATE_EMAIL_ADDRESS';
export const START_TRIAL = 'START_TRIAL';
export const START_TRIAL_SUCCESS = 'START_TRIAL_SUCCESS';
export const START_TRIAL_FAILURE = 'START_TRIAL_FAILURE';
export const SIGNUP_SHOW_AGREEMENT = 'SIGNUP_SHOW_AGREEMENT';
export const SIGNUP_CANCEL_AGREEMENT = 'SIGNUP_CANCEL_AGREEMENT';

export const SIGNUP_STORE_ACCOUNT = 'SIGNUP_STORE_ACCOUNT';

export const TOGGLE_LOGIN = 'TOGGLE_LOGIN';
export const SET_LOGIN = 'SET_LOGIN';

export const ACTIVATE_TRIAL = 'ACTIVATE_TRIAL';

export const UPDATE_FORM_FIELDS = 'UPDATE_FORM_FIELDS';
export const VALIDATE_FORM_FIELD = 'VALIDATE_FORM_FIELD';
export const UPDATE_FORM_ERRORS = 'UPDATE_FORM_ERRORS';

export const FORM_SUBMIT_SUCCESS = 'FORM_SUBMIT_SUCCESS';
export const FORM_SUBMIT_FAILURE = 'FORM_SUBMIT_FAILURE';
export const FORM_SUBMIT_START = 'FORM_SUBMIT_START';

export const SUBMIT_USER_INFO = 'SUBMIT_USER_INFO';

export const SIGNUP_LINK_PURCHASE = 'SIGNUP_LINK_PURCHASE';
export const SIGNUP_LINK_PURCHASE_SUCCESS = 'SIGNUP_LINK_PURCHASE_SUCCESS';
export const SIGNUP_LINK_PURCHASE_FAILURE = 'SIGNUP_LINK_PURCHASE_FAILURE';

export const STORE_SIGNUP_SESSION_INFO = 'STORE_SIGNUP_SESSION_INFO';
export const SIGNUP_USER_LOGIN = 'SIGNUP_USER_LOGIN';
export const SIGNUP_USER_LOGIN_SUCCESS = 'SIGNUP_USER_LOGIN_SUCCESS';
export const SIGNUP_USER_LOGIN_FAILURE = 'SIGNUP_USER_LOGIN_FAILURE';
export const CLEAR_SIGNUP_SESSION_INFO = 'CLEAR_SIGNUP_SESSION_INFO';

export const submitEmail = () => ({
  type: SUBMIT_EMAIL,
});

export const submitEmailSuccess = () => ({
  type: SUBMIT_EMAIL_SUCCESS,
});

export const submitEmailFailure = error => ({
  type: SUBMIT_EMAIL_FAILURE,
  error,
});

export const updateFormFields = (form, fields) => ({
  type: UPDATE_FORM_FIELDS,
  form,
  fields,
});

export const validateFormField = (form, field, value) => ({
  type: VALIDATE_FORM_FIELD,
  form,
  field,
  value,
});

export const updateFormErrors = (form, errors) => ({
  type: UPDATE_FORM_ERRORS,
  form,
  errors,
});

export const updateEmailAddress = email => ({
  type: UPDATE_EMAIL_ADDRESS,
  email,
});

export const startTrial = history => ({
  type: START_TRIAL,
  history,
});

export const startTrialSuccess = () => ({
  type: START_TRIAL_SUCCESS,
});

export const startTrialFailure = () => ({
  type: START_TRIAL_FAILURE,
});

export const showAgreement = () => ({
  type: SIGNUP_SHOW_AGREEMENT,
});

export const submitUserInfo = () => ({
  type: SUBMIT_USER_INFO,
});

export const cancelAgreement = () => ({
  type: SIGNUP_CANCEL_AGREEMENT,
});

export const activateTrial = history => ({
  type: ACTIVATE_TRIAL,
  history,
});

export const storeAccount = account => ({
  type: SIGNUP_STORE_ACCOUNT,
  account,
});

export const toggleLogin = () => ({
  type: TOGGLE_LOGIN,
});

export const setLogin = value => ({
  type: SET_LOGIN,
  value,
});

export const linkPurchase = (serial, code) => ({
  type: SIGNUP_LINK_PURCHASE,
  serial,
  code,
});

export const userLogin = (email, password) => ({
  type: SIGNUP_USER_LOGIN,
  email,
  password,
});

export const storeSessionInfo = (dtoken, accounts, msp) => ({
  type: STORE_SIGNUP_SESSION_INFO,
  dtoken,
  accounts,
  msp,
});

export const clearSession = () => ({
  type: CLEAR_SIGNUP_SESSION_INFO,
});

export const formSubmitSuccess = form => ({
  type: FORM_SUBMIT_SUCCESS,
  form,
});

export const formSubmitFailure = (form, error) => ({
  type: FORM_SUBMIT_FAILURE,
  form,
  error,
});
