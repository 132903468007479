import React from 'react';
import { I18n } from 'react-i18nify';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import background from '../../login-bg-img.jpg';
import FillDetails from '../../containers/signup/FillDetails';
import SignupAgreement from '../../containers/signup/SignupAgreement';

const Signup = ({ classes, submit, form }) => (
  <DocumentTitle title={I18n.t('signup.fillDetails.pageTitle')}>
    <Grid
      data-test-id="signup-container"
      container
      className={classes.container}
      alignItems="center"
      justify="center"
    >
      {form.succeeded ? <SignupAgreement /> : <FillDetails onSubmit={submit} />}
    </Grid>
  </DocumentTitle>
);

const signupStyle = {
  container: {
    height: '100vh',
    flexGrow: 1,
    backgroundColor: '#555555',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  },
  badTokenContainer: {
    width: '540px',
    height: '180px',
    padding: 32,
    backgroundColor: '#ffffff',
    margin: 0,
  },
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '300',
    marginBottom: '20px',
    textAlign: 'center',
  },
};

export default withStyles(signupStyle, { name: 'Signup' })(Signup);
