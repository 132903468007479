import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-i18nify';

import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import Dropdown from '../common/Dropdown';

import { InfoIcon } from '../icons';

const AddLocalUsers = ({
  classes,
  groups,
  data,
  updateUsers,
  updateGroup,
  saving,
}) => {
  return (
    <React.Fragment>
      <div className={classes.header}>
        <Translate
          value="userAuthentication.local.groupName"
          className={classes.groupName}
        />
        <Dropdown
          creatable
          data-test-id="agent-update-type"
          value={groups.find(group => group.value === data.group.value)}
          options={groups}
          isDisabled={saving}
          onChange={updateGroup}
          placeholder={I18n.t('userAuthentication.local.groupListPlaceholder')}
          className={classes.groupList}
        />
        <Tooltip
          title={I18n.t('userAuthentication.local.addUserText')}
          placement="top"
        >
          <InfoIcon color="#888888" data-test-id="add-local-users-help" />
        </Tooltip>
      </div>
      <div className={classes.body}>
        <Translate
          value="userAuthentication.local.addUserText"
          className={classes.addUserText}
          tag="div"
        />
        <Translate
          value="userAuthentication.local.userID"
          className={classes.userIDText}
          tag="div"
        />
        <TextField
          placeholder={I18n.t('userAuthentication.local.placeholder')}
          rows={5}
          className={classes.textField}
          multiline
          data-test-id="add-local-users"
          value={data.names}
          onChange={updateUsers}
          InputProps={{
            disableUnderline: true,
            classes: {
              root: classes.settingInput,
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

const styles = theme => ({
  header: {
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
    padding: '16px 30px',
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    padding: '16px 30px',
    backgroundColor: '#FFFFFF',
  },
  addUserText: {
    marginBottom: '24px',
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '24px',
  },
  userIDText: {
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.5)',
    textTransform: 'uppercase',
  },
  groupName: {
    color: theme.colors.primaryText,
    fontSize: '16px',
    lineHeight: '32px',
    marginRight: '30px',
  },
  textField: {
    width: '100%',
  },
  settingInput: {
    fontSize: '14px',
    border: '1px solid #DDDDDD',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  groupList: {
    flex: '1',
    marginRight: '16px',
  },
  tooltip: {
    height: '16px',
    width: '16px',
  },
});

export default withStyles(styles, { name: 'AddLocalUsers' })(AddLocalUsers);
