import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js';
import './index.css';
import 'draft-js/dist/Draft.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import configureStore from './store/configureStore';
import { I18n } from 'react-i18nify';
import translationsObject from './translate';
import createSagaMiddleware from 'redux-saga';
import saga from './reducers/sagas';
import { routerMiddleware } from 'react-router-redux';
import createBrowserHistory from 'history/createBrowserHistory';

import { setupAxiosHandler } from './reducers/Api.js';
import { STAGE } from './lib/Env';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-113857650-1');

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory({
  basename: STAGE,
});
const router = routerMiddleware(history);

I18n.setTranslations(translationsObject);
I18n.setLocale('en');

const store = configureStore(
  process.env.NODE_ENV
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
    : undefined,
  [router, sagaMiddleware]
);

setupAxiosHandler(store, history);
sagaMiddleware.run(saga);

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('root')
);
unregister();
