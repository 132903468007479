import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormField from './FormField';
import SessionAction from './SessionAction';
import Box from './Box';
import ActionButton from './ActionButton';
import ErrorMessage from './ErrorMessage';

import { EmailIcon, DarkLockIcon } from '../icons';

class UserLogin extends React.Component {
  render() {
    const { classes, handleLogin, handleBack, processing, error } = this.props;

    return (
      <Box
        title={I18n.t('signup.login.title')}
        subTitle={I18n.t('signup.login.subTitle')}
      >
        <form
          onSubmit={event => {
            handleLogin(this.username.value, this.password.value);
            event.preventDefault();
          }}
        >
          <Grid item container direction="column" className={classes.formDiv}>
            <Grid
              item
              container
              alignItems="center"
              justify="center"
              spacing={0}
            >
              <Grid item xs={11}>
                <FormField
                  data-test-id="email-address"
                  name="email"
                  placeholder={I18n.t('signup.startTrial.emailAddress')}
                  field={{
                    value: undefined,
                    error: '',
                  }}
                  icon=<EmailIcon />
                  inputRef={input => {
                    this.username = input;
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <FormField
                  data-test-id="password"
                  name="password"
                  type="password"
                  placeholder={I18n.t('shared.password')}
                  field={{
                    value: undefined,
                    error: '',
                  }}
                  icon=<DarkLockIcon />
                  inputRef={input => {
                    this.password = input;
                  }}
                />
              </Grid>
              <Grid item xs={11}>
                <ActionButton
                  data-test-id="btn-signin"
                  type="submit"
                  inProgress={processing}
                  inProgressText={I18n.t('components.login.signingIn')}
                  className={classes.actionButton}
                >
                  <Translate value="components.login.signIn" />
                </ActionButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
        <Grid item className={classes.msp}>
          <SessionAction
            data-test-id="msp-action"
            label=<Translate value="signup.barracudaMSP" dangerousHTML />
          />
        </Grid>
        <Grid item>
          <SessionAction
            label={I18n.t('signup.login.noAccount')}
            buttonLabel={I18n.t('shared.create')}
            buttonAction={handleBack}
          />
        </Grid>
        {error && (
          <ErrorMessage data-test-id="user-login-error" error={error} />
        )}
      </Box>
    );
  }
}

const styles = theme => ({
  formDiv: {
    padding: 8,
    width: '400px',
  },
  actionButton: {
    width: '100%',
    marginLeft: '30px',
    marginRight: '0px',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-10px',
    backgroundColor: '#FFDDE0',
    padding: '6px',
    width: '100%',
  },
  errorText: {
    color: '#333333',
    lineHeight: '15px',
    fontSize: '13px',
    marginLeft: '10px',
  },
  errorIcon: {
    color: theme.statusColors.failure,
  },
  progress: {
    color: 'rgba(0,0,0,0.7)',
  },
  msp: {
    marginBottom: '5px',
    '& a': {
      color: '#0088CE',
      textDecoration: 'none',
      fontWeight: 500,
    },
  },
});

UserLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  handleLogin: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  handleBack: PropTypes.func.isRequired,
  error: PropTypes.string,
};

UserLogin.defaultProps = {
  error: '',
};

export default withStyles(styles, { name: 'UserLogin' })(UserLogin);
