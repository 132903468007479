import { connect } from 'react-redux';
import ExceptionTable from '../../components/basic_filtering/ExceptionTable';
import {
  sortExceptions,
  updateExceptions,
} from '../../actions/basic_filtering';

const mapStateToProps = state => ({
  data: state.basic_filtering.exceptionConfig.domains,
  editSelected: state.basic_filtering.exceptionConfig.editSelected,
});

const mapDispatchToProps = dispatch => ({
  sortHandler: (orderBy, order) => {
    dispatch(sortExceptions(orderBy, order));
  },
  deleteHandler: (domain, selected) => {
    dispatch(updateExceptions([], [domain], selected));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExceptionTable);
