import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import SeparatePaginator from '../table/SeparatePaginator';
import CudaTableTitle from '../CudaTableTitle';
import TableBody from '../table/TableBody';

import ExpandableRow from '../../containers/wf_logs/ExpandableRow';
import ExportButton from '../../containers/wf_logs/ExportButton';
import RefreshButton from '../../containers/wf_logs/RefreshButton';
import ColumnChooser from '../../containers/wf_logs/ColumnChooser';

import Paper from '../CudaPaper';

class WFLogTable extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.toggleSort = this.toggleSort.bind(this);
  }

  handleChangePage(event, page) {
    this.props.changePage(page, this.props.rowsPerPage);
  }

  handleChangeRowsPerPage(event) {
    const page = Math.floor(
      (this.props.page * this.props.rowsPerPage) / event.target.value
    );
    this.props.changePage(page, event.target.value);
  }

  toggleSort(field) {
    let direction = 'asc';

    if (field === this.props.sort.field) {
      direction = this.props.sort.direction === 'asc' ? 'desc' : 'asc';
    }

    this.props.updateSort({
      direction,
      field,
    });
  }

  render() {
    const {
      classes,
      loading,
      logs,
      sort,
      page,
      rowsPerPage,
      logCount,
      columns,
    } = this.props;
    const { handleChangePage, handleChangeRowsPerPage, toggleSort } = this;
    const colCount =
      Object.keys(columns).filter(column => columns[column].selected).length +
      1;

    return (
      <div>
        <Grid
          container
          spacing={0}
          className={classNames(classes.paginateWrapper, classes.paginateTop)}
        >
          <Grid item xs={12} md={6}>
            <RefreshButton data-test-id="refreshLogs" />
            <span className={classes.separator} />
            <ExportButton data-test-id="exportLogs" />
            <span className={classes.separator} />
            <ColumnChooser data-test-id="columns" />
            <span className={classes.separator} />
          </Grid>
          <Grid item xs={12} md={6} data-test-id="top-paginator-wrapper">
            <SeparatePaginator
              count={logCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper className={classes.tableRoot}>
              <Table className={classes.table} data-test-id="wf-LogsTable">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        width: '20px',
                        padding: '0 0 0 24px',
                      }}
                    />
                    {columns.date.selected && (
                      <TableCell data-test-id="date">
                        <CudaTableTitle
                          value="shared.date"
                          sort
                          toggle={
                            sort.field === 'date' ? sort.direction : 'none'
                          }
                          onClick={() => {
                            toggleSort('date');
                          }}
                        />
                      </TableCell>
                    )}
                    {columns.action.selected && (
                      <TableCell data-test-id="action">
                        <CudaTableTitle value="components.wfLogs.action" />
                      </TableCell>
                    )}
                    {columns.user.selected && (
                      <TableCell data-test-id="user">
                        <CudaTableTitle value="components.wfLogs.userOrLocation" />
                      </TableCell>
                    )}
                    {columns.rule.selected && (
                      <TableCell data-test-id="rule">
                        <CudaTableTitle value="components.wfLogs.rule" />
                      </TableCell>
                    )}
                    {columns.supercategory.selected && (
                      <TableCell data-test-id="supercategories">
                        <CudaTableTitle value="components.wfLogs.supercategories" />
                      </TableCell>
                    )}
                    {columns.category.selected && (
                      <TableCell data-test-id="categories">
                        <CudaTableTitle value="components.wfLogs.categories" />
                      </TableCell>
                    )}
                    {columns.location.selected && (
                      <TableCell data-test-id="name">
                        <CudaTableTitle value="components.wfLogs.locationName" />
                      </TableCell>
                    )}
                    {columns.url.selected && (
                      <TableCell data-test-id="destination">
                        <CudaTableTitle value="components.wfLogs.url" />
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody
                  loading={loading}
                  rowCount={logs.length}
                  colCount={colCount}
                  lid="components.wfLogs.noResults"
                >
                  {logs.length > 0 &&
                    logs
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((n, i) => (
                        <ExpandableRow
                          index={i}
                          key={n.id}
                          n={n}
                          columns={columns}
                          colCount={colCount}
                        />
                      ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className={classNames(classes.paginateWrapper, classes.paginateBot)}
          justify="flex-end"
        >
          <Grid item xs={12} md={6} data-test-id="bottom-paginator-wrapper">
            <SeparatePaginator
              count={logCount}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

WFLogTable.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  logCount: PropTypes.number.isRequired,
  logs: PropTypes.array.isRequired,
  sort: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.string,
  }).isRequired,
  updateSort: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
};

const styles = theme => ({
  table: {
    backgroundColor: '#FFFFFF',
    position: 'relative',
    '& thead': {
      '& tr': {
        height: '50px',
      },
    },
    '& tr': {
      height: '32px',
      borderLeft: '1px solid #EEEEEE',
      borderRight: '1px solid #EEEEEE',
    },
    '& td': {
      padding: '4px 12px',
    },
    '& th': {
      padding: '4px 12px',
    },
  },
  tableRoot: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    margin: 0,
  },
  separator: {
    borderRight: '1px solid #DDD',
    height: '32px',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  paginateWrapper: {
    width: '100%',
    display: 'flex',
    border: '1px solid #DDDDDD',
  },
  paginateBot: {
    borderTop: 0,
    borderRadius: '0 0 2px 2px',
  },
  paginateTop: {
    borderBottom: 0,
    borderRadius: '2px 2px 0 0',
  },
});

export default withStyles(styles, { name: 'WFLogTable' })(WFLogTable);
