export const SMB_ACCOUNT_TOGGLE_UPLOAD = 'SMB_ACCOUNT_TOGGLE_UPLOAD';
export const SMB_ACCOUNT_UPLOAD_LOGO = 'SMB_ACCOUNT_UPLOAD_LOGO';
export const SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS =
  'SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS';
export const SMB_ACCOUNT_UPLOAD_LOGO_FAILURE =
  'SMB_ACCOUNT_UPLOAD_LOGO_FAILURE';
export const SMB_ACCOUNT_GET_INFO = 'SMB_ACCOUNT_GET_INFO';
export const SMB_ACCOUNT_GET_INFO_SUCCESS = 'SMB_ACCOUNT_GET_INFO_SUCCESS';
export const SMB_ACCOUNT_GET_INFO_FAILURE = 'SMB_ACCOUNT_GET_INFO_FAILURE';
export const SMB_ACCOUNT_TOGGLE_PREVIEW = 'SMB_ACCOUNT_TOGGLE_PREVIEW';
export const SMB_ACCOUNT_CHANGE_LOGO = 'SMB_ACCOUNT_CHANGE_LOGO';
export const SMB_ACCOUNT_CHANGE_LOGO_SUCCESS =
  'SMB_ACCOUNT_CHANGE_LOGO_SUCCESS';

export const toggleUploadModal = show => ({
  type: SMB_ACCOUNT_TOGGLE_UPLOAD,
  show,
});

export const uploadLogo = logo => ({
  type: SMB_ACCOUNT_UPLOAD_LOGO,
  logo,
});

export const uploadLogoSuccess = logo => ({
  type: SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS,
  logo,
});

export const uploadLogoFailure = error => ({
  type: SMB_ACCOUNT_UPLOAD_LOGO_FAILURE,
  error,
});

export const getAccountInfo = () => ({
  type: SMB_ACCOUNT_GET_INFO,
});

export const getAccountInfoSuccess = data => ({
  type: SMB_ACCOUNT_GET_INFO_SUCCESS,
  ...data,
});

export const getAccountInfoFailure = error => ({
  type: SMB_ACCOUNT_GET_INFO_FAILURE,
  error,
});

export const togglePreview = show => ({
  type: SMB_ACCOUNT_TOGGLE_PREVIEW,
  show,
});

export const changeLogo = etag => ({
  type: SMB_ACCOUNT_CHANGE_LOGO,
  etag,
});

export const changeLogoSuccess = () => ({
  type: SMB_ACCOUNT_CHANGE_LOGO_SUCCESS,
});

export const SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION =
  'SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION';
export const updateAccountOption = options => ({
  type: SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION,
  options,
});

export const SMB_ACCOUNT_RESET = 'SMB_ACCOUNT_RESET';
export const resetSettings = () => ({
  type: SMB_ACCOUNT_RESET,
});

export const SMB_ACCOUNT_SAVE = 'SMB_ACCOUNT_SAVE';
export const saveSettings = () => ({
  type: SMB_ACCOUNT_SAVE,
});
export const SMB_ACCOUNT_SAVE_SUCCESS = 'SMB_ACCOUNT_SAVE_SUCCESS';
export const saveSettingsSuccess = result => ({
  type: SMB_ACCOUNT_SAVE_SUCCESS,
  result,
});
export const SMB_ACCOUNT_SAVE_FAILURE = 'SMB_ACCOUNT_SAVE_FAILURE';
export const saveSettingsFailure = errors => ({
  type: SMB_ACCOUNT_SAVE_FAILURE,
  errors,
});

export const SMB_ACCOUNT_LOGO_ERROR = 'SMB_ACCOUNT_LOGO_ERROR';
export const logoErrors = errors => ({
  type: SMB_ACCOUNT_LOGO_ERROR,
  errors,
});
