import React, { Component } from 'react';
import ReactGA from 'react-ga';
import DocumentTitle from 'react-document-title';
import { Translate, I18n } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import SectionHeader from '../components/SectionHeader';
import SearchBar from '../components/SearchBar';
import PaginatorComponent from '../components/Paginator';
import {
  LogIcon,
  ClipboardIcon,
  CriticalIcon,
  WarningIcon,
} from '../components/icons';
import CudaMenu from '../components/CudaMenu';
import CudaTableTitle from '../components/CudaTableTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router';

import Timestamp from './Timestamp';

import {
  updateQuery,
  firstPage,
  nextPage,
  prevPage,
  lastPage,
  updateRows,
  updateRange,
  initLogs,
  updateSort,
  resetPage,
  downloadPdf,
  downloadCsv,
} from '../actions/atplogs';
import { updateSharedTimeframe } from '../actions/atp_shared';

import Paper from '../components/CudaPaper';

const atpLogStyles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  menu: {
    float: 'right',
  },
  button: {
    width: '166px',
    height: '40px',
    margin: 0,
  },
  filterOptions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateTd: {
    paddingRight: '10px',
  },
  dateColumn: {
    width: '130px',
  },
  tableTd: {
    paddingRight: '5px',
  },
  logOptions: {
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  scanDetermination: {
    width: '100px',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  pathColumn: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileColumn: {
    width: '115px',
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  malicious: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '5px',
    },
  },
};

const columns = ['date', 'username', 'device', 'path', 'file'];

const rangeOptions = [
  {
    key: 'day',
    label: 'Last 24 hours',
  },
  {
    key: 'week',
    label: 'Last 7 days',
  },
  {
    key: 'month',
    label: 'Last 30 days',
  },
];

class ATPLogsComponent extends Component {
  state = {
    anchorEl: null,
    open: false,
    itemsPerPage: 10,
    moreAnchorEl: null,
    moreOpen: false,
    icons: ['desc', 'none', 'none', 'none', 'none'],
  };

  toggle(index) {
    const { updateSort } = this.props;

    let value = this.state.icons[index];
    let icons = ['none', 'none', 'none', 'none', 'none'];
    if (value === 'asc') {
      value = 'desc';
    } else {
      value = 'asc';
    }

    icons[index] = value;
    this.setState({ icons: icons });
    updateSort(columns[index], value);
  }

  handleMoreClick = (event, index) => {
    this.setState({ moreOpen: index, moreAnchorEl: event.currentTarget });
  };

  handleCSVExportClick = () => {
    const { timezone, downloadCsv } = this.props;
    ReactGA.event({
      category: 'ATPLogs',
      action: 'Export CSV',
    });
    downloadCsv(rangeOptions[this.props.selectedIndex]['key']);
  };

  handlePdfClick = cuid => {
    const { downloadPdf } = this.props;
    ReactGA.event({
      category: 'ATPLogs',
      action: 'View Report',
    });
    downloadPdf(cuid);
    this.setState({ moreOpen: false, moreAnchorEl: null });
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false, moreOpen: false });
  };

  handleRequestClose = option => {
    this.setState({ itemsPerPage: option, open: false });
    this.props.updateRows(option);
  };

  componentDidMount() {
    let query = '';

    if (this.props.location.search) {
      let matches = this.props.location.search.match(/q=([^&]*)/);
      if (matches.length > 1) query = matches[1];
    }
    this.props.updateQuery(query);
  }

  componentWillUnmount() {
    const { resetPage } = this.props;

    resetPage();
  }

  render() {
    const {
      classes,
      logs,
      query,
      total,
      filters,
      selectedIndex,
      loading,
      pdfCuid,
      csvLoading,
    } = this.props;
    const {
      firstPage,
      nextPage,
      prevPage,
      lastPage,
      updateQuery,
      updateRange,
    } = this.props;
    const scanDeterminationClass = classNames(
      classes.tableTd,
      classes.scanDetermination
    );
    const options = [10, 20, 30];
    return (
      <DocumentTitle title={I18n.t('components.atpLogs.pageTitle')}>
        <div>
          <SectionHeader
            icon={<LogIcon />}
            title={I18n.t('components.atpLogs.title')}
            tooltipText="Tooltip Text"
            options={
              <span>
                <CudaMenu
                  height={40}
                  options={rangeOptions}
                  onChange={index => {
                    updateRange(rangeOptions[index]['key'], index);
                  }}
                  selectedIndex={selectedIndex}
                />
              </span>
            }
          />
          <Grid container spacing={24} justify="space-between">
            <Grid item xs={6}>
              <SearchBar
                height="40px"
                value={query}
                onChange={value => {
                  updateQuery(value);
                }}
                placeholder={I18n.t('components.atpLogs.search')}
                autoFocus={false}
                disabled={false}
              />
            </Grid>
            <Grid className={classes.filterOptions} item xs={6}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={() => {
                  this.handleCSVExportClick();
                }}
                disabled={csvLoading}
              >
                {csvLoading ? (
                  <CircularProgress style={{ width: '20px', height: '20px' }} />
                ) : (
                  <Translate value="shared.downloadCSV" />
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <CudaTableTitle
                          value="shared.date"
                          sort={true}
                          toggle={this.state.icons[0]}
                          onClick={() => {
                            this.toggle(0);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle
                          value="shared.user"
                          sort={true}
                          toggle={this.state.icons[1]}
                          onClick={() => {
                            this.toggle(1);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle
                          value="shared.endpointName"
                          sort={true}
                          toggle={this.state.icons[2]}
                          onClick={() => {
                            this.toggle(2);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle
                          value="shared.path"
                          sort={true}
                          toggle={this.state.icons[3]}
                          onClick={() => {
                            this.toggle(3);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle
                          value="shared.file"
                          sort={true}
                          toggle={this.state.icons[4]}
                          onClick={() => {
                            this.toggle(4);
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle value="shared.scanDetermination" />
                      </TableCell>
                      <TableCell>
                        <CudaTableTitle value="shared.view" />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!loading && logs.length > 0
                      ? logs.map((n, i) => {
                          return (
                            <TableRow key={i}>
                              <TableCell className={classes.dateTd}>
                                <div className={classes.dateColumn}>
                                  <Timestamp
                                    timestamp={n['_source'].timestamp}
                                  />
                                </div>
                              </TableCell>
                              <TableCell className={classes.tableTd}>
                                {n['_source']['user']}
                              </TableCell>
                              <TableCell className={classes.tableTd}>
                                {n['_source']['beat']
                                  ? n['_source']['beat']['hostname']
                                  : ''}
                              </TableCell>
                              <TableCell className={classes.tableTd}>
                                <Tooltip
                                  id="tooltip-top-start"
                                  title={n['_source']['scan_path'].replace(
                                    /[^\\/]*$/,
                                    ''
                                  )}
                                  placement="top-start"
                                >
                                  <div className={classes.pathColumn}>
                                    {n['_source']['scan_path'].replace(
                                      /[^\\/]*$/,
                                      ''
                                    )}
                                  </div>
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <Tooltip
                                  id="tooltip-top-start"
                                  title={n['_source']['scan_path'].replace(
                                    /^.*[\\/]/,
                                    ''
                                  )}
                                  placement="top-start"
                                >
                                  <div className={classes.fileColumn}>
                                    {n['_source']['scan_filename']}
                                  </div>
                                </Tooltip>
                              </TableCell>
                              <TableCell className={scanDeterminationClass}>
                                {n['_source']['scan_result'] === 'malicious' ? (
                                  <div className={classes.malicious}>
                                    <CriticalIcon />
                                    <Translate value="shared.malicious" />
                                  </div>
                                ) : null}
                                {n['_source']['scan_result'] ===
                                'suspicious' ? (
                                  <div className={classes.malicious}>
                                    <WarningIcon />
                                    <Translate value="shared.suspicious" />
                                  </div>
                                ) : null}
                                {n['_source']['scan_result'] === 'encrypted' ? (
                                  <div className={classes.malicious}>
                                    <Translate value="shared.encrypted" />
                                  </div>
                                ) : null}
                              </TableCell>
                              <TableCell>
                                {pdfCuid === n['_source']['batd_scan_cuid'] ? (
                                  <CircularProgress />
                                ) : (
                                  <IconButton
                                    aria-label="View"
                                    aria-owns={null}
                                    aria-haspopup="true"
                                    onClick={() =>
                                      this.handlePdfClick(
                                        n['_source']['batd_scan_cuid']
                                      )
                                    }
                                  >
                                    <ClipboardIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : undefined}
                    {!loading && logs.length === 0 ? (
                      <TableRow>
                        <TableCell className={classes.noResults} colSpan="7">
                          <Translate value="shared.noResults" />
                        </TableCell>
                      </TableRow>
                    ) : (
                      undefined
                    )}
                    {loading ? (
                      <TableRow>
                        <TableCell className={classes.noResults} colSpan="7">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      undefined
                    )}
                  </TableBody>
                </Table>
                <div>
                  <PaginatorComponent
                    text="Rows per page: "
                    itemsTotal={total}
                    pageNumber={filters.index}
                    onPaginateClick={this.handleClick}
                    onFirstClick={firstPage}
                    onLastClick={lastPage}
                    onNextClick={nextPage}
                    onPreviousClick={prevPage}
                    itemsPerPage={this.state.itemsPerPage}
                  />
                  <Menu
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                  >
                    {options.map(option => (
                      <MenuItem
                        key={option}
                        onClick={() => {
                          this.handleRequestClose(option);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.atplogs.loading,
  total: state.atplogs.total,
  filters: state.atplogs.filters,
  logs: state.atplogs.logs,
  query: state.atplogs.query,
  account_id: state.account.selected,
  timezone: state.account.time_zone,
  selectedIndex: state.atp_shared.timeSelectIndex,
  pdfCuid: state.atplogs.pdfCuid,
  csvLoading: state.atplogs.csv.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initLogs: () => {
    dispatch(initLogs());
  },
  updateQuery: query => {
    dispatch(updateQuery(query));
  },
  updateRows: rows => {
    dispatch(updateRows(rows));
  },
  firstPage: () => {
    dispatch(firstPage());
  },
  nextPage: () => {
    dispatch(nextPage());
  },
  prevPage: () => {
    dispatch(prevPage());
  },
  lastPage: () => {
    dispatch(lastPage());
  },
  updateRange: (range, index) => {
    dispatch(updateSharedTimeframe(range, index));
    dispatch(updateRange(range, index));
  },
  updateSort: (column, order) => {
    dispatch(updateSort(column, order));
  },
  resetPage: () => {
    dispatch(resetPage());
  },
  downloadPdf: cuid => {
    dispatch(downloadPdf(cuid));
  },
  downloadCsv: range => {
    dispatch(downloadCsv(range));
  },
});

const ATPLogs = withRouter(
  withStyles(atpLogStyles, { name: 'ATPLogs' })(ATPLogsComponent)
);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ATPLogs);
