import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CudaMenu from '../CudaMenu';
import SearchBar from '../SearchBar';

class ActionBarComponent extends Component {
  state = {};

  render() {
    const {
      classes,
      query,
      handleQuery,
      rangeOptions,
      updateRange,
      selectedIndex,
    } = this.props;

    return (
      <Grid container spacing={0} justify="space-between">
        <Grid item xs={6}>
          <SearchBar
            height="40px"
            value={query}
            onChange={value => {
              handleQuery(value);
            }}
            placeholder={I18n.t('components.quarantine.search')}
            autoFocus={false}
            disabled={false}
          />
        </Grid>
        <Grid className={classes.filterOptions} item xs={6}>
          <div className={classes.buttonWrapper}>
            <CudaMenu
              height={40}
              options={rangeOptions}
              onChange={index => {
                updateRange(rangeOptions[index]['key'], index);
              }}
              selectedIndex={selectedIndex}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}
ActionBarComponent.propTypes = {
  query: PropTypes.string.isRequired,
  handleQuery: PropTypes.func.isRequired,
  rangeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  updateRange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

const styles = {
  delete: {
    backgroundColor: '#DD1E31',
  },
  button: {
    height: '40px',
    margin: '0 5px',
    paddingTop: '10px',
    paddingBottom: '10px',
    lineHeight: '20px',
    '& svg': {
      marginRight: '8px',
    },
  },
  buttonWrapper: {
    float: 'right',
  },
};

export default withStyles(styles, { name: 'ActionBarStyles' })(
  ActionBarComponent
);
