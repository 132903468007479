import { createSelector } from 'reselect';
import { reportTypes } from '../actions/reports';

const getSubscriptions = state => state.account.subscriptions;
const getTypes = () => reportTypes;

const getFilteredTypes = createSelector(
  [getTypes, getSubscriptions],
  (options, subscriptions) => {
    const groupedOptions = [];

    options.forEach(option => {
      if (option.type === 'label') {
        groupedOptions[groupedOptions.length] = [option];
      } else if (option.subscriptions.some(sub => subscriptions[sub])) {
        groupedOptions[groupedOptions.length - 1].push(option);
      }
    });
    return [].concat(...groupedOptions.filter(group => group.length > 1));
  }
);

export { getFilteredTypes };
