import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';

import ReportModal from '../../containers/reports/Modal';
import CudaMenu from '../CudaMenu';
import Paper from '../CudaPaper';

const styles = {
  headers: {
    height: '14px',
    width: '64px',
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
  },
  paper: {
    padding: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  reportBtn: {
    height: '32px',
    lineHeight: '1px',
    margin: '0 16px 0 0',
    padding: '8px 21px',
    fontWeight: '200',
  },
  wrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    margin: '8px',
  },
  buttonWrapper: {
    display: 'inline-flex',
    margin: '8px',
  },
};

class Selector extends Component {
  componentDidMount = () => {
    const { init, mainOptions } = this.props;
    init(mainOptions);
  };

  render() {
    const {
      classes,
      mainOptions,
      timeOptions,
      mainSelectHandler,
      timeSelectHandler,
      executeHandler,
      mainSelectIndex,
      timeSelectIndex,
      openModal,
      reportSelect,
      timeSelect,
      rows,
      index,
    } = this.props;

    return (
      <Paper>
        <div className={classes.paper}>
          <div className={classes.wrapper}>
            <Translate
              value="shared.timeFrame"
              tag="div"
              className={classes.headers}
            />
            <CudaMenu
              options={timeOptions}
              onChange={timeSelectHandler}
              selectedIndex={timeSelectIndex}
            />
          </div>
          <div className={classes.wrapper}>
            <Translate
              value="shared.reportType"
              tag="div"
              className={classes.headers}
            />
            <CudaMenu
              options={mainOptions}
              onChange={index => {
                mainSelectHandler(index, mainOptions);
              }}
              selectedIndex={mainSelectIndex}
            />
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                executeHandler(reportSelect, timeSelect, rows, index);
              }}
              className={classes.reportBtn}
              disabled={false}
            >
              <Translate value="shared.view" />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                openModal(mainOptions);
              }}
              className={classes.reportBtn}
              disabled={false}
            >
              <Translate value="shared.schedule" />
            </Button>
          </div>
          <ReportModal />
        </div>
      </Paper>
    );
  }
}

Selector.propTypes = {
  classes: PropTypes.object.isRequired,
  mainOptions: PropTypes.array.isRequired,
  timeOptions: PropTypes.array.isRequired,
  mainSelectHandler: PropTypes.func.isRequired,
  timeSelectHandler: PropTypes.func.isRequired,
  init: PropTypes.func.isRequired,
  executeHandler: PropTypes.func.isRequired,
  mainSelectIndex: PropTypes.number.isRequired,
  timeSelectIndex: PropTypes.number.isRequired,
  openModal: PropTypes.func.isRequired,
  reportSelect: PropTypes.string.isRequired,
  timeSelect: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default withStyles(styles)(Selector);
