import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'react-i18nify';
import SectionHeader from './SectionHeader';
import Preferences from '../containers/account/Preferences';
import Password from '../containers/account/Password';
import StatusBar from '../containers/account/UpdateStatusBar';
import { withRouter } from 'react-router';
import {
  AccountSettingsIcon,
  CASIcon,
  CCBIcon,
  ESSIcon,
  EssentialsIcon,
  BVMIcon,
  VRSIcon,
} from './icons';

class AccountSettingsComponent extends Component {
  componentWillMount() {
    this.props.history.replace({
      pathame: '/account_settings',
      state: {},
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <DocumentTitle title={I18n.t('components.account.page_title')}>
        <Grid container justify="center">
          <Grid item xs={10}>
            <SectionHeader
              icon={<AccountSettingsIcon className={classes.headerIcon} />}
              title={I18n.t('components.account.title')}
              help={true}
            >
              <div className={classes.subText}>
                {I18n.t('components.account.subheader')}
              </div>
              <div className={classes.icons}>
                <EssentialsIcon />
                <ESSIcon />
                <CCBIcon />
                <CASIcon />
                <BVMIcon />
                <VRSIcon />
              </div>
            </SectionHeader>
            <Grid container justify="space-between" spacing={24}>
              <Grid item xs={6}>
                <Preferences />
              </Grid>
              <Grid item xs={6}>
                <Password />
              </Grid>
            </Grid>
          </Grid>
          <StatusBar />
        </Grid>
      </DocumentTitle>
    );
  }
}
AccountSettingsComponent.propTypes = {};

const styles = theme => ({
  saveMessage: {
    height: 48,
  },
  headerIcon: {
    height: '24px',
    width: '24px',
  },
  icons: {
    marginTop: '15px',
    '& > svg': {
      height: '34px',
      width: '34px',
      marginRight: '16px',
    },
  },
  subText: {
    marginTop: '16px',
  },
});

export default withStyles(styles, { name: 'AccountSettings' })(
  withRouter(AccountSettingsComponent)
);
