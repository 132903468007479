import { call, put, select, takeEvery } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { I18n } from 'react-i18nify';
import Api from './Api';
import * as AppTypes from '../actions/app';
import * as Types from '../actions/advanced_filtering';
import * as AccountTypes from '../actions/account';
import { getAccountId } from '../selectors/account';

const update = require('immutability-helper');

const defaultConfig = {
  ruleModal: {
    adGroup: [],
    selectedTypeIndex: 0,
    selectedActionIndex: 0,
    domains: [],
    urls: [],
    categories: [],
    inputIndex: 0,
  },
};

const defaultSearch = {
  query: '',
  queryField: 0,
  match: {
    query: '',
    type: '',
  },
};

export default (
  state = {
    ruleModal: {
      ...defaultConfig.ruleModal,
    },
    addRuleModal: {
      show: false,
      loading: false,
      disabled: false,
    },
    selectedRule: {
      loading: false,
      show: false,
      id: '',
      ruleset_id: '',
      error: '',
    },
    search: {
      ...defaultSearch,
      queryFields: [
        {
          label: I18n.t('components.wfLogs.userId'),
          field: 'users',
        },
        {
          label: I18n.t('components.advancedFiltering.groups'),
          field: 'groups',
        },
        {
          label: I18n.t('components.wfLogs.categories'),
          field: 'categories',
        },
        {
          label: I18n.t('components.advancedFiltering.domains'),
          field: 'domains',
          type: 'input',
        },
      ],
    },
    highlightedRule: '',
    userToRuleset: {},
    processing: false,
    processingRule: false,
    groupToRuleset: {},
    ruleset: {
      rules: [],
    },
  },
  action
) => {
  let rulesetRules;
  let newIndex;
  let index;
  let order;

  switch (action.type) {
    case Types.AF_APPLY_SEARCH_FILTER:
      if (!state.search.query) {
        return {
          ...state,
          search: {
            ...state.search,
            match: {
              ...state.search.match,
              query: '',
              type: '',
            },
          },
        };
      }

      return {
        ...state,
        processing: true,
        search: {
          ...state.search,
          match: {
            ...state.search.match,
            query: state.search.query,
            type: state.search.queryFields[state.search.queryField]['field'],
          },
        },
      };
    case Types.AF_GET_RULESETS:
      return {
        ...state,
        processing: true,
      };
    case Types.SUBMIT_RULES_REORDER:
    case Types.SUBMIT_RULESETS_REORDER:
      return {
        ...state,
        processingRule: true,
      };
    case Types.HIGHLIGHT_RULE:
      return {
        ...state,
        highlightedRule: action.rule,
      };
    case Types.SELECT_AF_INPUT_INDEX:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          inputIndex: action.index,
        },
      };
    case Types.SELECT_AF_TYPE:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          selectedTypeIndex: action.index,
        },
      };
    case Types.SELECT_AF_ACTION:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          selectedActionIndex: action.index,
        },
      };
    case Types.AF_CHANGE_DOMAIN:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          domains: action.value,
          inputIndex: action.value.length,
        },
      };
    case Types.AF_CHANGE_URL:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          urls: action.value,
          inputIndex: action.value.length,
        },
      };
    case Types.AF_CHANGE_AD:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          adGroup: action.value,
        },
      };
    case Types.GET_RULESET_SUCCESS:
      return {
        ...state,
        userToRuleset: action.userToRuleset,
        groupToRuleset: action.groupToRuleset,
        ruleset: action.ruleset,
        processing: false,
      };
    case Types.AF_CLOSE_EDIT_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          error: '',
          show: false,
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
        },
      };
    case Types.AF_ADD_RULE:
      return {
        ...state,
        addRuleModal: {
          ...state.addRuleModal,
          show: false,
        },
      };
    case Types.AF_OPEN_ADD_RULE:
      return {
        ...state,
        addRuleModal: {
          ...state.addRuleModal,
          show: true,
          disabled: action.disabled,
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
          adGroup: action.selectedAD || [],
        },
      };
    case Types.AF_CLOSE_ADD_RULE:
      return {
        ...state,
        addRuleModal: {
          ...state.addRuleModal,
          show: false,
        },
      };
    case Types.AF_OPEN_EDIT_RULESET:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          show: true,
          id: action.rule.id,
          display: 'ruleset',
          ruleset_id: action.rule.ruleset_id,
        },
        ruleModal: {
          ...state.ruleModal,
          adGroup: action.selectedAD,
        },
      };
    case Types.AF_OPEN_EDIT_RULE:
      let typeIndex = 0;
      let selectIndex = 0;
      let domains = [];
      let urls = [];
      let categories = [];

      if (action.rule.type === 'category') {
        typeIndex = Types.TYPE_OPTIONS.findIndex(x => x.key === 'categories');
        categories = [...action.rule.categories];
      } else if (action.rule.type === 'domain') {
        typeIndex = Types.TYPE_OPTIONS.findIndex(x => x.key === 'domains');
        selectIndex = action.rule.domains.length;
        domains = [...action.rule.domains];
      } else if (action.rule.type === 'url') {
        typeIndex = Types.TYPE_OPTIONS.findIndex(x => x.key === 'url');
        selectIndex = action.rule.urls.length;
        urls = [...action.rule.urls];
      }

      let data = {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          show: true,
          id: action.rule.id,
          display: undefined,
          ruleset_id: action.rule.ruleset_id,
        },
        ruleModal: {
          ...state.ruleModal,
          adGroup: action.selectedAD,
          selectedTypeIndex: typeIndex,
          selectedActionIndex: Types.ACTION_OPTIONS.findIndex(
            x => x.key === action.rule.action
          ),
          domains: domains,
          urls: urls,
          categories: categories,
          inputIndex: selectIndex,
        },
      };

      return data;
    case Types.AF_EDIT_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          error: '',
          show: false,
        },
      };
    case Types.AF_EDIT_RULE_ERROR:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          error: action.error,
          show: true,
        },
      };
    case Types.AF_DELETE_RULESET_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          id: action.rule_id,
        },
      };
    case Types.AF_DELETE_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          id: action.id,
          ruleset_id: action.ruleset_id,
        },
      };
    case Types.AF_SEARCH_INPUT_ONCHANGE:
      return {
        ...state,
        search: {
          ...state.search,
          query: action.value,
        },
      };
    case Types.AF_SEARCH_FIELD_ONCHANGE:
      return {
        ...state,
        search: {
          ...state.search,
          query: '',
          queryField: action.field,
        },
      };
    case Types.AF_RESET_SEARCH:
      return {
        ...state,
        search: {
          ...state.search, // Keep the options
          ...defaultSearch,
        },
      };
    case Types.AF_UPDATE_SELECTED_CATEGORIES:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          categories: [
            ...state.ruleModal.categories.filter(
              cat => action.removed.indexOf(cat) === -1
            ),
            ...action.added,
          ],
        },
      };
    case Types.REORDER_RULES:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  $splice: [
                    [action.dragIndex, 1],
                    [
                      action.hoverIndex,
                      0,
                      state.ruleset.rules[action.parentIndex].ruleset_jump
                        .rules[action.dragIndex],
                    ],
                  ],
                },
              },
            },
          },
        }),
      };
    case Types.UPDATE_RULE_ORDER:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  [action.index]: {
                    $apply: rule => ({ ...rule, index: action.order }),
                  },
                },
              },
            },
          },
        }),
      };
    case Types.SUBMIT_RULE_ORDER:
      index = parseInt(action.originalIndex, 10);
      rulesetRules = state.ruleset.rules[action.parentIndex].ruleset_jump.rules;
      order = Object.prototype.hasOwnProperty.call(action, 'index')
        ? parseInt(action.index, 10)
        : parseInt(rulesetRules[index].index, 10);

      newIndex = order >= rulesetRules.length ? rulesetRules.length - 1 : order;

      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  $apply: newRules => {
                    let newArr = newRules.slice();
                    newArr.splice(newIndex, 0, ...newArr.splice(index, 1));
                    return newArr.map((n, i) => ({ ...n, index: i }));
                  },
                },
              },
            },
          },
        }),
      };
    case Types.REORDER_RULESETS:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            $splice: [
              [action.dragIndex, 1],
              [action.hoverIndex, 0, state.ruleset.rules[action.dragIndex]],
            ],
          },
        }),
      };
    case Types.SUBMIT_RULESET_ORDER:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            $splice: [
              [action.originalIndex, 1],
              [action.index, 0, state.ruleset.rules[action.originalIndex]],
            ],
          },
        }),
      };
    case Types.SUBMIT_RULES_REORDER_SUCCESS:
      return {
        ...state,
        processingRule: false,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  $apply: rules => rules.map((n, i) => ({ ...n, index: i })),
                },
              },
            },
          },
        }),
      };
    case Types.SUBMIT_RULE_ORDER_SUCCESS:
    case Types.SUBMIT_RULESETS_REORDER_SUCCESS:
      return {
        ...state,
        processingRule: false,
      };
    case Types.SUBMIT_RULE_ORDER_FAILURE:
    case Types.SUBMIT_RULESETS_REORDER_FAILURE:
    case Types.SUBMIT_RULES_REORDER_FAILURE:
      return {
        ...state,
        processingRule: false,
      };
    default:
      return state;
  }
};

function* addRule() {
  try {
    const store = yield select();
    const ruleData = store.advanced_filtering.ruleModal;
    const ruleset = store.advanced_filtering.ruleset;
    const userToRuleset = store.advanced_filtering.userToRuleset;
    const groupToRuleset = store.advanced_filtering.groupToRuleset;
    const adGroup = ruleData.adGroup;
    const adMap = store.account.adMap;

    const selectedType = Types.TYPE_OPTIONS[ruleData.selectedTypeIndex]['key'];

    let errors = [];
    for (let i = 0; i < adGroup.length; i++) {
      try {
        let rulesetId;
        if (
          adGroup[i]['value'] !== 'everyone' &&
          !userToRuleset[adGroup[i]['value']] &&
          !groupToRuleset[adGroup[i]['value']]
        ) {
          // Create ruleset for user or group
          let jumpsetBody = {
            account_id: store.account.selected,
            ruleset_id: ruleset.id,
            index: 0,
            action: 'jump',
            type: Types.RULE_TYPES['jump'],
          };

          if (adGroup[i]['type'] === 'user') {
            jumpsetBody['user_ids'] = [adGroup[i]['value']];
            jumpsetBody['rule_name'] =
              adMap[adGroup[i]['value']]['display_name'];
          } else {
            jumpsetBody['group_ids'] = [adGroup[i]['value']];
            jumpsetBody['rule_name'] = adMap[adGroup[i]['value']]['group_name'];
          }

          const result = yield call(Api.ruleSets.create, {
            account_id: store.account.selected,
          });

          jumpsetBody['ruleset_jump'] = result['id'];

          yield call(Api.rules.create, jumpsetBody);
          rulesetId = result['id'];
        } else if (adGroup[i]['value'] === 'everyone') {
          rulesetId = groupToRuleset['everyone'];
          if (!rulesetId) {
            // Create ruleset for user or group
            let jumpsetBody = {
              account_id: store.account.selected,
              ruleset_id: ruleset.id,
              index: 9000, // index greater than ruleset to append to end. Policy Service will fix count
              action: 'jump',
              type: Types.RULE_TYPES['jump'],
              rule_name: 'everyone',
            };

            const result = yield call(Api.ruleSets.create, {
              account_id: store.account.selected,
            });

            jumpsetBody['ruleset_jump'] = result['id'];

            yield call(Api.rules.create, jumpsetBody);
            rulesetId = result['id'];
          }
        } else {
          // Get ruleset ID
          rulesetId =
            userToRuleset[adGroup[i]['value']] ||
            groupToRuleset[adGroup[i]['value']] ||
            adGroup[i]['value'];
        }

        let body = {
          account_id: store.account.selected,
          ruleset_id: rulesetId,
          index: 0,
          action: Types.ACTION_OPTIONS[ruleData.selectedActionIndex]['key'],
          type: Types.RULE_TYPES[selectedType],
        };

        if (selectedType === 'categories') {
          body['categories'] = ruleData.categories;
        } else if (selectedType === 'domains') {
          body['domains'] = ruleData.domains;
        } else if (selectedType === 'url') {
          body['urls'] = ruleData.urls;
        }

        yield call(Api.rules.create, body);
      } catch (error) {
        errors.push(adGroup[i]['label']);
      }
    }

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    // Display error or success message if any
    if (errors.length > 0) {
      yield put(
        AppTypes.error(
          I18n.t('components.advancedFiltering.createErrors', {
            resources: errors.join(', '),
          })
        )
      );
    } else {
      yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
    }
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* getRulesets() {
  try {
    const store = yield select();

    const activeDirectory = store.account.activeDirectory;
    if (activeDirectory.length === 0) {
      const adResult = yield call(Api.directory.read, {
        account_id: store.account.selected,
      });
      yield put(AccountTypes.getActiveDirectorySuccess(adResult));
    }

    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* getFilterRulesets() {
  try {
    const store = yield select();
    const query = store.advanced_filtering.search.match.query;
    const query_type = store.advanced_filtering.search.match.type;
    let result;

    if (query_type === 'users') {
      result = yield call(Api.ruleSets.users.read, {
        account_id: store.account.selected,
        user_id: query.user_id,
      });
    } else if (query_type === 'groups') {
      result = yield call(Api.ruleSets.groups.read, {
        account_id: store.account.selected,
        group_id: query.group_id,
      });
    } else {
      result = yield call(Api.ruleSets.read, {
        account_id: store.account.selected,
      });
    }
    yield put(Types.getRulesetSuccess(result));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* editRule() {
  try {
    const store = yield select();
    const ruleData = store.advanced_filtering.ruleModal;
    const selectedRule = store.advanced_filtering.selectedRule;

    const selectedType =
      selectedRule.display ||
      Types.TYPE_OPTIONS[ruleData.selectedTypeIndex]['key'];
    let body = {
      action: Types.ACTION_OPTIONS[ruleData.selectedActionIndex]['key'],
    };

    if (selectedType === 'ruleset') {
      const adGroup = ruleData.adGroup;
      body = {
        group_ids: adGroup.type === 'group' ? [adGroup.value] : [],
        user_ids: adGroup.type === 'user' ? [adGroup.value] : [],
      };
    } else if (selectedType === 'categories') {
      body['categories'] = ruleData.categories;
      if (body['categories'].length === 0) {
        return yield put(
          Types.editError(
            I18n.t('components.advancedFiltering.errors.emptyCategories')
          )
        );
      }
    } else if (selectedType === 'domains') {
      body['domains'] = ruleData.domains;
      if (body['domains'].length === 0) {
        return yield put(
          Types.editError(
            I18n.t('components.advancedFiltering.errors.emptyDomain')
          )
        );
      }
    } else if (selectedType === 'url') {
      body['urls'] = ruleData.urls;
      if (body['urls'].length === 0) {
        return yield put(
          Types.editError(
            I18n.t('components.advancedFiltering.errors.emptyUrl')
          )
        );
      }
    }

    yield call(
      Api.rules.update,
      {
        account_id: store.account.selected,
        ruleset_id: selectedRule.ruleset_id,
        rule_id: selectedRule.id,
      },
      body
    );

    // Get ruleset to update UI.
    yield call(getFilterRulesets);
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    console.log('the error: ', error);
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.message));
  }
}

function* deleteRule() {
  try {
    const store = yield select();
    const selectedRule = store.advanced_filtering.selectedRule;
    const rulesets = store.advanced_filtering.ruleset;

    let deleteRulesetRule = false;
    let rulesetRuleId;
    const rules = rulesets.rules;
    for (let i = 0; i < rules.length; i++) {
      if (
        rules[i]['ruleset_jump'] &&
        rules[i]['ruleset_jump']['id'] === selectedRule.ruleset_id &&
        rules[i]['ruleset_jump']['rules'].length <= 1
      ) {
        deleteRulesetRule = true;
        rulesetRuleId = rules[i]['id'];
        break;
      }
    }

    yield call(Api.rules.delete, {
      account_id: store.account.selected,
      ruleset_id: selectedRule.ruleset_id,
      rule_id: selectedRule.id,
    });

    if (deleteRulesetRule) {
      yield call(Api.ruleSets.delete, {
        account_id: store.account.selected,
        id: rulesetRuleId,
      });
    }

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* deleteRulesetRule() {
  try {
    const store = yield select();
    const selectedRule = store.advanced_filtering.selectedRule;

    yield call(Api.ruleSets.delete, {
      account_id: store.account.selected,
      id: selectedRule.id,
    });

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* reorderRules(action) {
  try {
    const accountId = yield select(getAccountId);

    yield call(
      Api.rules.update,
      {
        account_id: accountId,
        ruleset_id: action.rulesetId,
        rule_id: action.id,
      },
      {
        index: action.index,
      }
    );
    yield put(Types.submitRulesReorderSuccess(action.parentIndex));
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error('Failed'));
    yield put(
      Types.reorderRules(action.index, action.originalIndex, action.parentIndex)
    );
    yield put(Types.submitRulesReorderFailure());
  }
  yield delay(5000);
  yield put(AppTypes.clearError());
}

function* reorderRulesets(action) {
  try {
    const accountId = yield select(getAccountId);
    yield call(
      Api.rules.update,
      {
        account_id: accountId,
        ruleset_id: action.rulesetId,
        rule_id: action.id,
      },
      {
        index: action.index,
      }
    );
    yield put(Types.submitRulesetsReorderSuccess());
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error('Failed'));
    yield put(Types.reorderRulesets(action.index, action.originalIndex));
    yield put(Types.submitRulesetsReorderFailure());
  }
  yield delay(5000);
  yield put(AppTypes.clearError());
}

export function* advancedFilteringReducerFlow() {
  yield takeEvery(Types.AF_ADD_RULE, addRule);
  yield takeEvery(Types.AF_GET_RULESETS, getRulesets);
  yield takeEvery(Types.AF_EDIT_RULE, editRule);
  yield takeEvery(Types.AF_DELETE_RULE, deleteRule);
  yield takeEvery(Types.AF_DELETE_RULESET_RULE, deleteRulesetRule);
  yield takeEvery(Types.SUBMIT_RULES_REORDER, reorderRules);
  yield takeEvery(Types.SUBMIT_RULE_ORDER, reorderRules);
  yield takeEvery(Types.SUBMIT_RULESETS_REORDER, reorderRulesets);
  yield takeEvery(Types.SUBMIT_RULESET_ORDER, reorderRulesets);
  yield takeEvery(Types.AF_APPLY_SEARCH_FILTER, getFilterRulesets);
}
