import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import EditModal from '../modal/EditModal';
import AddPolicy from '../../containers/advanced_filtering/AddPolicy';

const AddPolicyModal = ({ showModal, closeDialog, buttonAction, disabled }) => (
  <EditModal
    title={I18n.t('components.advancedFiltering.create')}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    saveBtnText={I18n.t('shared.create')}
    disabled={disabled}
  >
    <AddPolicy />
  </EditModal>
);

AddPolicyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideNetwork: PropTypes.bool,
};

AddPolicyModal.defaultProps = {
  disabled: false,
};

export default AddPolicyModal;
