import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';

const customStyles = {
  container: (base, state) => ({
    ...base,
    border: '1px solid #DDDDDD',
    borderRadius: '2px',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.06)',
    background: 'linear-gradient(180deg, #FEFEFE 0%, #FAFAFA 100%)',
    opacity: state.isDisabled ? 0.5 : 1,
  }),
  dropdownIndicator: base => ({
    ...base,
    padding: '5px',
  }),
  menu: base => ({
    ...base,
    marginTop: 0,
    borderRadius: '2px',
    boxShadow: '0 8px 17px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
  }),
  menuList: base => ({
    ...base,
    padding: 0,
  }),
  control: base => ({
    ...base,
    border: 'none',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontSize: '14px',
    color: '#555555',
    minHeight: '30px',
    lineHeight: '18px',
  }),
  placeholder: base => ({
    ...base,
    color: '#555555',
    opacity: '0.5',
    margin: '0',
    cursor: 'text',
  }),
  option: (base, state) => ({
    ...base,
    fontSize: '14px',
    lineHeight: '24px',
    padding: '8px',
    color: '#000000',
    backgroundColor: state.isFocused ? '#DEEBFF' : '#FFFFFF',
  }),
  singleValue: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  input: base => ({
    ...base,
    margin: '2px 0',
  }),
};

const Dropdown = ({ creatable, ...other }) =>
  creatable ? (
    <CreatableSelect styles={customStyles} {...other} />
  ) : (
    <Select styles={customStyles} {...other} />
  );

Dropdown.propTypes = {
  creatable: PropTypes.bool,
};

Dropdown.defaultProps = {
  creatable: false,
};

export default Dropdown;
