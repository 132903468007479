import { fork, take, race } from 'redux-saga/effects';
import { delay } from 'redux-saga';

export default (ms, pattern, worker, ...args) =>
  fork(function*() {
    while (true) {
      let action = yield take(pattern);

      while (true) {
        const { debounced, _action } = yield race({
          debounced: delay(ms),
          _action: take(pattern),
        });

        if (debounced) {
          yield fork(worker, ...args, action);
          break;
        }

        action = _action;
      }
    }
  });
