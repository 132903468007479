import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';

const GridColumn = ({ classes, children, spacing, className, ...other }) => (
  <Grid
    item
    container
    direction="column"
    className={classNames(
      classes.container,
      classes[`container${spacing}`],
      className
    )}
    {...other}
  >
    {children}
  </Grid>
);

const styles = {
  container8: {
    '& > div': {
      padding: '4px 0',
    },
  },
  container16: {
    '& > div': {
      padding: '8px 0',
    },
  },
  container24: {
    '& > div': {
      padding: '12px 0',
    },
  },
  container32: {
    '& > div': {
      padding: '16px 0',
    },
  },
  container40: {
    '& > div': {
      padding: '20px 0',
    },
  },
  container: {
    '& > div': {
      '&:last-child': {
        paddingBottom: 0,
      },
      '&:first-child': {
        paddingTop: 0,
      },
    },
  },
};

GridColumn.propTypes = {
  className: PropTypes.string,
  spacing: PropTypes.number,
};

GridColumn.defaultProps = {
  className: '',
  spacing: 24,
};

export default withStyles(styles, { name: 'GridColumn' })(GridColumn);
