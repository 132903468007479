import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';

import CudaMenu from '../CudaMenu';
import TextField from '../TextField';

import { ALLOW, BLOCK } from '../../actions/basic_filtering';

class ExceptionAddBar extends Component {
  state = {
    selectedAction: 0,
    domain: '',
  };

  onChangeDomain = event => {
    this.setState({
      domain: event.target.value,
    });
  };

  onChangeAction = selectedAction => {
    this.setState({
      selectedAction,
    });
  };

  addHandler = () => {
    const { domains } = this.props;

    const added = this.props.addHandler(
      {
        domain: this.state.domain,
        action: this.options[this.state.selectedAction].key,
      },
      domains
    );

    if (added) {
      this.setState({
        domain: '',
      });
    }
  };

  options = [
    {
      key: ALLOW,
      label: I18n.t('components.basicFiltering.allowTraffic'),
      icon: (
        <DoneIcon
          style={{
            height: '24px',
            width: '24px',
            marginRight: '4px',
            float: 'left',
          }}
        />
      ),
    },
    {
      key: BLOCK,
      label: I18n.t('components.basicFiltering.blockTraffic'),
      icon: (
        <BlockIcon
          style={{
            height: '24px',
            width: '24px',
            marginRight: '4px',
            float: 'left',
          }}
        />
      ),
    },
  ];

  render() {
    const { classes, error } = this.props;
    const { selectedAction, domain } = this.state;
    const { options } = this;

    return (
      <Grid container spacing={16}>
        <Grid data-test-id="exceptionActionWrapper" item xs={3}>
          <CudaMenu
            options={options}
            selectedIndex={selectedAction}
            onChange={this.onChangeAction}
          />
        </Grid>
        <Grid item xs={7} data-test-id="domainInputWrapper">
          <TextField
            data-test-id="exceptionDomainInput"
            autoFocus
            disableUnderline
            value={domain}
            onChange={this.onChangeDomain}
            placeholder={I18n.t(
              'components.basicFiltering.addDomainPlaceholder'
            )}
          />
          {error ? (
            <div
              data-test-id="exceptionDomainInputError"
              className={classes.error}
            >
              {I18n.t(error)}
            </div>
          ) : null}
        </Grid>
        <Grid item xs={2}>
          <Button
            id="excpetion-domain-add-btn"
            variant="contained"
            color="secondary"
            className={classes.addButton}
            onClick={this.addHandler}
            disabled={!domain}
          >
            {I18n.t('components.basicFiltering.addDomain')}
          </Button>
        </Grid>
      </Grid>
    );
  }
}
ExceptionAddBar.propTypes = {
  classes: PropTypes.object.isRequired,
  addHandler: PropTypes.func.isRequired,
  error: PropTypes.string,
  domains: PropTypes.array.isRequired,
};

const styles = {
  addButton: {
    '&:disabled': {
      opacity: '0.5',
    },
    lineHeight: '18px',
    width: '100%',
    margin: 0,
    fontWeight: 400,
    padding: '7px 0',
  },
  error: {
    color: '#DD1E31',
    fontSize: '12px',
  },
};

export default withStyles(styles, { name: 'ExceptionAddBar' })(ExceptionAddBar);
