import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { CudaDialogTitle } from '../CudaDialog';
import CudaMenu from '../CudaMenu';

const ReportModal = ({
  classes,
  title,
  onClose,
  reportOptions,
  timeOptions,
  frequencyOptions,
  reportIndex,
  selectReport,
  timeframeIndex,
  selectTimeframe,
  frequencyIndex,
  selectFrequency,
  recipients,
  onChangeRecipients,
  hourOptions,
  hourIndex,
  selectHour,
  weekOptions,
  weekIndex,
  selectWeek,
  dayOptions,
  dayIndex,
  selectDay,
  onSave,
  timezone,
  ...other
}) => {
  let hourList = [];
  hourOptions.forEach(hour => {
    hourList.push({
      key: hour['key'],
      label: hour['label'] + ' (' + timezone + ')',
    });
  });

  return (
    <Dialog {...other} fullWidth={true} maxWidth="md">
      <CudaDialogTitle onRequestClose={onClose}>{title}</CudaDialogTitle>
      <DialogContent>
        <Grid container spacing={24} className={classes.container}>
          <Grid item xs={3}>
            <Translate value="components.reporting.reportType" />
          </Grid>
          <Grid item xs={9}>
            <CudaMenu
              options={reportOptions}
              onChange={index => {
                selectReport(index, reportOptions);
              }}
              selectedIndex={reportIndex}
            />
          </Grid>
          <Grid item xs={3}>
            <Translate value="components.reporting.timeFrame" />
          </Grid>
          <Grid item xs={9}>
            <CudaMenu
              options={timeOptions}
              onChange={selectTimeframe}
              selectedIndex={timeframeIndex}
            />
          </Grid>
          <Grid item xs={3}>
            <Translate value="components.reporting.frequency" />
          </Grid>
          <Grid item xs={9} className={classes.frequencyWrapper}>
            <CudaMenu
              options={frequencyOptions}
              onChange={selectFrequency}
              customClasses={[classes.flexMenu]}
              selectedIndex={frequencyIndex}
            />
            {frequencyOptions[frequencyIndex]['key'] === 'daily' ? (
              <CudaMenu
                options={hourList}
                onChange={selectHour}
                customClasses={[classes.flexMenu]}
                selectedIndex={hourIndex}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'weekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'biweekly' ? (
              <CudaMenu
                options={weekOptions}
                onChange={selectWeek}
                customClasses={[classes.flexMenu]}
                selectedIndex={weekIndex}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'monthly' ? (
              <CudaMenu
                options={dayOptions}
                onChange={selectDay}
                customClasses={[classes.flexMenu]}
                selectedIndex={dayIndex}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'weekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'biweekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'monthly' ? (
              <CudaMenu
                options={hourList}
                onChange={selectHour}
                customClasses={[classes.flexMenu]}
                selectedIndex={hourIndex}
              />
            ) : null}
          </Grid>
          <Grid item xs={3}>
            <Translate value="components.reporting.recipients" />
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth={true}
              multiline
              rows="4"
              placeholder={I18n.t('components.notifications.email_placeholder')}
              onChange={event => {
                onChangeRecipients(event.target.value);
              }}
              value={recipients}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            color="secondary"
            variant="contained"
            onClick={onClose}
            className={classes.reportBtn}
            disabled={false}
          >
            <Translate value="shared.cancel" />
          </Button>
          {recipients === '' ? (
            <Tooltip title={I18n.t('components.reporting.emailTooltip')}>
              <span>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    onSave();
                    onClose();
                  }}
                  className={classes.reportBtn}
                  disabled={recipients === ''}
                >
                  <Translate value="shared.schedule" />
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                onSave();
                onClose();
              }}
              className={classes.reportBtn}
              disabled={recipients === ''}
            >
              <Translate value="shared.schedule" />
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  container: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    width: '100%',
    margin: '0',
  },
  reportBtn: {
    height: '32px',
    lineHeight: '1px',
    margin: '0 16px 0 0',
    padding: '8px 21px',
    fontWeight: '200',
  },
  flexMenu: {
    flexGrow: 1,
    display: 'inline-flex',
    '& > ul': {
      width: '100%',
    },
  },
  frequencyWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& >div': {
      margin: '0 8px',
    },
    '& >div:first-child': {
      marginLeft: '0',
    },
    '& >div:last-child': {
      marginRight: '0',
    },
  },
});

ReportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  reportOptions: PropTypes.array.isRequired,
  timeOptions: PropTypes.array.isRequired,
  frequencyOptions: PropTypes.array.isRequired,
  reportIndex: PropTypes.number.isRequired,
  selectReport: PropTypes.func.isRequired,
  timeframeIndex: PropTypes.number.isRequired,
  selectTimeframe: PropTypes.func.isRequired,
  frequencyIndex: PropTypes.number.isRequired,
  selectFrequency: PropTypes.func.isRequired,
  recipients: PropTypes.string.isRequired,
  onChangeRecipients: PropTypes.func.isRequired,
  hourOptions: PropTypes.array.isRequired,
  selectHour: PropTypes.func.isRequired,
  hourIndex: PropTypes.number.isRequired,
  weekOptions: PropTypes.array.isRequired,
  selectWeek: PropTypes.func.isRequired,
  weekIndex: PropTypes.number.isRequired,
  dayOptions: PropTypes.array.isRequired,
  selectDay: PropTypes.func.isRequired,
  dayIndex: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withStyles(styles)(ReportModal);
