import { connect } from 'react-redux';
import ExceptionAddBar from '../../components/basic_filtering/ExceptionAddBar';
import {
  updateExceptions,
  domainError,
  badValueDomainError,
} from '../../actions/basic_filtering';

const mapStateToProps = (state, ownProps) => ({
  error: state.basic_filtering.exceptionConfig.error,
  domains: state.basic_filtering.exceptionConfig.domains,
});

const mapDispatchToProps = dispatch => ({
  addHandler: (domain, domains) => {
    const domainRegex = /^[a-zA-Z0-9\-\.]+$/i;
    if (!domainRegex.test(domain.domain)) {
      dispatch(badValueDomainError());
      return false;
    }
    if (domains.find(d => d.domain === domain.domain) === undefined) {
      dispatch(updateExceptions([domain], []));
      return true;
    }
    dispatch(domainError());
    return false;
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExceptionAddBar);
