import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LicenseAgreement from '../../components/signup/LicenseAgreement';
import { activateTrial, setLogin, clearSession } from '../../actions/signup';
import { deleteSession } from '../../actions/account';
import Auth from '../../lib/Auth';

const mapStateToProps = state => ({
  processing: state.signup.license.submitting,
  error: state.signup.license.error,
});

const mapDispatchToProps = dispatch => ({
  handleAgree: history => {
    dispatch(activateTrial(history));
  },
  clearInfo: () => {
    Auth.logout();
    dispatch(deleteSession());
    dispatch(setLogin(false));
    dispatch(clearSession());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LicenseAgreement));
