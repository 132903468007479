import { connect } from 'react-redux';
import DeployAgent from '../components/DeployAgent';

import {
  downloadAccountConfig,
  getInstallers,
  closeReleaseNotes,
} from '../actions/account';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  loading: state.account.loading,
  downloading: state.account.downloadingAgentConfig,
  releaseNotes: state.account.releaseNotes,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  downloadAccountConfig: () => {
    dispatch(downloadAccountConfig());
  },
  closeReleaseNotes: () => {
    dispatch(closeReleaseNotes());
  },
  initData: () => {
    dispatch(getInstallers());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployAgent);
