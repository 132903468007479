import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import DocumentTitle from 'react-document-title';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import SectionHeader from './SectionHeader';
import FilterSearchBar from './FilterSearchBar';
import { WebLogIcon } from './icons';
import TimeSelect from '../containers/wf_logs/TimeSelect';

import WFLogTable from '../containers/wf_logs/WFLogTable';

class WebFilteringLogs extends Component {
  state = {};

  componentDidMount = () => {
    this.props.initLogs();
  };

  timeSelectHandler = event => {
    this.props.updateRange(event);
  };

  render() {
    const {
      classes,
      query,
      updateQuery,
      queryData,
      queryFields,
      queryField,
      updateField,
      queryFieldValues,
    } = this.props;

    const fieldValues = queryFieldValues[queryFields[queryField].field];
    return (
      <DocumentTitle title={I18n.t('components.wfLogs.pageTitle')}>
        <div>
          <SectionHeader
            icon={<WebLogIcon />}
            title={I18n.t('components.wfLogs.title')}
          />
          <Grid spacing={24} container>
            <Grid item xs={12} md={7} lg={6}>
              <FilterSearchBar
                data-test-id="wfLogsSearch"
                height="40px"
                autoFocus={false}
                disabled={false}
                fields={queryFields}
                field={queryField}
                fieldValues={fieldValues}
                handleMenuItemClick={updateField}
                value={query}
                onChange={value => {
                  updateQuery(value);
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={6}
              className={classes.timeOptionsWrapper}
            >
              <Button
                data-test-id="search-btn"
                onClick={queryData}
                color="secondary"
                className={classes.queryButton}
                variant="contained"
              >
                {I18n.t('components.wfLogs.search')}
              </Button>
              <TimeSelect onChange={this.timeSelectHandler} />
            </Grid>
            <Grid item xs={12}>
              <WFLogTable />
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

WebFilteringLogs.propTypes = {
  initLogs: PropTypes.func.isRequired,
  updateRange: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  // query: PropTypes.string.isRequired,
};

const styles = {
  timeOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  queryButton: {
    margin: 0,
    height: '40px',
    minWidth: '85px',
  },
};

export default withStyles(styles, { name: 'WebFilteringLogs' })(
  WebFilteringLogs
);
