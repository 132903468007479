import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Authenticator from '../Authenticator';
import {
  updateSubscriptions,
  storeSubscriptions,
  retrieveAccount,
  storeUserName,
  storeSerials,
} from '../actions/account';
import { displayError } from '../actions/login';

import { storeDecodedSSOToken } from '../actions/activate';
import { showSessionModal } from '../actions/account';

const mapDispatchToProps = dispatch => ({
  updateSubscriptions: subscriptions => {
    dispatch(updateSubscriptions(subscriptions));
  },
  storeSubscriptions: (subs, accountSubs) => {
    dispatch(storeSubscriptions(subs, accountSubs));
  },
  storeSerials: serials => {
    dispatch(storeSerials(serials));
  },
  retrieveAccount: (email, account, accounts, services, user_id, time_zone) => {
    dispatch(
      retrieveAccount(email, account, accounts, services, user_id, time_zone)
    );
  },
  storeUserName: userName => {
    dispatch(storeUserName(userName));
  },
  storeDecodedSSOToken: dtoken => {
    dispatch(storeDecodedSSOToken(dtoken));
  },
  errorMessage: (code, message) => {
    dispatch(displayError(code, message));
  },
  showSessionModal: () => {
    dispatch(showSessionModal());
  },
});

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps
  )(Authenticator)
);
