import { connect } from 'react-redux';
import { I18n } from 'react-i18nify';
import SelectionInput from '../../components/advanced_filtering/SelectionInput';

import { TYPE_OPTIONS, selectType } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({
  name: I18n.t('components.advancedFiltering.type'),
  options: TYPE_OPTIONS,
  selectedIndex: state.advanced_filtering.ruleModal.selectedTypeIndex,
  helpText: I18n.t('components.advancedFiltering.typeHelpText'),
  tooltipTestId: 'type-selection',
});

const mapDispatchToProps = dispatch => ({
  onChange: index => {
    dispatch(selectType(index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionInput);
