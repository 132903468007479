import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import CheckedCategory from './CheckedCategory';
import PolicyDropdown from './PolicyDropdown';

class CategoryPolicyComponent extends Component {
  onChange = (selected, cats) => {
    this.props.setCategories(selected, cats);
  };

  onSelect = (added, removed) => {
    const { editSelected } = this.props;

    this.props.updateCategories(editSelected, added, removed);
  };

  render() {
    const {
      classes,
      superCategories,
      selectedCategories,
      levels,
      customPolicies,
      selected,
    } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          className={classes.levelContainer}
          data-test-id="categoriesBoxWrapper"
        >
          <PolicyDropdown
            levels={levels}
            label="categoryPolicy"
            custom={customPolicies}
            customPolicyKey="categories"
            selected={selected}
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.catContainer}>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              {superCategories.slice(0, 4).map(n => (
                <CheckedCategory
                  className={classes.checkedCategory}
                  key={n.superCategory.value}
                  topLevel={n.superCategory}
                  categories={n.categories}
                  selectedCategories={selectedCategories}
                  changeSelection={this.onSelect}
                />
              ))}
            </Grid>
            <Grid item xs={4}>
              {superCategories.slice(4, 8).map(n => (
                <CheckedCategory
                  className={classes.checkedCategory}
                  key={n.superCategory.value}
                  topLevel={n.superCategory}
                  categories={n.categories}
                  selectedCategories={selectedCategories}
                  changeSelection={this.onSelect}
                />
              ))}
            </Grid>
            <Grid item xs={4}>
              {superCategories.slice(8).map(n => (
                <CheckedCategory
                  className={classes.checkedCategory}
                  key={n.superCategory.value}
                  topLevel={n.superCategory}
                  categories={n.categories}
                  selectedCategories={selectedCategories}
                  changeSelection={this.onSelect}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CategoryPolicyComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.string,
  editSelected: PropTypes.string,
  superCategories: PropTypes.arrayOf(
    PropTypes.shape({
      superCategpry: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  customPolicies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.number),
      domains: PropTypes.arrayOf(
        PropTypes.shape({
          domain: PropTypes.string,
          action: PropTypes.string,
        })
      ),
      id: PropTypes.string,
    })
  ).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.number),
  setCategories: PropTypes.func.isRequired,
  updateCategories: PropTypes.func.isRequired,
};
CategoryPolicyComponent.defaultProps = {
  selected: 'medium',
  editSelected: 'custom',
  selectedCategories: [],
};

const styles = {
  checkedCategory: {
    marginBottom: '24px',
  },
  catContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'NoResultsStyles' })(
  CategoryPolicyComponent
);
