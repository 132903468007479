export const JWE_SESSION_STORE = 'Endpoint_shield_session';
export const SSO_SESSION_STORE = 'BNES_appsec_session';
export const SSO_REFRESH_TOKEN = 'BNES_appsec_refresh';
export const SSO_BCC_TOKEN = 'CLOUD_AT';
export const SSO_SUPPORT_INFO = 'BCS_support_info';
export let PRIMARY_DNS = '35.174.51.19';
export let SECONDARY_DNS = '35.174.51.19';
let ENV_STAGE = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE
  : process.env.PUBLIC_URL;
// Get the stage at the end of the url
if (ENV_STAGE) {
  let subpath = ENV_STAGE.split('/');
  ENV_STAGE = subpath[subpath.length - 1];
}
let DDNS_DOWNLOAD =
  'https://s3.amazonaws.com/bcs-files-dev/ddns/Dynamic%20IP%20Updater.msi';
let AGENT_DOWNLOAD =
  'https://s3.amazonaws.com/bcs-files-dev/ddns/Dynamic%20IP%20Updater.msi';
let LOGO_PATH = '/default-logo.svg';

if (process.env.REACT_APP_ENV === 'qa') {
  DDNS_DOWNLOAD =
    'https://s3.amazonaws.com/bcs-files-qa/ddns/Dynamic%20IP%20Updater.msi';
  PRIMARY_DNS = '34.233.212.145';
  SECONDARY_DNS = '34.229.35.195';
} else if (process.env.REACT_APP_ENV === 'staging') {
  DDNS_DOWNLOAD =
    'https://s3.amazonaws.com/bcs-files-staging/ddns/Dynamic%20IP%20Updater.msi';
  PRIMARY_DNS = '18.210.81.209';
  SECONDARY_DNS = '18.210.126.49';
} else if (
  process.env.REACT_APP_ENV === 'production' ||
  (ENV_STAGE === '' && process.env.NODE_ENV === 'production')
) {
  DDNS_DOWNLOAD =
    'https://s3.amazonaws.com/bcs-files-production/ddns/Dynamic%20IP%20Updater.msi';
  PRIMARY_DNS = '34.203.72.42';
  SECONDARY_DNS = '34.203.170.29';
}
export const STAGE =
  ENV_STAGE || (ENV_STAGE === '' && process.env.NODE_ENV === 'production')
    ? ENV_STAGE
    : 'v1';
const API_ENDPOINT =
  process.env.REACT_APP_DOMAIN && process.env.REACT_APP_DOMAIN !== ''
    ? process.env.REACT_APP_DOMAIN
    : 'https://api.dev.bcs.aws.cudaops.com';
export const BASE_URL = STAGE
  ? API_ENDPOINT + '/portal/' + STAGE
  : API_ENDPOINT + '/portal';
export const DOWNLOAD_URL = STAGE
  ? API_ENDPOINT + '/download/' + STAGE
  : API_ENDPOINT + '/download';
export const TOKEN_VALIDATOR_URL = BASE_URL + '/token_validator';
export const COOKIE_VALIDATOR_URL = BASE_URL + '/cookie_validator';
export const CSV_URL = DOWNLOAD_URL + '/csv';
export const PDF_URL = DOWNLOAD_URL + '/pdf';
export const REPORT_PDF_URL = DOWNLOAD_URL + '/pdf_report';
export const LOGIN_URL = BASE_URL + '/login';
export const TOKEN_URL = BASE_URL + '/token';
export const ACCOUNT = 'EPS_account';
export const ACCOUNT_NAME = 'EPS_account_name';
export const EPS_AUTHDB_SERVICE_ID = 17;
export { DDNS_DOWNLOAD };
export { AGENT_DOWNLOAD };
export { LOGO_PATH };

export const AD_CAMPUS_URL =
  'https://campus.barracuda.com/product/ContentShield/doc/79464883/ldap-active-directory-and-barracuda-content-shield/';
