import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import EditModal from '../modal/EditModal';
import DynamicIPUpdater from '../../containers/basic_filtering/DynamicIPUpdater';

const DynameicIPModal = ({ title, showModal, closeDialog }) => (
  <EditModal
    title={title}
    showModal={showModal}
    closeBtnText={I18n.t('shared.close')}
    closeDialog={closeDialog}
  >
    <DynamicIPUpdater />
  </EditModal>
);

DynameicIPModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default DynameicIPModal;
