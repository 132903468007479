import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const ActionButton = ({
  classes,
  children,
  height,
  inProgress,
  inProgressText,
  variant,
  color,
  fullWidth,
  ...other
}) => (
  <div
    style={{ height }}
    className={classNames(classes.container, {
      [classes.fullWidth]: fullWidth,
    })}
  >
    {inProgress ? (
      <React.Fragment>
        <CircularProgress className={classes.progress} size={24} />
        <span className={classes.progressText}>{inProgressText}</span>
      </React.Fragment>
    ) : (
      <Button variant={variant} color={color} {...other}>
        {children}
      </Button>
    )}
  </div>
);

const styles = {
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  progressText: {
    lineHeight: '24px',
    textTransform: 'uppercase',
    fontSize: '14px',
    marginLeft: '10px',
  },
};

ActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  inProgress: PropTypes.bool.isRequired,
  inProgressText: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.string,
  fullWidth: PropTypes.bool,
};

ActionButton.defaultProps = {
  variant: 'raised',
  color: 'primary',
  height: '50px',
  className: '',
  fullWidth: true,
};

export default withStyles(styles, { name: 'ActionButton' })(ActionButton);
