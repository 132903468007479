import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import CheckedCategory from './CheckedCategory';

class CategoryPolicyComponent extends Component {
  onSelect = (added, removed) => {
    const { editSelected } = this.props;

    this.props.updateCategories(editSelected, added, removed);
  };

  render() {
    const { classes, superCategories, selectedCategories, error } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.error}>
          {error ? error : null}
        </Grid>
        <Grid item xs={12} className={classes.catContainer}>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              {superCategories.slice(0, 4).map(n => (
                <CheckedCategory
                  className={classes.checkedCategory}
                  key={n.superCategory.value}
                  topLevel={n.superCategory}
                  categories={n.categories}
                  selectedCategories={selectedCategories}
                  changeSelection={this.onSelect}
                />
              ))}
            </Grid>
            <Grid item xs={4}>
              {superCategories.slice(4, 8).map(n => (
                <CheckedCategory
                  className={classes.checkedCategory}
                  key={n.superCategory.value}
                  topLevel={n.superCategory}
                  categories={n.categories}
                  selectedCategories={selectedCategories}
                  changeSelection={this.onSelect}
                />
              ))}
            </Grid>
            <Grid item xs={4}>
              {superCategories.slice(8).map(n => (
                <CheckedCategory
                  className={classes.checkedCategory}
                  key={n.superCategory.value}
                  topLevel={n.superCategory}
                  categories={n.categories}
                  selectedCategories={selectedCategories}
                  changeSelection={this.onSelect}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CategoryPolicyComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  superCategories: PropTypes.arrayOf(
    PropTypes.shape({
      superCategpry: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.number),
  updateCategories: PropTypes.func.isRequired,
  error: PropTypes.string,
};
CategoryPolicyComponent.defaultProps = {
  selectedCategories: [],
};

const styles = {
  error: {
    color: '#DD1E31',
    fontSize: '13px',
    textAlign: 'center',
  },
  checkedCategory: {
    marginBottom: '24px',
  },
  catContainer: {},
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'NoResultsStyles' })(
  CategoryPolicyComponent
);
