import { connect } from 'react-redux';
import Header from '../../components/reports/Header';

const mapStateToProps = (state, ownProps) => {
  const accounts = state.account.accounts;
  const selected = state.account.selected;

  let name = '';
  for (var i = 0; i < accounts.length; i++) {
    if (accounts[i].id === selected) {
      name = accounts[i].name;
      break;
    }
  }

  return {
    title: state.reports.genReport,
    subTitle: state.reports.timeLabel,
    accountName: name,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
