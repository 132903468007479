import { createSelector } from 'reselect';
import { I18n } from 'react-i18nify';

import { getCategoryList } from './categories';
import { getUserList, getGroupList } from './account';

const getCategories = state => state.basic_filtering.catMapping.categories;
const getSupercategories = state =>
  state.basic_filtering.catMapping.supercategories;
const getRulesets = state => state.advanced_filtering.ruleset;
const getSearchMatch = state => state.advanced_filtering.search.match;

const _checkMatch = (uuids, queries) => {
  return uuids.some(uuid => queries.includes(uuid));
};

const _checkDomainMatch = (domains, domain) => {
  const regex = new RegExp(domain, 'ig');

  return domains.some(d => {
    regex.lastIndex = 0; // Reset search index to beginning
    return regex.exec(d);
  });
};

const _checkRulesetDomainMatch = (ruleset, domain) => {
  if (!ruleset.rules) {
    return false;
  }

  if (ruleset.rules.length === 0) {
    return false;
  }

  let rules = ruleset.rules;
  for (let i = 0; i < rules.length; i++) {
    if (
      rules[i]['domains'] &&
      rules[i]['domains'].length > 0 &&
      _checkDomainMatch(rules[i]['domains'], domain)
    ) {
      return true;
    }
  }
};

const _checkCategoryMatch = (categories, category) => {
  return categories.some(cat => cat === category);
};

const _checkRulesetCategoryMatch = (ruleset, category) => {
  if (!ruleset.rules) {
    return false;
  }

  if (ruleset.rules.length === 0) {
    return false;
  }

  let rules = ruleset.rules;
  for (let i = 0; i < rules.length; i++) {
    if (
      rules[i]['categories'] &&
      rules[i]['categories'].length > 0 &&
      _checkCategoryMatch(rules[i]['categories'], category)
    ) {
      return true;
    }
  }

  return false;
};

const searchTypes = {
  users: true,
  groups: true,
  categories: true,
  domains: true,
};

const getFilteredRuleset = createSelector(
  [getRulesets, getSearchMatch],
  (ruleset, search) => {
    const tmpRuleset = ruleset;
    if (!searchTypes[search.type]) {
      return ruleset;
    }

    const filterRules = tmpRuleset.rules.filter(rule => {
      if (search.type === 'categories') {
        return _checkRulesetCategoryMatch(
          rule.ruleset_jump,
          search.query.value
        );
      } else if (search.type === 'domains') {
        return _checkRulesetDomainMatch(rule.ruleset_jump, search.query.value);
      }

      // Did not match any type return all
      return true;
    });

    return {
      ...ruleset,
      rules: filterRules,
    };
  }
);

const getCategoryNames = createSelector(
  [getCategories, getSupercategories],
  (categories, supercategories) => {
    const catNames = {
      0: I18n.t('components.notifications.anyCategory'),
    };

    Object.keys(categories).forEach(key => {
      catNames[parseInt(key, 10)] = categories[key].display;
    });
    Object.keys(supercategories).forEach(superCat => {
      catNames[parseInt(superCat, 10)] = supercategories[superCat];
    });

    return catNames;
  }
);

const getQueryFieldValues = createSelector(
  [getCategoryList, getUserList, getGroupList],
  (categories, users, groups) => ({
    categories,
    users,
    groups,
  })
);

export { getCategoryNames, getQueryFieldValues, getFilteredRuleset };
