import { connect } from 'react-redux';
import LocalUsers from '../../components/user_authentication/LocalUsers';
import {
  openModal,
  closeModal,
  addUsers,
  updateUser,
  updateGroup,
  moveUser,
  removeFromGroup,
  deleteGroup,
} from '../../actions/user_authentication';

import { modalChanged } from '../../selectors/user_authentication';

const mapStateToProps = state => ({
  modal: {
    show: state.userAuthentication.modal.show,
    name: state.userAuthentication.modal.name,
    changed: modalChanged(state),
    processing: state.userAuthentication.modal.processing,
    error: state.userAuthentication.modal.error,
  },
});

const mapDispatchToProps = dispatch => ({
  showAddUsers: () => {
    dispatch(
      openModal('addLocalUsers', {
        users: '',
        group: {},
      })
    );
  },
  addUsers: () => dispatch(addUsers()),
  updateUser: () => dispatch(updateUser()),
  updateGroup: () => dispatch(updateGroup()),
  moveUser: () => dispatch(moveUser()),
  removeFromGroup: () => dispatch(removeFromGroup()),
  deleteGroup: () => dispatch(deleteGroup()),
  closeModal: () => dispatch(closeModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalUsers);
