import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Translate } from 'react-i18nify';
import { DragSource, DropTarget } from 'react-dnd';

import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { DragVerticalIcon } from '../icons';

import RulesetName from './RulesetName';
import RuleTable from './RuleTable';

import ExpandableRow from '../expandable_table/ExpandableRow';

const styles = theme => ({
  toggleIcon: {
    color: theme.colors.blueAction,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer',
    },
  },
  wrapper: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    color: 'rgba(0, 0, 0, 0.50)',
    fontSize: '12px',
    fontWeight: 500,
    '& > div': {
      padding: '10px 4px',
      '&:last-child': {
        paddingRight: 0,
        textAlign: 'center',
      },
    },
  },
  smallDiv: {
    flex: '0 0 50px',
  },
  bigDiv: {
    flex: '1 1',
  },
  expanded: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  menuOptions: {
    color: '#5F5F5F',
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  dragHandle: {
    '& > svg': {
      height: '32px',
    },
  },
  deleteOption: {
    color: theme.statusColors.failure,
  },
  disabledHandle: {
    pointerEvents: 'none',
  },
});

class RulesetRow extends React.Component {
  constructor(props) {
    super(props);
    this.toggleExpand = this.toggleExpand.bind(this);
    this.moveToTop = this.moveToTop.bind(this);
    this.moveToBottom = this.moveToBottom.bind(this);
  }

  state = {
    expanded: true,
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  toggleExpand() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  moveToTop() {
    this.props.submitOrder(
      this.props.rule.id,
      this.props.arrIndex,
      0,
      this.props.rule.ruleset_id
    );
  }

  moveToBottom() {
    this.props.submitOrder(
      this.props.rule.id,
      this.props.arrIndex,
      this.props.totalCount,
      this.props.rule.ruleset_id
    );
  }

  render() {
    const {
      classes,
      rule,
      name,
      type,
      arrIndex,
      processing,
      highlight,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      isDragging,
      users,
      groups,
      openAddRule,
      openEditRuleset,
      adMap,
      ruleId,
      deleteRulesetRule,
    } = this.props;
    const { anchorEl } = this.state;
    const rules = rule.ruleset_jump ? rule.ruleset_jump.rules : [];
    const everyone = type === 'everyone';

    // Users and Groups get adMap to populate.
    let selectedAD = [];
    if (users.length > 0) {
      selectedAD = users.map(id =>
        adMap[id]
          ? {
              type: adMap[id]['type'],
              label: adMap[id]['display_name'],
              value: adMap[id]['uuid'],
            }
          : {
              type: 'user',
              label: <span style={{ color: '#DD1E31' }}>No longer exists</span>,
              value: id,
            }
      );
    } else if (groups.length > 0) {
      selectedAD = groups.map(id =>
        adMap[id]
          ? {
              type: adMap[id]['type'],
              label: adMap[id]['group_name'],
              value: adMap[id]['uuid'],
            }
          : {
              type: 'group',
              label: <span style={{ color: '#DD1E31' }}>No longer exists</span>,
              value: id,
            }
      );
    } else {
      selectedAD = [{ type: 'everyone', label: 'Everyone', value: 'everyone' }];
    }

    const template = (
      <div
        ref={elm => {
          this.rowset = elm;
        }}
        style={{ opacity: isDragging ? 0 : 1 }}
      >
        <ExpandableRow
          data-test-id={`expand-row-${arrIndex}`}
          icons={
            everyone ? (
              <span style={{ width: '20px', height: '32px' }} />
            ) : (
              connectDragSource(
                <div
                  data-test-id="drag-handle"
                  className={classNames(classes.dragHandle, {
                    [classes.disabledHandle]: processing,
                  })}
                >
                  <DragVerticalIcon />
                </div>
              )
            )
          }
          header={
            <div>
              <div className={classes.bigDiv}>
                <RulesetName
                  data-test-id="ruleset-name"
                  type={type}
                  name={name}
                  count={rules.length}
                  highlight={highlight}
                />
              </div>
              <div className={classes.smallDiv}>
                <IconButton
                  data-test-id="more-icon"
                  aria-label="More"
                  aria-owns={null}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                  disabled={processing}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem
                    data-test-id="create-rule"
                    className={classes.menuOptions}
                    onClick={() => {
                      this.handleClose();
                      openAddRule(selectedAD);
                    }}
                  >
                    <EditIcon color="inherit" />
                    <Translate value="components.advancedFiltering.createRule" />
                  </MenuItem>
                  <MenuItem
                    data-test-id="edit-rule"
                    className={classes.menuOptions}
                    onClick={() => {
                      this.handleClose();
                      openEditRuleset(selectedAD, rule);
                    }}
                  >
                    <EditIcon color="inherit" />
                    <Translate value="components.advancedFiltering.edit" />
                  </MenuItem>
                  {!everyone && (
                    <MenuItem
                      data-test-id="move-top"
                      className={classes.menuOptions}
                      onClick={() => {
                        this.moveToTop();
                        this.handleClose();
                      }}
                    >
                      <VerticalAlignTopIcon color="inherit" />
                      <Translate value="components.advancedFiltering.moveTop" />
                    </MenuItem>
                  )}
                  {!everyone && (
                    <MenuItem
                      data-test-id="move-bottom"
                      className={classes.menuOptions}
                      onClick={() => {
                        this.moveToBottom();
                        this.handleClose();
                      }}
                    >
                      <VerticalAlignBottomIcon color="inherit" />
                      <Translate value="components.advancedFiltering.moveBottom" />
                    </MenuItem>
                  )}
                  {!everyone && (
                    <MenuItem
                      data-test-id="delete-group"
                      className={classNames(
                        classes.menuOptions,
                        classes.deleteOption
                      )}
                      onClick={() => {
                        this.handleClose();
                        deleteRulesetRule(ruleId);
                      }}
                    >
                      <DeleteIcon color="inherit" />
                      <Translate value="components.advancedFiltering.delete" />
                    </MenuItem>
                  )}
                </Menu>
              </div>
            </div>
          }
          body={
            <RuleTable
              data-test-id={`rule-table-${arrIndex}`}
              index={arrIndex}
              rules={rules}
              users={users}
              groups={groups}
              everyone={everyone}
              id={rule.ruleset_jump.id}
            />
          }
        />
      </div>
    );

    return everyone
      ? template
      : connectDropTarget(connectDragPreview(template));
  }
}

RulesetRow.defaultProps = {
  highlight: false,
};

RulesetRow.propTypes = {
  classes: PropTypes.object.isRequired,
  ruleId: PropTypes.string.isRequired,
  highlight: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  openAddRule: PropTypes.func.isRequired,
  deleteRulesetRule: PropTypes.func.isRequired,
  adMap: PropTypes.object.isRequired,
};

const rulesetSource = {
  beginDrag(props) {
    return {
      id: props.rule.id,
      index: props.arrIndex,
      originalIndex: props.arrIndex,
    };
  },
  endDrag(props, monitor, component) {
    const item = monitor.getItem();
    const { id, originalIndex, index } = item;

    if (index !== originalIndex) {
      props.submitReorder(id, index, originalIndex, props.rule.ruleset_id);
    }
  },
};

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const rulesetTarget = {
  hover(props, monitor, component) {
    // eslint-disable-line
    if (!component) {
      return null;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.arrIndex;

    if (dragIndex === hoverIndex) {
      return null;
    }

    const hoverBoundingRect = component.rowset.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return null;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return null;
    }

    props.reorder(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex; // eslint-disable-line
  },
};

function targetCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const targeted = DropTarget('RULESET', rulesetTarget, targetCollect)(
  RulesetRow
);
const sourced = DragSource('RULESET', rulesetSource, sourceCollect)(targeted);
export default withStyles(styles, { name: 'RulesetRow' })(sourced);
