import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import CudaTooltip from '../CudaTooltip';
import Paper from '../CudaPaper';

const AgentsModule = ({ classes, total, active, loading }) => {
  return (
    <Paper>
      <Grid spacing={0} container>
        <Grid item xs={12}>
          <div className={classes.headerSection}>
            <div className={classes.title}>
              <Translate value="shared.agents" />
            </div>
          </div>
        </Grid>
        {loading ? (
          <Grid item xs={12}>
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          </Grid>
        ) : null}
        <Grid item xs={6}>
          {!loading ? (
            <div className={classes.infoWrapper}>
              <div className={classes.number}>
                <div data-tip data-for="agents-id">
                  {total}
                </div>
                <CudaTooltip id="agents-id">
                  <span>
                    {' '}
                    {I18n.t('components.dashboard.agentsDeployTooltip')}{' '}
                  </span>
                </CudaTooltip>
              </div>
              <div className={classes.text}>
                <Translate value="components.dashboard.agentsDeployed" />
              </div>
            </div>
          ) : null}
        </Grid>
        {!loading ? (
          <Grid item xs={6}>
            <div className={classes.infoWrapper}>
              <div className={classes.number}>
                <div data-tip data-for="agents-active-id">
                  {total - active}
                </div>
                <CudaTooltip id="agents-active-id">
                  <span>
                    {' '}
                    {I18n.t('components.dashboard.agentsInactivetip')}{' '}
                  </span>
                </CudaTooltip>
              </div>
              <div className={classes.text}>
                <Translate value="shared.inactive" />
              </div>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

const styles = theme => ({
  infoWrapper: {
    textAlign: 'center',
    margin: '25px',
  },
  headerSection: {
    margin: '20px 20px 5px 20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  number: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  text: {
    marginTop: '10px',
    fontSize: '12px',
    lineHeigh: '24px',
    color: 'rgb(0,0,0,0.5)',
    textTransform: 'uppercase',
  },
  item: {
    marginTop: '5px',
  },
  marginBottom: {
    marginBottom: '20px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '62px',
  },
});

AgentsModule.propTypes = {
  total: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(AgentsModule);
