import { connect } from 'react-redux';
import ExpandableRow from '../../components/wf_logs/ExpandableRow';
import { getADUserHash } from '../../selectors/account';

const mapStateToProps = state => ({
  cats: state.basic_filtering.catMapping.categories,
  supercats: state.basic_filtering.catMapping.supercategories,
  locations: state.wfLogs.locations,
  users: getADUserHash(state),
});

export default connect(
  mapStateToProps,
  undefined
)(ExpandableRow);
