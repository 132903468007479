import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Area from '../charts/Area';
import TimeSelect from './TimeSelection';
import Paper from '../CudaPaper';

const helpText = [
  I18n.t('shared.threats'),
  I18n.t('shared.users'),
  I18n.t('shared.devices'),
];

const ThreatDetectedChart = ({
  classes,
  options,
  data,
  selectedThreats,
  onThreatChange,
  selectedThreatType,
  onThreatTypeChange,
  loading,
  range,
}) => {
  return (
    <Paper>
      <Grid spacing={0} container>
        <Grid item xs={5}>
          <div className={classes.headerSection}>
            <div className={classes.title}>Threats Detected</div>
            <div className={classes.subtitle}>
              {options[selectedThreats].label}
            </div>
          </div>
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12} className={classes.chart}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <Area
              data={data}
              height={257}
              graphType={range}
              toolTipName={helpText[selectedThreatType]}
              sparseTick={range === 'month' || range === 'day'}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.timeSelect}
          data-test-id="threatDetectedSelectionWrapper"
        >
          <TimeSelect
            options={options}
            selectedIndex={selectedThreats}
            onChange={onThreatChange}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = theme => ({
  container: {},
  headerSection: {
    margin: '20px 20px 0px 20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.5)',
  },
  buttonNavRoot: {
    height: '90px',
    justifyContent: 'flex-start',
  },
  root: {
    borderColor: '#FFFFFF',
    borderTop: '3px solid',
    '&:hover': {
      backgroundColor: '#E7E7E7',
      borderColor: '#E7E7E7',
    },
  },
  selected: {
    borderColor: '#0088CE',
    color: 'black',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '257px',
  },
  chart: {
    padding: '0px 0px 0px 24px',
  },
  timeSelect: {
    marginTop: '16px',
  },
});

ThreatDetectedChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_count: PropTypes.number,
      key_as_string: PropTypes.string,
    })
  ).isRequired,
  selectedThreatType: PropTypes.number.isRequired, // summary, user, device
  onThreatTypeChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectedThreats: PropTypes.number.isRequired,
  onThreatChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  range: PropTypes.string.isRequired,
};

export default withStyles(styles)(ThreatDetectedChart);
