export const INIT_DATA = 'INIT_GRAPH';
export const CHANGE_TIME = 'CHANGE_TIME';
export const GET_ATP_DATA_SUCCESS = 'GET_ATP_DATA_SUCCESS';
export const GET_ATP_DATA_FAILURE = 'GET_ATP_DATA_FAILURE';
export const SORT_LOGS = 'SORT_LOGS';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';

export const DASHBOARD_UPDATE_MODULE = 'DASHBOARD_UPDATE_MODULE';
export const DASHBOARD_UPDATE_MODULE_DONE = 'DASHBOARD_UPDATE_MODULE_DONE';

export const DASHBOARD_DOWNLOAD_CSV = 'DASHBOARD_DOWNLOAD_CSV';
export const DASHBOARD_DOWNLOAD_CSV_SUCCESS = 'DASHBOARD_DOWNLOAD_CSV_SUCCESS';
export const DASHBOARD_DOWNLOAD_CSV_FAILURE = 'DASHBOARD_DOWNLOAD_CSV_FAILURE';

export const downloadCsv = range => ({
  type: DASHBOARD_DOWNLOAD_CSV,
  range,
});

export const downloadCsvSuccess = () => ({
  type: DASHBOARD_DOWNLOAD_CSV_SUCCESS,
});

export const downloadCsvFailure = error => ({
  type: DASHBOARD_DOWNLOAD_CSV_FAILURE,
  error,
});

export const updateModule = (module, timeRange) => ({
  type: DASHBOARD_UPDATE_MODULE,
  timeRange,
  module,
});

export const updateModuleDone = module => ({
  type: DASHBOARD_UPDATE_MODULE_DONE,
  module,
});

export const initData = () => ({
  type: INIT_DATA,
});

export const dashboardLoading = () => ({
  type: DASHBOARD_LOADING,
});

export const changeTimeframe = (time, index) => ({
  type: CHANGE_TIME,
  time,
  index,
});

const formatBlockedCategories = (blockedCats, catsInfo, superCatsInfo) => {
  const cats = { ...catsInfo };
  const categories = Object.keys(cats);
  const supercats = Object.keys(superCatsInfo);
  for (let i = 0; i < supercats.length; i += 1) {
    cats[supercats[i]] = {
      display: superCatsInfo[supercats[i]],
    };
  }

  const addedSupercats = {};
  const lastSuperCats = blockedCats.supercategories.current;
  const prevSuperCats = blockedCats.supercategories.prev;
  const blockedSupercategories = lastSuperCats.map(supercat => {
    const prev = prevSuperCats.find(
      prevSuperCat => prevSuperCat.key === supercat.key
    );
    addedSupercats[supercat.key] = true;
    let trend = 'infinite';
    if (prev) {
      trend = ((supercat.doc_count - prev.doc_count) / prev.doc_count) * 100;
    } else if (supercat.doc_count === 0) {
      trend = 0;
    }

    return {
      supercategory: cats[supercat.key].display,
      count: supercat.doc_count,
      key: supercat.key,
      trend: trend,
      delta: prev ? supercat.doc_count - prev.doc_count : supercat.doc_count,
    };
  });

  for (let i = 0; i < supercats.length; i += 1) {
    if (!addedSupercats[parseInt(supercats[i], 10)]) {
      const prev = prevSuperCats.find(
        prevCat => prevCat.key === cats[supercats[i]].key
      );
      blockedSupercategories.push({
        supercategory: cats[supercats[i]].display,
        count: 0,
        key: parseInt(supercats[i], 10),
        trend: prev ? -100 : '-',
        delta: prev ? prev.doc_count : 0,
      });
    }
  }

  const addedCats = {};
  const lastCats = blockedCats.categories.current;
  const prevCats = blockedCats.categories.prev;
  const blockedCategories = lastCats.map(cat => {
    const prev = prevCats.find(prevCat => prevCat.key === cat.key);
    addedCats[cat.key] = true;
    let trend = 'infinite';
    if (prev) {
      trend = ((cat.doc_count - prev.doc_count) / prev.doc_count) * 100;
    } else if (cat.doc_count === 0) {
      trend = '-';
    }

    return {
      category: cats[cat.key].display,
      count: cat.doc_count,
      key: cat.key,
      trend: trend,
      delta: prev ? cat.doc_count - prev.doc_count : cat.doc_count,
    };
  });

  if (blockedCategories.length < blockedSupercategories.length) {
    for (let i = 0; i < categories.length; i += 1) {
      if (!addedCats[parseInt(categories[i], 10)]) {
        const prev = prevCats.find(
          prevCat => prevCat.key === cats[categories[i]].key
        );

        blockedCategories.push({
          category: cats[categories[i]].display,
          count: 0,
          key: parseInt(categories[i], 10),
          trend: prev ? -100 : '-',
          delta: prev ? prev.doc_count : 0,
        });
      }

      if (blockedCategories.length >= blockedSupercategories.length) {
        break;
      }
    }
  }

  return {
    blockedRequests: blockedCats.curTotal,
    prevBlockedRequests: blockedCats.prevTotal,
    blockedCategories,
    blockedSupercategories,
  };
};

const formatThreatCount = threatCount =>
  threatCount.buckets.length ? threatCount.buckets[0].doc_count : 0;

const formatThreatCounts = threatCounts => threatCounts.buckets || [];

const formatThreatLogs = (agentInfo, deviceThreats) => {
  const agents = {};

  for (let agent of agentInfo) {
    agents[agent.key] = {
      name: agent.last_status.hits.hits[0]._source.device_name,
      version: agent.last_status.hits.hits[0]._source.agent_version,
    };
  }
  let logs = deviceThreats.buckets.map(agent => ({
    name: agents[agent.key].name,
    version: agents[agent.key].version,
    count: agent.doc_count,
    user: agent.last_file.hits.hits[0]._source.user,
    timestamp: agent.last_file.hits.hits[0]._source.timestamp,
  }));
  logs.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));

  return logs;
};

export const getATPDataSuccess = result => {
  const module = result.module;
  const formattedResult = {};

  if (result.agent_threats && (!module || module === 'threatCount')) {
    formattedResult.threatCount = formatThreatCount(
      result.agent_threats.aggregations.threat_count
    );
  }
  if (result.agent_threats && (!module || module === 'threatCounts')) {
    formattedResult.threatCounts = formatThreatCounts(
      result.agent_threats.aggregations.threat_counts
    );
  }
  if (result.agent_threats && (!module || module === 'threatLogs')) {
    formattedResult.threatLogs = formatThreatLogs(
      result.agent_info,
      result.agent_threats.aggregations.device_threats
    );
  }
  if (!module || module === 'blocked_info') {
    formattedResult.categories = formatBlockedCategories(
      result.blocked_info,
      result.wcs_cats.categories,
      result.wcs_cats.supercategories
    );
  }
  if (!module) {
    formattedResult.dayCategories = formatBlockedCategories(
      result.day_blocked_info,
      result.wcs_cats.categories,
      result.wcs_cats.supercategories
    );
  }

  return {
    type: GET_ATP_DATA_SUCCESS,
    formattedResult,
  };
};

export const getATPDataFailure = result => ({
  type: GET_ATP_DATA_FAILURE,
  result,
});

export const sortLogs = (order, property) => ({
  type: SORT_LOGS,
  order,
  property,
});
