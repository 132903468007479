import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import {
  CudaDialogContent,
  CudaDialogTitle,
  CudaDialogRegularContent,
  ActionButton,
} from '../CudaDialog';

class ResolveQuarantineComponent extends Component {
  render() {
    const {
      classes,
      showModal,
      closeDialog,
      buttonAction,
      title,
      action,
      file,
    } = this.props;
    return (
      <Dialog open={showModal} maxWidth="md" onEscapeKeyDown={closeDialog}>
        <CudaDialogTitle
          onClose={closeDialog}
          titleClasses={{
            root: classes[action + 'Title'],
          }}
        >
          {I18n.t('components.quarantine.' + title)}
        </CudaDialogTitle>
        <CudaDialogContent overflow="visible" loadingAnimation={false}>
          <div className={classes.dialogContent}>
            <CudaDialogRegularContent className={classes.dialogContent}>
              <Grid container spacing={0} className={classes.infoWrapper}>
                <Grid item xs={3}>
                  <Translate value="components.quarantine.fileName" />
                </Grid>
                <Grid item xs={9} className={classes.fileInfo}>
                  {file.file_name}
                </Grid>
                <Grid item xs={3}>
                  <Translate value="shared.endpoint" />
                </Grid>
                <Grid item xs={9} className={classes.fileInfo}>
                  {file.device_name}
                </Grid>
                <Grid item xs={12} className={classes.warningText}>
                  <Translate value="components.quarantine.modalText" />
                </Grid>
              </Grid>
            </CudaDialogRegularContent>
          </div>
        </CudaDialogContent>
        <DialogActions>
          <div>
            <ActionButton onClick={closeDialog}>
              {I18n.t('shared.cancel')}
            </ActionButton>
            <ActionButton
              buttonClasses={{
                root: classes[action + 'Title'],
              }}
              onClick={buttonAction}
            >
              {I18n.t('shared.' + action)}
            </ActionButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}
ResolveQuarantineComponent.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func,
  title: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
};

const styles = theme => ({
  dialogContent: {
    width: '500px',
  },
  fileInfo: {
    fontWeight: '600',
  },
  infoWrapper: {
    fontSize: '16px',
    lineHeight: '20px',
    '& > div': {
      padding: '4px 0',
    },
    '& > $warningText': {
      paddingTop: '20px',
    },
  },
  warningText: {
    fontSize: '14px',
  },
  restoreTitle: {
    backgroundColor: '#0084C1',
  },
  deleteTitle: {
    backgroundColor: '#DD1E31',
  },
});

export default withStyles(styles, { name: 'ResolveQuarantineStyles' })(
  ResolveQuarantineComponent
);
