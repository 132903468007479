import { connect } from 'react-redux';
import FilterSearchBar from '../../components/FilterSearchBar';

import {
  searchInputOnChange,
  searchFieldOnChange,
} from '../../actions/advanced_filtering';
import { getQueryFieldValues } from '../../selectors/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  const fieldValues = getQueryFieldValues(state);
  const fields = state.advanced_filtering.search.queryFields;
  const field = state.advanced_filtering.search.queryField;
  const selectedFieldValues = fieldValues[fields[field].field];
  return {
    value: state.advanced_filtering.search.query,
    fields: fields,
    field: field,
    fieldValues: selectedFieldValues,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: value => {
    // User can delete the selection and it ends
    // up as an array
    if (value.constructor === Array) {
      return dispatch(searchInputOnChange(''));
    }

    dispatch(searchInputOnChange(value));
  },
  handleMenuItemClick: field => {
    dispatch(searchFieldOnChange(field));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterSearchBar);
