import { connect } from 'react-redux';
import NewUserTrial from '../../components/signup/NewUserTrial';
import {
  submitEmail,
  updateEmailAddress,
  toggleLogin,
} from '../../actions/signup';

const mapStateToProps = state => ({
  form: state.signup.emailForm,
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: event => {
    event.preventDefault();
    dispatch(submitEmail());
  },
  updateField: event => {
    dispatch(updateEmailAddress(event.target.value));
  },
  handleLogin: () => {
    dispatch(toggleLogin());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewUserTrial);
