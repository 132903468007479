import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { I18n } from 'react-i18nify';
import SessionModal from '../components/SessionModal';
import Auth from '../lib/Auth';

import {
  showSessionModal,
  refreshSession,
  closeSessionModal,
} from '../actions/account';

const mapStateToProps = (state, ownProps) => ({
  title: I18n.t('shared.sessionRefresh'),
  showModal: state.account.sessionToken.show,
  processing: state.account.sessionToken.processing,
  saveBtnText: I18n.t('shared.refresh'),
  content: I18n.t('shared.sessionRefreshPrompt'),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  buttonAction: () => {
    dispatch(refreshSession());
    const dtoken = Auth.get_sso_token();
    if (Auth.timer) {
      clearTimeout(Auth.timer);
    }
    setTimeout(() => {
      dispatch(showSessionModal());
    }, (dtoken['exp'] - dtoken['auth_time']) * (3 / 4) * 1000);
  },
  closeDialog: () => {
    dispatch(closeSessionModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SessionModal));
