import React from 'react';
import PropTypes from 'prop-types';
import { I18n, Translate } from 'react-i18nify';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

import RulesetRow from '../../containers/advanced_filtering/RulesetRow';

const styles = {
  header: {
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    color: 'rgba(0, 0, 0, 0.50)',
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    '& > div': {
      padding: '10px 4px',
      '&:last-child': {
        textAlign: 'center',
      },
    },
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
  },
  smallDiv: {
    flex: '0 0 50px',
  },
  bigDiv: {
    flex: '1 1',
  },
  wrapper: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
    display: 'flex',
    justifyContent: 'center',
  },
  noRules: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '40px',
  },
};

const Rulesets = ({
  classes,
  ruleset,
  adMap,
  processing,
  openAddRule,
  deleteRulesetRule,
  search,
}) => (
  <div>
    <div className={classes.header}>
      <div className={classes.smallDiv} />
      <div className={classes.bigDiv}>
        {I18n.t('components.advancedFiltering.groupOrUser')}
      </div>
      <div className={classes.smallDiv}>
        {I18n.t('components.advancedFiltering.more')}
      </div>
    </div>
    {processing && (
      <div className={classes.wrapper}>
        <CircularProgress data-test-id="loadingSpinner" />
      </div>
    )}
    {!processing && !ruleset.rules.length && (
      <div className={classes.wrapper}>
        <Translate
          value="components.advancedFiltering.noRules"
          className={classes.noRules}
        />
      </div>
    )}
    {!processing &&
      Boolean(ruleset.rules.length) &&
      ruleset.rules.map((rule, i) => {
        let name = ['Everyone'];
        let type = 'everyone';
        let highlight = false;

        if (rule.user_ids.length) {
          name = rule.user_ids.map(id =>
            adMap[id] ? (
              adMap[id]['display_name']
            ) : (
              <Translate
                style={{ color: '#DD1E31' }}
                value="shared.noLongerExists"
              />
            )
          );
          type = 'user';
          if (
            search.query &&
            rule['user_ids'].indexOf(search.query.value) > -1
          ) {
            highlight = true;
          }
        } else if (rule.group_ids.length) {
          name = rule.group_ids.map(id =>
            adMap[id] ? (
              adMap[id]['group_name']
            ) : (
              <Translate
                style={{ color: '#DD1E31' }}
                value="shared.noLongerExists"
              />
            )
          );
          type = 'group';
          if (
            search.query &&
            rule['group_ids'].indexOf(search.query.value) > -1
          ) {
            highlight = true;
          }
        }

        return (
          <RulesetRow
            key={rule.id}
            ruleId={rule.id}
            rule={rule}
            name={name}
            highlight={highlight}
            type={type}
            users={rule.user_ids}
            groups={rule.group_ids}
            totalCount={ruleset.rules.length - 2} // everyone group is always at the bottom
            arrIndex={i}
            openAddRule={openAddRule}
            deleteRulesetRule={deleteRulesetRule}
            adMap={adMap}
          />
        );
      })}
  </div>
);

Rulesets.propTypes = {
  classes: PropTypes.object.isRequired,
  ruleset: PropTypes.object.isRequired,
  adMap: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  openAddRule: PropTypes.func.isRequired,
  deleteRulesetRule: PropTypes.func.isRequired,
};

const styled = withStyles(styles, { name: 'Rulesets' })(Rulesets);
export default DragDropContext(HTML5Backend)(styled);
