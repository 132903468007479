export const TOGGLE_SCAN = 'TOGGLE_SCAN';
export const TOGGLE_SCAN_SUCCESS = 'TOGGLE_SCAN_SUCCESS';
export const TOGGLE_SCAN_FAILURE = 'TOGGLE_SCAN_FAILURE';
export const TOGGLE_CUSTOM_EXCLUSION = 'TOGGLE_CUSTOM_EXCLUSION';
export const ADD_CUST_FILE_EXCLUSION = 'ADD_CUST_FILE_EXCLUSION';
export const ADD_CUST_FILE_EXCLUSION_SUCCESS =
  'ADD_CUST_FILE_EXCLUSION_SUCCESS';
export const ADD_CUST_FILE_EXCLUSION_FAILURE =
  'ADD_CUST_FILE_EXCLUSION_FAILURE';
export const ADD_CUST_PATH_EXCLUSION = 'ADD_CUST_PATH_EXCLUSION';
export const ADD_CUST_PATH_EXCLUSION_SUCCESS =
  'ADD_CUST_PATH_EXCLUSION_SUCCESS';
export const ADD_CUST_PATH_EXCLUSION_FAILURE =
  'ADD_CUST_PATH_EXCLUSION_FAILURE';
export const ADD_CUST_PROCESS_EXCLUSION = 'ADD_CUST_PROCESS_EXCLUSION';
export const ADD_CUST_PROCESS_EXCLUSION_SUCCESS =
  'ADD_CUST_PROCESS_EXCLUSION_SUCCESS';
export const ADD_CUST_PROCESS_EXCLUSION_FAILURE =
  'ADD_CUST_PROCESS_EXCLUSION_FAILURE';
export const EDIT_CUST_EXCLUSION = 'EDIT_CUST_EXCLUSION';
export const EDIT_CUST_EXCLUSION_SUCCESS = 'EDIT_CUST_EXCLUSION_SUCCESS';
export const EDIT_CUST_EXCLUSION_FAILURE = 'EDIT_CUST_EXCLUSION_FAILURE';
export const REMOVE_CUST_EXCLUSION = 'REMOVE_CUST_EXCLUSION';
export const REMOVE_CUST_EXCLUSION_SUCCESS = 'REMOVE_CUST_EXCLUSION_SUCCESS';
export const REMOVE_CUST_EXCLUSION_FAILURE = 'REMOVE_CUST_EXCLUSION_FAILURE';
export const REMOVE_CUST_FILE_EXCLUSION = 'REMOVE_CUST_FILE_EXCLUSION';
export const REMOVE_CUST_PATH_EXCLUSION = 'REMOVE_CUST_PATH_EXCLUSION';
export const REMOVE_CUST_PROCESS_EXCLUSION = 'REMOVE_CUST_PROCESS_EXCLUSION';
export const EXCLUSION_TYPE_FILE = 'FILE';
export const EXCLUSION_TYPE_PATH = 'PATH';
export const EXCLUSION_TYPE_PROCESS = 'PROCESS';
export const ATP_SETTINGS_INIT = 'ATP_SETTINGS_INIT';
export const ATP_GET_SUCCESS = 'ATP_GET_SUCCESS';
export const ATP_GET_FAILURE = 'ATP_GET_FAILURE';

export const settings = {
  scanMSOffice: 'scanMSOffice',
  executables: 'executables',
  pdfDocuments: 'pdfDocuments',
  archiveFiles: 'archiveFiles',
  autoRemediate: 'autoRemediate',
  blockedPwProtectedOfficeDoc: 'blockedPwProtectedOfficeDoc',
  blockedPwProtectedArchiveFile: 'blockedPwProtectedArchiveFile',
  scanExclusion: 'scanExclusion',
  cpaEnabled: 'cpaEnabled',
};

export const toggleSetting = setting => ({
  type: TOGGLE_SCAN,
  setting,
});

export const toggleSettingSuccess = setting => ({
  type: TOGGLE_SCAN_SUCCESS,
  setting,
});

export const toggleSettingFailure = setting => ({
  type: TOGGLE_SCAN_FAILURE,
  setting,
});

export const toggleCustomExclusion = value => ({
  type: TOGGLE_CUSTOM_EXCLUSION,
  value,
});

export const addCustomExclusion = (type, value) => ({
  type,
  value,
});

export const addFileCustomExclusionSuccess = value => ({
  type: ADD_CUST_FILE_EXCLUSION_SUCCESS,
  value,
});

export const addFileCustomExclusionFailure = () => ({
  type: ADD_CUST_FILE_EXCLUSION_FAILURE,
});

export const addPathCustomExclusionSuccess = value => ({
  type: ADD_CUST_PATH_EXCLUSION_SUCCESS,
  value,
});

export const addPathCustomExclusionFailure = () => ({
  type: ADD_CUST_PATH_EXCLUSION_FAILURE,
});

export const addProcessCustomExclusionSuccess = value => ({
  type: ADD_CUST_PROCESS_EXCLUSION_SUCCESS,
  value,
});

export const addProcessCustomExclusionFailure = () => ({
  type: ADD_CUST_PROCESS_EXCLUSION_FAILURE,
});

export const editCustomExclusion = (
  type,
  prevType,
  prevValue,
  cType,
  cValue
) => ({
  type,
  prevType,
  prevValue,
  cType,
  cValue,
});

export const editCustomExclusionSuccess = atp => ({
  type: EDIT_CUST_EXCLUSION_SUCCESS,
  atp,
});

export const editCustomExclusionFailure = () => ({
  type: EDIT_CUST_EXCLUSION_FAILURE,
});

export const removeCustomExclusion = (fileType, value) => ({
  type: REMOVE_CUST_EXCLUSION,
  fileType,
  value,
});

export const removeCustomExclusionSuccess = atp => ({
  type: REMOVE_CUST_EXCLUSION_SUCCESS,
  atp,
});

export const removeCustomExclusionFailure = () => ({
  type: REMOVE_CUST_EXCLUSION_FAILURE,
});

export const initATP = () => ({
  type: ATP_SETTINGS_INIT,
});

export const getATPSuccess = result => ({
  type: ATP_GET_SUCCESS,
  result,
});

export const getATPFailure = error => ({
  type: ATP_GET_FAILURE,
  error,
});
