import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import CudaMenu from '../CudaMenu';
import SectionTitle from './SectionTitle';

class PolicyDropdown extends Component {
  static getDerivedStateFromProps(nextProps) {
    const { customPolicyKey } = nextProps;

    let options = [];
    if (nextProps.levels) {
      options = [
        {
          key: 'recommended-defaults',
          label: I18n.t('components.basicFiltering.recommendDefaults'),
          type: 'label',
        },
        {
          key: 'high',
          label: I18n.t('components.basicFiltering.high', {
            count: nextProps.levels.high.length,
          }),
          entries: nextProps.levels.high,
        },
        {
          key: 'medium',
          label: I18n.t('components.basicFiltering.medium', {
            count: nextProps.levels.medium.length,
          }),
          entries: nextProps.levels.medium,
        },
        {
          key: 'low',
          label: I18n.t('components.basicFiltering.low', {
            count: nextProps.levels.low.length,
          }),
          entries: nextProps.levels.low,
        },
      ];
    }
    options.push({
      key: 'custom',
      label: I18n.t('components.basicFiltering.custom', { count: 0 }),
      entries: [],
    });

    if (nextProps.custom && nextProps.custom.length) {
      options = [
        ...options,
        { key: 'custom-policies', label: 'Custom Policies', type: 'label' },
        ...nextProps.custom.map(policy => ({
          key: policy.id,
          label: `${policy.name} (${policy[customPolicyKey].length})`,
          entries: policy[customPolicyKey],
        })),
      ];
    }
    return {
      options,
    };
  }

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  state = {
    options: [],
  };

  onChange = index => {
    this.props.onChange(
      this.state.options[index].key,
      this.state.options[index].entries
    );
  };

  render() {
    const { classes, selected } = this.props;
    const selectedIndex = this.state.options.findIndex(
      option => option.key === selected
    );
    const label = `components.basicFiltering.${this.props.label}`;

    return (
      <div>
        <SectionTitle
          tooltipText={<Translate value={`${label}Help`} dangerousHTML />}
          titleText={I18n.t(label)}
          tooltipTestId="policyHelp"
        />
        <CudaMenu
          customClasses={[classes.menu]}
          options={this.state.options}
          selectedIndex={selectedIndex}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

PolicyDropdown.propTypes = {
  levels: PropTypes.shape({
    high: PropTypes.arrayOf(PropTypes.number),
    medium: PropTypes.arrayOf(PropTypes.number),
    low: PropTypes.arrayOf(PropTypes.number),
    custom: PropTypes.arrayOf(PropTypes.number),
  }),
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.string,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};
PolicyDropdown.defaultProps = {
  selected: 'custom',
  levels: null,
};

const styles = {
  menu: {
    marginTop: '4px',
  },
};

export default withStyles(styles, { name: 'ConfigureDNS' })(PolicyDropdown);
