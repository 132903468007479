import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import CustomizedAxisTick from '../charts/CustomizedAxisTick';
import CudaMenu from '../CudaMenu';
import ModuleLoading from './ModuleLoading';

function CustomBarLabel({ x, y, value, width }) {
  return value ? (
    <text
      x={x + width / 2}
      y={y}
      fontSize="12"
      fill="#E34050"
      textAnchor="middle"
      dy={-6}
    >{`${value}`}</text>
  ) : null;
}
CustomBarLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

class ThreatGraphComponent extends Component {
  state = {
    timeRangeIndex: 1,
  };

  onChangeTimerange = timeRangeIndex => {
    this.props.changeTimerange(this.options[timeRangeIndex].key);
    this.setState({ timeRangeIndex });
  };

  options = [
    {
      key: 'day',
      label: I18n.t('shared.ranges.day'),
    },
    {
      key: 'week',
      label: I18n.t('shared.ranges.week'),
    },
    {
      key: 'month',
      label: I18n.t('shared.ranges.month'),
    },
  ];

  render() {
    const { classes, graphData, hideTitle = false, loading } = this.props;
    const { timeRangeIndex } = this.state;
    const { options } = this;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.wrapper}>
          {loading ? <ModuleLoading /> : null}
          {loading || hideTitle ? null : (
            <Typography type="caption">
              {options[timeRangeIndex].label}
            </Typography>
          )}
          {!loading ? (
            <ResponsiveContainer className={classes.graph} height={200}>
              <BarChart margin={{ top: 20 }} data={graphData}>
                <XAxis
                  interval={0}
                  tickLine={false}
                  tick={
                    <CustomizedAxisTick
                      graphType={options[timeRangeIndex].key}
                    />
                  }
                  dataKey="key_as_string"
                />
                <YAxis
                  fontSize="12"
                  tick={{ transform: 'translate(0, -7)' }}
                  stroke="rgba(0,0,0,.2)"
                  orientation={'right'}
                  mirror={true}
                  axisLine={false}
                  allowDecimals={false}
                />
                <CartesianGrid vertical={false} />
                <Bar
                  dataKey="doc_count"
                  barSize={40}
                  label={<CustomBarLabel width={40} />}
                  fill="#E34050"
                />
              </BarChart>
            </ResponsiveContainer>
          ) : null}
        </Grid>
        <Grid item xs={6}>
          <CudaMenu
            disabled={loading}
            customStyle={classes.timeMenu}
            options={options}
            selectedIndex={timeRangeIndex}
            onChange={value => {
              this.onChangeTimerange(value);
            }}
          />
        </Grid>
      </Grid>
    );
  }
}
ThreatGraphComponent.propTypes = {
  graphData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      threatCount: PropTypes.number,
    })
  ),
  hideTitle: PropTypes.bool,
};

const threatGraphStyles = {
  wrapper: {
    padding: '0 20px 20px 20px',
    position: 'relative',
    borderBottom: '1px solid #DDDDDD',
    height: '260px',
  },
  graph: {
    marginTop: '20px',
  },
  timeMenu: {
    border: 0,
    maxWidth: '140px',
  },
};

export default withStyles(threatGraphStyles, { name: 'ThreatGraphStyles' })(
  ThreatGraphComponent
);
