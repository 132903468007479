import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';

const StatusBar = ({
  classes,
  error,
  show,
  saving,
  cancelAction,
  submitAction,
}) =>
  show && (
    <Fade in={show}>
      <div className={classes.wrapper}>
        {saving && (
          <LinearProgress
            className={classes.progress}
            classes={{ barColorPrimary: classes.primaryProgress }}
          />
        )}
        <div style={{ flex: 1 }} />
        <div
          className={classes.error}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {!saving && error}
        </div>
        <div className={classes.buttonWrapper} style={{ flex: 1 }}>
          {cancelAction && (
            <Button
              variant="contained"
              color="secondary"
              disabled={saving}
              onClick={cancelAction}
            >
              {I18n.t('shared.cancel')}
            </Button>
          )}
          {submitAction && (
            <Button
              variant="contained"
              color="primary"
              disabled={saving}
              onClick={submitAction}
            >
              {I18n.t('shared.save')}
            </Button>
          )}
        </div>
      </div>
    </Fade>
  );

const styles = theme => ({
  error: {
    display: 'flex',
    alignItems: 'center',
    color: theme.statusColors.failure,
  },
  wrapper: {
    zIndex: 1,
    backgroundColor: '#FFFFFF',
    width: '100%',
    position: 'fixed',
    padding: '12px',
    display: 'flex',
    justifyContent: 'center',
    bottom: 0,
    left: 0,
    boxShadow: '0 8px 17px 0 rgba(0,0,0,0.3), 0 6px 20px 0 rgba(0,0,0,0.19)',
    boxSizing: 'border-box',
  },
  progress: {
    height: '4px',
    backgroundColor: '#95CC66',
    top: 0,
    position: 'absolute',
    width: '100%',
  },
  primaryProgress: {
    backgroundColor: '#FFFFFF',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button': {
      margin: 0,
      padding: '8px',
      '&:last-child': {
        marginLeft: '10px',
      },
    },
  },
});

StatusBar.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  cancelAction: PropTypes.func,
  submitAction: PropTypes.func,
};

StatusBar.defaultProps = {
  cancelAction: undefined,
  submitAction: undefined,
};

export default withStyles(styles, { name: 'StatusBar' })(StatusBar);
