import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Text,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Translate } from 'react-i18nify';

const styles = theme => ({
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const CustomizedYAxisTick = ({ x, y, stroke, payload, textWidth = 150 }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        width={textWidth}
        fontSize="12"
        x={0}
        y={0}
        dy={2}
        textAnchor="end"
        fill="#606060"
      >
        {payload.value}
      </Text>
    </g>
  );
};

const CustomizedXAxisTick = ({ x, y, stroke, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        width={30}
        fontSize="12"
        x={0}
        y={0}
        dy={20}
        textAnchor="middle"
        fill="#606060"
      >
        {payload.value}
      </Text>
    </g>
  );
};

const BarComponent = ({
  classes,
  data,
  height = 400,
  width = 600,
  textWidth = 150,
}) => {
  return (
    <ResponsiveContainer height={height} width={width}>
      {data.length > 0 ? (
        <BarChart
          width={width}
          height={height}
          data={data}
          margin={{ left: 105, bottom: 15 }}
          barSize={32}
          layout="vertical"
        >
          <CartesianGrid horizontal={false} />
          <XAxis type="number" tick={<CustomizedXAxisTick />} />
          <YAxis
            axisLine={false}
            type="category"
            tickLine={false}
            tick={<CustomizedYAxisTick textWidth={textWidth} />}
            dataKey="name"
          />
          <Bar dataKey="count" fill="#0088CE" />
        </BarChart>
      ) : (
        <div className={classes.noResults}>
          <Translate value="shared.noResults" />
        </div>
      )}
    </ResponsiveContainer>
  );
};

BarComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
};

export default withStyles(styles, { name: 'AreaChart' })(BarComponent);
