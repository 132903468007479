import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';

import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

class PasswordInputComponent extends Component {
  state = {
    showPassword: false,
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleTogglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const {
      classes,
      label,
      passwordShow = false,
      value,
      handleChange,
      handleFocus,
      handleBlur,
      disabled,
      ...other
    } = this.props;

    return (
      <TextField
        fullWidth={true}
        label={label}
        disabled={disabled}
        type={this.state.showPassword ? 'text' : 'password'}
        value={value}
        onChange={handleChange}
        InputProps={{
          className: classes.pwInput,
          onFocus: handleFocus,
          onBlur: handleBlur,
          disableUnderline: true,
          classes: {
            error: classes.error,
          },
          endAdornment: passwordShow ? (
            <InputAdornment position="end">
              <IconButton
                data-test-id="password-icon"
                onClick={this.handleTogglePassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        InputLabelProps={{
          className: classes.pwLabel,
          shrink: true,
        }}
        {...other}
      />
    );
  }
}

PasswordInputComponent.defaultProps = {
  handleFocus: () => {},
  handleBlur: () => {},
  disabled: false,
};

PasswordInputComponent.propTypes = {
  label: PropTypes.string.isRequired,
  passwordShow: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

const inputStyles = theme => ({
  pwLabel: {
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, .6)',
    transform: 'none',
  },
  error: {
    borderColor: `${theme.statusColors.failure} !important`,
  },
  pwInput: {
    border: '1px solid #DDDDDD',
    boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.2)',
    '& input': {
      fontSize: '14px',
      lineHeight: '20px',
      height: '20px',
      color: 'rgba(0, 0, 0, .5)',
      borderRadius: '2px',
      padding: '6px',
    },
  },
});

export default withStyles(inputStyles, { name: 'PasswordInput' })(
  PasswordInputComponent
);
