import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const SessionAction = ({
  classes,
  label,
  buttonLabel,
  buttonAction,
  ...others
}) => (
  <div className={classes.wrapper}>
    {label}
    {buttonAction && (
      <Button color="primary" onClick={buttonAction} {...others}>
        {buttonLabel}
      </Button>
    )}
  </div>
);

const styles = {
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    '& > button': {
      padding: '0 5px',
      height: '20px',
      minHeight: '20px',
    },
  },
};

SessionAction.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonAction: PropTypes.func,
};

SessionAction.defaultProps = {
  buttonLabel: '',
  buttonAction: '',
};

export default withStyles(styles, { name: 'SessionAction' })(SessionAction);
