import { connect } from 'react-redux';
import WebFilteringLogs from '../components/WebFilteringLogs';
import { getQueryFieldValues } from '../selectors/wf_logs';
import {
  initData,
  updateQuery,
  updateRange,
  queryData,
  updateField,
} from '../actions/wf_logs';

const mapStateToProps = state => ({
  query: state.wfLogs.query,
  queryFields: state.wfLogs.queryFields,
  queryField: state.wfLogs.queryField,
  queryFieldValues: getQueryFieldValues(state),
});

const mapDispatchToProps = dispatch => ({
  initLogs: () => {
    dispatch(initData());
  },
  updateQuery: query => {
    dispatch(updateQuery(query));
  },
  queryData: () => {
    dispatch(queryData());
  },
  updateRange: index => {
    dispatch(updateRange(index));
  },
  updateField: field => {
    dispatch(updateField(field));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFilteringLogs);
