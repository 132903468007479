import React from 'react';
import { I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Paper from '../CudaPaper';
import UserTable from '../../containers/user_authentication/UserTable';
import SearchBar from '../../containers/user_authentication/SearchBar';

import AddLocalUsers from '../../containers/user_authentication/AddLocalUsers';
import EditLocalUser from '../../containers/user_authentication/EditLocalUser';
import EditLocalGroup from '../../containers/user_authentication/EditLocalGroup';
import DeleteGroup from '../../containers/user_authentication/DeleteGroup';
import MoveLocalUser from '../../containers/user_authentication/MoveLocalUser';
import RemoveFromGroup from '../../containers/user_authentication/RemoveFromGroup';

import Modal from '../modal/Modal';

const LocalUsers = ({
  classes,
  modal,
  showAddUsers,
  loading,

  updateUser,
  updateGroup,
  moveUser,
  removeFromGroup,
  addUsers,
  editUser,
  deleteGroup,

  closeModal,
}) => (
  <Grid id="basic-filtering" spacing={24} container>
    <Grid item xs={12} md={8} lg={6}>
      <SearchBar
        data-test-id="local-user-search"
        height="40px"
        autoFocus={false}
        disabled={loading}
        placeholder={I18n.t('userAuthentication.local.searchPlaceholder')}
      />
    </Grid>
    <Grid className={classes.buttonWrapper} item xs={12} md={4} lg={6}>
      <Button
        id="add-network-btn"
        variant="contained"
        color="secondary"
        onClick={showAddUsers}
        disabled={loading}
      >
        {I18n.t('userAuthentication.local.addUsers')}
      </Button>
    </Grid>
    <Grid item xs={12}>
      <Paper loading={loading}>
        <UserTable />
      </Paper>
    </Grid>
    <Modal
      error={modal.error}
      showModal={modal.show && modal.name === 'addLocalUsers'}
      title={I18n.t('userAuthentication.local.addLocalUsers')}
      saveBtnText={I18n.t('shared.add')}
      closeDialog={closeModal}
      buttonAction={addUsers}
      disabled={!modal.changed}
      processing={modal.processing}
      padding={false}
      overflow
      name="add-local-users"
    >
      <AddLocalUsers />
    </Modal>
    <Modal
      error={modal.error}
      showModal={modal.show && modal.name === 'editLocalUser'}
      title={I18n.t('userAuthentication.local.editUser')}
      saveBtnText={I18n.t('shared.save')}
      closeDialog={closeModal}
      disabled={!modal.changed}
      processing={modal.processing}
      buttonAction={updateUser}
      name="edit-local-user"
    >
      <EditLocalUser />
    </Modal>
    <Modal
      error={modal.error}
      showModal={modal.show && modal.name === 'editLocalGroup'}
      title={I18n.t('userAuthentication.local.renameGroup')}
      saveBtnText={I18n.t('shared.save')}
      closeDialog={closeModal}
      disabled={!modal.changed}
      contentClass={classes.shortModal}
      processing={modal.processing}
      buttonAction={updateGroup}
      name="edit-local-group"
    >
      <EditLocalGroup />
    </Modal>
    <Modal
      error={modal.error}
      showModal={modal.show && modal.name === 'moveLocalUser'}
      title={I18n.t('userAuthentication.local.moveUser')}
      saveBtnText={I18n.t('shared.move')}
      closeDialog={closeModal}
      contentClass={classes.shortModal}
      disabled={!modal.changed}
      processing={modal.processing}
      buttonAction={moveUser}
      overflow
      name="move-local-user"
    >
      <MoveLocalUser />
    </Modal>
    <Modal
      error={modal.error}
      showModal={modal.show && modal.name === 'removeFromGroup'}
      title={I18n.t('userAuthentication.local.removeFromGroup')}
      saveBtnText={I18n.t('shared.remove')}
      actionBtnClass={classes.deleteBtn}
      contentClass={classes.shortModal}
      closeDialog={closeModal}
      processing={modal.processing}
      buttonAction={removeFromGroup}
      name="remove-from-group"
    >
      <RemoveFromGroup />
    </Modal>
    <Modal
      error={modal.error}
      showModal={modal.show && modal.name === 'deleteLocalGroup'}
      title={I18n.t('userAuthentication.local.deleteGroup')}
      saveBtnText={I18n.t('shared.delete')}
      actionBtnClass={classes.deleteBtn}
      contentClass={classes.shortModal}
      closeDialog={closeModal}
      processing={modal.processing}
      buttonAction={deleteGroup}
      name="delete-local-group"
    >
      <DeleteGroup />
    </Modal>
  </Grid>
);

const styles = {
  buttonWrapper: {
    '& > button': {
      margin: 0,
    },
    display: 'inline-flex',
    justifyContent: 'flex-end',
  },
  shortModal: {
    width: '500px',
  },
  deleteBtn: {
    backgroundColor: '#DD1E31',
    '&:hover': {
      backgroundColor: '#B71C1C',
    },
  },
  dialog: {
    overflow: 'visible',
  },
};

export default withStyles(styles, { name: 'LocalUsers' })(LocalUsers);
