import React, { Component } from 'react';

import { I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Select from 'react-select';
import Button from '@material-ui/core/Button';

import Paper from '.././CudaPaper';

const customStyles = {
  control: () => ({
    display: 'flex',
    height: '32px',
    border: '1px solid #DDDDDD',
    boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.2)',
    '& input': {
      borderRadius: '2px',
      padding: '6px',
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#555555',
    opacity: '0.5',
    margin: '0',
    cursor: 'text',
  }),
  option: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  singleValue: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
};

class PreferencesComponent extends Component {
  state = {
    showEditTZ: false,
  };

  toggleEditTZ() {
    this.setState({
      showEditTZ: !this.state.showEditTZ,
    });
  }

  render() {
    const {
      classes,
      changeTimezone,
      time_zone,
      saving,
      timezones,
    } = this.props;
    const { showEditTZ } = this.state;

    return (
      <Paper
        title={I18n.t('shared.preferences')}
        elevation={2}
        className={classes.paper}
      >
        <Grid container justify="space-between" className={classes.container}>
          <Grid item xs={4} className={classes.label}>
            {I18n.t('components.account.timezone')}
          </Grid>
          <Grid item xs={8}>
            {showEditTZ ? (
              <div data-test-id="timezone-select">
                <Select
                  isDisabled={saving}
                  styles={customStyles}
                  isClearable={false}
                  isMulti={false}
                  backspaceRemovesValue={false}
                  escapeClearsValue={false}
                  options={timezones}
                  className={classes.selectDropdown}
                  value={{ value: time_zone, label: time_zone }}
                  onChange={timezone => changeTimezone(timezone.value)}
                />
              </div>
            ) : (
              <div className={classes.tzLabel}>
                {time_zone}

                <Button
                  data-test-id="timezone-change-btn"
                  color="primary"
                  className={classes.link}
                  onClick={() => this.toggleEditTZ()}
                >
                  {I18n.t('shared.change')}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const styles = theme => ({
  paper: {
    padding: '25px',
  },
  container: {
    marginTop: '14px',
  },
  label: {
    lineHeight: '33px',
  },
  tzLabel: {
    lineHeight: '33px',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
  },
});

export default withStyles(styles, { name: 'Password' })(PreferencesComponent);
