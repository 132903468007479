import { connect } from 'react-redux';
import ExceptionFilterBar from '../../components/basic_filtering/ExceptionFilterBar';
import { filterExceptions } from '../../actions/basic_filtering';

const mapDispatchToProps = dispatch => ({
  searchHandler: (query, type) => {
    dispatch(filterExceptions(query, type));
  },
});

export default connect(
  undefined,
  mapDispatchToProps
)(ExceptionFilterBar);
