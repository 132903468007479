import { combineReducers } from 'redux';
import { atp } from './atp';
import { atplogs } from './atplogs';
import { account } from './account';
import { dashboard } from './dashboard';
import { mspDashboard } from './mspdashboard';
import { reports } from './reports';
import { devices } from './devices';
import login from './login';
import notifications from './notifications';
import atp_shared from './atp_shared';
import quarantine from './quarantine';
import app from './app';
import audit_log from './audit_log';
import basic_filtering from './basic_filtering';
import advanced_filtering from './advanced_filtering';
import wfLogs from './wf_logs';
import activate from './activate';
import smbAccountSettings from './smb_account_settings';
import signup from './signup';
import blockPageSettings from './block_page_settings';
import userAuthentication from './user_authentication';

const rootReducer = combineReducers({
  app,
  login,
  account,
  atp,
  atplogs,
  dashboard,
  mspDashboard,
  notifications,
  reports,
  atp_shared,
  audit_log,
  quarantine,
  devices,
  basic_filtering,
  advanced_filtering,
  wfLogs,
  activate,
  signup,
  smbAccountSettings,
  blockPageSettings,
  userAuthentication,
});

export default rootReducer;
