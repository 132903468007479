import { createSelector } from 'reselect';
import moment from 'moment-timezone';

const getAccountId = state => state.account.selected;
const getMspSubStats = state => state.account.mspSubStats;
const getADAccounts = state => state.account.activeDirectory;
const getTimezones = () => moment.tz.names();

const getSubs = createSelector(
  [getMspSubStats],
  mspSubStats => {
    const futureDate = moment()
      .add(30, 'days')
      .calendar();

    let subs = {
      dns: false,
      atp: false,
      wca: false,
      ...mspSubStats.reduce((obj, n) => {
        let state = 'good';
        if (moment(n.expiredate).isBefore()) {
          state = 'expired';
        } else if (moment(n.expiredate).isBefore(futureDate)) {
          state = 'warning';
        }
        const daysLeft = moment(n.expiredate).diff(
          moment({ hours: 0 }),
          'days'
        );

        return {
          ...obj,
          [n['type']]: {
            ...n,
            daysLeft,
            state,
          },
        };
      }, {}),
    };

    return subs;
  }
);

const getADUserHash = createSelector(
  [getADAccounts],
  accounts =>
    accounts
      .filter(ad => ad.type === 'user')
      .reduce(
        (obj, ad) => ({
          ...obj,
          [ad.uuid]: ad.display_name,
        }),
        {}
      )
);

const getGroupList = createSelector(
  [getADAccounts],
  accounts =>
    accounts
      .filter(ad => ad.type === 'group')
      .map(ad => ({
        group_id: ad.group_id,
        label: ad.group_name,
        value: ad.uuid,
      }))
      .sort((a, b) => (a.label || '').localeCompare(b.label))
);

const getUserList = createSelector(
  [getADAccounts],
  accounts =>
    accounts
      .filter(ad => ad.type === 'user')
      .map(ad => ({
        user_id: ad.user_id,
        label: ad.display_name,
        value: ad.uuid,
      }))
      .sort((a, b) => (a.label || '').localeCompare(b.label))
);

const getTimezoneList = createSelector(
  [getTimezones],
  timezones =>
    timezones.map(tz => ({ label: tz, value: tz, 'data-test-id': `${tz}` }))
);

export {
  getADUserHash,
  getAccountId,
  getSubs,
  getUserList,
  getGroupList,
  getTimezoneList,
};
