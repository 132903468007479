import React from 'react';
import { I18n } from 'react-i18nify';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import background from '../../login-bg-img.jpg';
import NewUserTrial from '../../containers/signup/NewUserTrial';
import UserLogin from '../../containers/signup/UserLogin';

import ExistingUserSelectAccount from '../../containers/signup/ExistingUserSelectAccount';
import ExistingUserAgreement from '../../containers/signup/ExistingUserAgreement';

const styles = {
  container: {
    height: '100vh',
    flexGrow: 1,
    backgroundColor: '#555555',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
  },
};

const StartTrial = ({ classes, sso, login, page, ...rest }) => {
  let child;

  if (sso && page === 1) {
    child = <ExistingUserSelectAccount />;
  } else if (sso && page === 2) {
    child = <ExistingUserAgreement />;
  } else if (login) {
    child = <UserLogin />;
  } else {
    child = <NewUserTrial />;
  }
  return (
    <DocumentTitle title={I18n.t('signup.startTrial.pageTitle')}>
      <Grid
        data-test-id="trial-container"
        container
        className={classes.container}
        alignItems="center"
        justify="center"
      >
        {child}
      </Grid>
    </DocumentTitle>
  );
};

export default withStyles(styles, { name: 'StartTrial' })(StartTrial);
