import { connect } from 'react-redux';
import { I18n } from 'react-i18nify';
import ChipInput from '../../components/advanced_filtering/ChipInput';

import { onChangeDomain, selectIndex } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({
  name: I18n.t('components.advancedFiltering.domains'),
  inputValue: state.advanced_filtering.ruleModal.domains,
  selectedIndex: state.advanced_filtering.ruleModal.inputIndex,
  error: state.advanced_filtering.selectedRule.error,
});

const mapDispatchToProps = dispatch => ({
  onChange: value => {
    dispatch(onChangeDomain(value));
  },
  onSelectIndex: index => {
    dispatch(selectIndex(index));
  },
  filterInput: value => {
    return value.replace(/\/.*/, '');
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
