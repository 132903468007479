import { connect } from 'react-redux';
import ComboDashboard from '../components/ComboDashboard';
import { initData } from '../actions/dashboard';
import { getSubscription } from '../actions/account';

const mapStateToProps = state => ({
  msp: state.account.msp,
});

const mapDispatchToProps = dispatch => ({
  initData: () => {
    dispatch(initData());
  },
  getSubscription: () => {
    dispatch(getSubscription(true));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComboDashboard);
