import * as Types from '../actions/reports';
import * as AppTypes from '../actions/app';
import { delay } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import Api from './Api';
import { I18n } from 'react-i18nify';

const filterInit = {
  index: 1,
  rows: 10,
  sort: 'date',
  order: 'desc',
};
const pdfInit = {
  reportType: '',
  range: '',
  timezone: '',
  name: '',
  title: '',
  rangeText: '',
  loading: false,
};
const csvInit = {
  reportType: '',
  range: '',
  loading: false,
};

const modalInit = {
  open: false,
  reportType: 'blocked-supercategories',
  reportIndex: 1,
  timeframe: 'month',
  timeframeIndex: 2,
  frequency: 'weekly',
  frequencyIndex: 2,
  recipients: '',
  hour: 1,
  hourIndex: 0,
  week: '5',
  weekIndex: 5,
  day: 1,
  dayIndex: 0,
};

const DEFAULT_SELECTED_REPORT = 1;

export const reports = (
  state = {
    basicWF: {
      'blocked-categories': {
        sortIndex: 2,
        entryKeys: ['name', 'supercategory', 'count'],
        sortDirection: ['none', 'none', 'desc'],
      },
      'blocked-supercategories': {
        sortIndex: 1,
        entryKeys: ['name', 'count'],
        sortDirection: ['none', 'desc'],
      },
    },
    reportSelect: Types.reportTypes[DEFAULT_SELECTED_REPORT].key,
    reportSelectIndex: 1,
    timeSelect: 'month',
    timeSelectIndex: 2,
    startCustom: new Date().setMonth(new Date().getMonth() - 1),
    endCustom: new Date(),
    reportProgress: false,
    report: false,
    genReport: Types.reportTypes[DEFAULT_SELECTED_REPORT].key, // generated report type
    timeLabel: 'month', //generated time label
    graphData: [],
    tableData: [],
    total: 0,
    filters: { ...filterInit },
    reports: [],
    lastSeen: {},
    runReportQueue: [],
    lastSeenPendingList: [],
    loading: false,
    modal: {
      ...modalInit,
    },
    editModal: {
      ...modalInit,
    },
    pdf: {
      ...pdfInit,
    },
    csv: {
      ...csvInit,
    },
  },
  action
) => {
  switch (action.type) {
    case Types.REPORT_DOWNLOAD_PDF:
      return {
        ...state,
        pdf: {
          ...state.pdf,
          reportType: action.reportType,
          range: action.range,
          timezone: action.timezone,
          name: action.name,
          title: action.title,
          rangeText: action.rangeText,
          loading: true,
        },
      };
    case Types.REPORT_DOWNLOAD_PDF_SUCCESS:
    case Types.REPORT_DOWNLOAD_PDF_FAILURE:
      return {
        ...state,
        pdf: {
          ...pdfInit,
        },
      };
    case Types.REPORT_DOWNLOAD_CSV:
      return {
        ...state,
        csv: {
          ...state.csv,
          loading: true,
          reportType: action.reportType,
          range: action.range,
        },
      };
    case Types.REPORT_DOWNLOAD_CSV_SUCCESS:
    case Types.REPORT_DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        csv: {
          ...csvInit,
        },
      };
    case Types.CHECK_REPORTS_TIMESTAMP_SUCCESS:
      let lastSeenPendingList = [...state.lastSeenPendingList];
      let lastSeen = { ...state.lastSeen };

      if (action.lastSeen) {
        for (
          let listIndex = 0;
          listIndex < lastSeenPendingList.length;
          listIndex++
        ) {
          if (
            action.lastSeen[lastSeenPendingList[listIndex]['id']] &&
            action.lastSeen[lastSeenPendingList[listIndex]['id']] !==
              lastSeen[lastSeenPendingList[listIndex]['id']]
          ) {
            lastSeen[lastSeenPendingList[listIndex]['id']] =
              action.lastSeen[lastSeenPendingList[listIndex]['id']];
            lastSeenPendingList.splice(listIndex, 1);
          }
        }
      }

      return {
        ...state,
        lastSeenPendingList: lastSeenPendingList,
        lastSeen: lastSeen,
      };
    case Types.RUN_MULTI_REPORT:
      let list = [];
      const runReportQueue = state.runReportQueue;
      lastSeen = state.lastSeen;

      for (
        let reportIndex = 0;
        reportIndex < runReportQueue.length;
        reportIndex++
      ) {
        list.push({
          id: runReportQueue[reportIndex]['id'],
          lastSeen: lastSeen[runReportQueue[reportIndex]['id']],
        });
      }

      return {
        ...state,
        lastSeenPendingList: list,
      };
    case Types.TOGGLE_BASIC_REPORT:
      let sortDirection = [...state.basicWF[action.report].sortDirection];
      const direction =
        state.basicWF[action.report].sortDirection[action.index];

      for (let i = 0; i < sortDirection.length; i++) {
        sortDirection[i] = 'none';
      }

      if (direction === 'asc') {
        sortDirection[action.index] = 'desc';
      } else {
        sortDirection[action.index] = 'asc';
      }

      return {
        ...state,
        basicWF: {
          ...state.basicWF,
          [action.report]: {
            ...state.basicWF[action.report],
            sortDirection: sortDirection,
            sortIndex: action.index,
          },
        },
      };

    case Types.SET_EDIT_SCHEDULED_REPORT:
      let reportIndex = 0;
      let report = 'all';
      let timeframe = 'month';
      let timeframeIndex = 2;
      let frequency = 'weekly';
      let frequencyIndex = 2;
      let hour = 1;
      let hourIndex = 0;
      let week = '5';
      let weekIndex = 5;
      let day = 1;
      let dayIndex = 0;

      for (let i in action.options) {
        if (action.options[i].key === action.data.report_type) {
          reportIndex = i;
          report = action.options[i].key;
          break;
        }
      }

      for (let i in Types.timeOptions) {
        if (Types.timeOptions[i].key === action.data.timeframe) {
          timeframeIndex = i;
          timeframe = Types.timeOptions[i].key;
          break;
        }
      }

      for (let i in Types.frequencyOptions) {
        if (Types.frequencyOptions[i].key === action.data.frequency) {
          frequencyIndex = i;
          frequency = Types.frequencyOptions[i].key;
          break;
        }
      }

      for (let i in Types.hourOptions) {
        if (Types.hourOptions[i].key === action.data.hour) {
          hourIndex = i;
          hour = Types.hourOptions[i].key;
          break;
        }
      }

      for (let i in Types.weekOptions) {
        if (Types.weekOptions[i].key === Number(action.data.weekday)) {
          weekIndex = i;
          week = Types.weekOptions[i].key;
          break;
        }
      }

      for (let i in Types.dayOptions) {
        if (Types.dayOptions[i].key === action.data.day) {
          dayIndex = i;
          day = Types.dayOptions[i].key;
          break;
        }
      }

      return {
        ...state,
        editModal: {
          ...state.editModal,
          id: action.data.id,
          recipients: action.data.recipients,
          reportType: report,
          reportIndex: reportIndex,
          timeframe: timeframe,
          timeframeIndex: timeframeIndex,
          frequency: frequency,
          frequencyIndex: frequencyIndex,
          hour: hour,
          hourIndex: hourIndex,
          week: week,
          weekIndex: weekIndex,
          day: day,
          dayIndex: dayIndex,
        },
      };
    case Types.OPEN_EDIT_SCHEDULED_REPORT:
      return {
        ...state,
        editModal: {
          ...state.editModal,
          open: true,
        },
      };
    case Types.SCHEDULE_REPORT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.OPEN_SCHEDULE_REPORT_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          reportType: action.options[1].key,
          open: true,
        },
      };
    case Types.CLOSE_SCHEDULE_REPORT_MODAL:
      return {
        ...state,
        modal: {
          ...modalInit,
          open: false,
        },
      };
    case Types.CLOSE_EDIT_REPORT_MODAL:
      return {
        ...state,
        editModal: {
          ...modalInit,
          open: false,
        },
      };
    case Types.ON_CHANGE_RECIPIENTS:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          recipients: action.value,
        },
      };
    case Types.SELECT_SCHEDULE_REPORT_TYPE:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          reportType: action.options[action.index].key,
          reportIndex: action.index,
        },
      };
    case Types.SELECT_SCHEDULE_REPORT_TIMEFRAME:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          timeframe: Types.timeOptions[action.index].key,
          timeframeIndex: action.index,
        },
      };
    case Types.SELECT_SCHEDULE_REPORT_HOUR:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          hour: Types.hourOptions[action.index].key,
          hourIndex: action.index,
        },
      };
    case Types.SELECT_SCHEDULE_REPORT_DAY:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          day: Types.dayOptions[action.index].key,
          dayIndex: action.index,
        },
      };
    case Types.SELECT_SCHEDULE_REPORT_WEEK:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          week: Types.weekOptions[action.index].key,
          weekIndex: action.index,
        },
      };
    case Types.SELECT_SCHEDULE_REPORT_FREQUENCY:
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          frequency: Types.frequencyOptions[action.index].key,
          frequencyIndex: action.index,
        },
      };
    case Types.GET_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        reports: action.reports,
        lastSeen: action.lastSeen,
      };
    case Types.GET_REPORT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.CLEAR_RUN_REPORT:
      return {
        ...state,
        runReportQueue: [],
      };
    case Types.TOGGLE_ALL_SELECT_REPORT:
      let allReports = [...state.runReportQueue];
      if (!action.reports || allReports.length === action.reports.length) {
        allReports = [];
      } else {
        allReports = action.reports;
      }
      return {
        ...state,
        runReportQueue: allReports,
      };
    case Types.SELECT_RUN_REPORT:
      let reports = [...state.runReportQueue];
      let removed = false;
      reports = reports.filter(r => {
        if (r.id !== action.report.id) {
          return true;
        } else {
          removed = true;
          return false;
        }
      });
      if (!removed) {
        reports.push(action.report);
      }
      return {
        ...state,
        runReportQueue: reports,
      };
    case Types.RUN_REPORT:
      return {
        ...state,
        filters: {
          ...filterInit,
          rows: action.rows,
          index: action.index,
        },
        reportSelect: action.reportSelect,
        timeSelect: action.range,
      };
    case Types.SELECT_REPORT_TYPE:
      return {
        ...state,
        reportSelect: action.value,
        reportSelectIndex: action.index,
      };
    case Types.SELECT_REPORT_TIME_FILTER:
      return {
        ...state,
        timeSelect: action.value,
        timeSelectIndex: action.index,
      };
    case Types.SELECT_START_CUSTOM_REPORT_DATE:
      return {
        ...state,
        startCustom: action.date,
      };
    case Types.SELECT_END_CUSTOM_REPORT_DATE:
      return {
        ...state,
        endCustom: action.date,
      };
    case Types.REPORT_IN_PROGRESS:
      return {
        ...state,
        reportProgress: true,
      };
    case Types.REPORT_SUCCESS:
      return {
        ...state,
        genReport: action.data.reportType,
        timeLabel: action.data.timeLabel,
        reportProgress: false,
        report: true,
        graphData: action.data['graphData'],
        tableData: action.data['tableData'],
        total: action.data['total'],
      };
    case Types.REPORT_ERROR:
      return {
        ...state,
        reportProgress: false,
      };
    case Types.UPDATE_REPORT_ROW:
      return {
        ...state,
        filters: {
          ...state.filters,
          rows: action.rows,
          index: 1,
        },
      };
    case Types.REPORT_FIRST_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index: 1,
        },
      };
    case Types.REPORT_PREV_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index: --state.filters.index,
        },
      };
    case Types.REPORT_NEXT_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index: ++state.filters.index,
        },
      };
    case Types.REPORT_LAST_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index:
            Math.round(state.total / state.filters.rows) +
            (state.total % state.filters.rows > 0 ? 1 : 0),
        },
      };
    default:
      return state;
  }
};

function* runReport() {
  try {
    const store = yield select();
    yield put(Types.runningReport());
    const result = yield call(Api.getData, {
      page: 'reports',
      type: store.reports.reportSelect,
      range: store.reports.timeSelect,
      account_id: store.account.selected,
      startTime: new Date(store.reports.startCustom).getTime(),
      endTime: new Date(store.reports.endCustom).getTime(),
      rows: store.reports.filters.rows,
      index: store.reports.filters.index - 1,
    });
    yield put(
      Types.runReportSuccess({
        ...result,
        reportType: store.reports.reportSelect,
        timeLabel: store.reports.timeSelect,
      })
    );
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.runReportError(e.error));
  }
}

function* getReport() {
  try {
    yield put(Types.loadingReports());
    const result = yield call(Api.scheduleReports.read, {});
    yield put(Types.getReportSuccess(result.reports, result.lastSeen));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getReportFailure(e.error));
  }
}

function* runMultiReport() {
  try {
    const store = yield select();
    yield call(Api.scheduleReports.runReports, {
      method: 'run_multi_report',
      reports: store.reports.runReportQueue,
    });
    yield put(AppTypes.success(I18n.t('shared.reports.runSuccess')));
    yield put(Types.checkReportsTimestamp());
  } catch (e) {
    yield put(AppTypes.error(I18n.t('shared.reports.runFail')));
    yield put(Types.getReportFailure(e.error));
  }
}

function* checkReportsTimestamp() {
  try {
    const store = yield select();
    let lastSeenPendingList = store.reports.lastSeenPendingList;
    let reports = [];
    for (let i = 0; i < lastSeenPendingList.length; i++) {
      reports.push(lastSeenPendingList[i]['id']);
    }

    const result = yield call(Api.scheduleReports.runReports, {
      method: 'get_report_timestamps',
      reports: reports,
    });
    yield put(Types.checkReportsTimestampSuccess(result['lastSeen']));
  } catch (e) {
    yield put(Types.getReportFailure(e.error));
  }
}

function* checkReportsTimestampSuccess() {
  try {
    const updateStore = yield select();
    const lastSeenPendingList = updateStore.reports.lastSeenPendingList;
    if (lastSeenPendingList.length > 0) {
      yield call(delay, 5000);
      yield put(Types.checkReportsTimestamp());
    }
  } catch (e) {}
}

function* postReport() {
  try {
    const store = yield select();
    yield call(Api.scheduleReports.create, {
      method: 'create_schedule_report',
      timezone: store.account.time_zone,
      report_type: store.reports.modal.reportType,
      recipients: store.reports.modal.recipients,
      timeframe: store.reports.modal.timeframe,
      frequency: store.reports.modal.frequency,
      hour: store.reports.modal.hour,
      day: store.reports.modal.day,
      weekday: store.reports.modal.week,
    });
    yield getReport();
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getReportFailure(e.error));
  }
}

function* editReport() {
  try {
    const store = yield select();
    yield call(Api.scheduleReports.replace, {
      method: 'replace_schedule_reports',
      id: store.reports.editModal.id,
      timezone: store.account.time_zone,
      report_type: store.reports.editModal.reportType,
      recipients: store.reports.editModal.recipients,
      timeframe: store.reports.editModal.timeframe,
      frequency: store.reports.editModal.frequency,
      hour: store.reports.editModal.hour,
      day: store.reports.editModal.day,
      weekday: store.reports.editModal.week,
    });
    yield getReport();
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getReportFailure(e.error));
  }
}

function* deleteReport() {
  try {
    const store = yield select();
    yield call(Api.scheduleReports.delete, store.reports.editModal.id, {
      id: store.reports.editModal.id,
      method: 'remove_schedule_reports',
    });
    yield getReport();
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getReportFailure(e.error));
  }
}

function* downloadPdf() {
  try {
    const store = yield select();
    const params = {
      type: store.reports.pdf.reportType,
      range: store.reports.pdf.range,
      timezone: store.reports.pdf.timezone,
      name: store.reports.pdf.name,
      title: store.reports.pdf.title,
      rangeText: store.reports.pdf.rangeText,
      account_id: store.account.selected,
    };

    yield call(Api.downloadReport, params);
    yield put(Types.downloadPdfSuccess());
  } catch (e) {
    console.log('the error: ', e);
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
    yield put(Types.downloadPdfFailure(e));
  }
}

function* downloadCsv() {
  try {
    const store = yield select();
    const params = {
      account_id: store.account.selected,
      job_type: `${store.reports.csv.reportType}_csv`,
      range: store.reports.csv.range,
      timezone: store.account.timezone,
    };

    const accountID = store.account.selected;
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(Api.csv.download, result.presignedURL, 'BCS Report.csv');
        break;
      }
    }

    yield put(Types.downloadCsvSuccess());
  } catch (e) {
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
    yield put(Types.downloadCsvFailure(e));
  }
}

export function* reportsReducerFlow() {
  yield takeLatest(Types.GET_SCHEDULE_REPORTS, getReport);
  yield takeLatest(Types.CREATE_REPORT, postReport);
  yield takeLatest(Types.EDIT_SCHEDULED_REPORT, editReport);
  yield takeLatest(Types.DELETE_SCHEDULED_REPORT, deleteReport);
  yield takeLatest(Types.RUN_REPORT, runReport);
  yield takeLatest(Types.UPDATE_REPORT_ROW, runReport);
  yield takeLatest(Types.REPORT_FIRST_PAGE, runReport);
  yield takeLatest(Types.REPORT_PREV_PAGE, runReport);
  yield takeLatest(Types.REPORT_NEXT_PAGE, runReport);
  yield takeLatest(Types.REPORT_LAST_PAGE, runReport);
  yield takeLatest(Types.RUN_MULTI_REPORT, runMultiReport);
  yield takeLatest(Types.CHECK_REPORTS_TIMESTAMP, checkReportsTimestamp);
  yield takeLatest(
    Types.CHECK_REPORTS_TIMESTAMP_SUCCESS,
    checkReportsTimestampSuccess
  );
  yield takeLatest(Types.REPORT_DOWNLOAD_PDF, downloadPdf);
  yield takeLatest(Types.REPORT_DOWNLOAD_CSV, downloadCsv);
}
