import React, { Component } from 'react';
import './App.css';
import { Switch, BrowserRouter, Redirect, Route } from 'react-router-dom';
import Main from './Main';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import Auth from './lib/Auth';
import { Provider } from 'react-redux';
import Authenticator from './containers/Authenticator';
import Theme from './Theme';

import Activate from './containers/Activate';
import Login from './containers/Login';
import ViewReport from './containers/ViewReport';
import GenerateReport from './containers/GenerateReport';
import StartTrial from './containers/signup/StartTrial';
import Signup from './containers/signup/Signup';

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={Theme}>
        <Provider store={this.props.store}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/generate_report" component={GenerateReport} />
              <Route
                exact
                path="/view_report/:report/:range/:rows/:index"
                component={ViewReport}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/login/:error" component={Login} />
              <Route
                exact
                path="/trial"
                render={props =>
                  Auth.checkOnce() ? (
                    <StartTrial />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/authenticator',
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
              <Route path="/signup" component={Signup} />
              <Route exact path="/authenticator" component={Authenticator} />
              <Route
                exact
                path="/activate"
                render={props =>
                  Auth.checkOnce() ? (
                    <Activate />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/authenticator',
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
              <PrivateRoute component={Main} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      Auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/authenticator',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default App;
