import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';

import EPSTableBody from '../table/TableBody';
import CudaTableTitle from '../CudaTableTitle';

import { BLOCK } from '../../actions/basic_filtering';

class ExceptionTable extends Component {
  state = {
    orderBy: 'domain',
    order: 'asc',
  };

  sortHandler = field => {
    const orderBy = field;
    let order = 'desc';

    if (this.state.orderBy === field && this.state.order === 'desc') {
      order = 'asc';
    }

    this.props.sortHandler(orderBy, order);
    this.setState({ order, orderBy });
  };

  render() {
    const { classes, data, editSelected } = this.props;
    const { orderBy, order } = this.state;

    return (
      <Table data-test-id="exceptionTable">
        <TableHead>
          <TableRow>
            <TableCell data-test-id="exceptionColumn">
              <CudaTableTitle value="components.basicFiltering.exception" />
            </TableCell>
            <TableCell data-test-id="domainColumn">
              <CudaTableTitle
                data-test-id="domainSort"
                value="components.basicFiltering.domain"
                sort
                toggle={orderBy === 'domain' ? order : 'none'}
                onClick={() => {
                  this.sortHandler('domain');
                }}
              />
            </TableCell>
            <TableCell data-test-id="removeColumn">
              <CudaTableTitle value="components.basicFiltering.remove" />
            </TableCell>
          </TableRow>
        </TableHead>
        <EPSTableBody
          data-test-id="exceptionTableBody"
          loading={false}
          colCount={3}
          rowCount={data.length}
          lid="components.basicFiltering.noExceptions"
        >
          {data.map((exception, i) =>
            exception.hide ? (
              undefined
            ) : (
              <TableRow
                data-test-id={`exceptionRow${i}`}
                key={exception.domain}
              >
                <TableCell className={classes.cell}>
                  {exception.action === BLOCK ? (
                    <div className={classes.exceptionCell}>
                      <BlockIcon />
                      <Translate value="components.basicFiltering.blockTraffic" />
                    </div>
                  ) : (
                    <div className={classes.exceptionCell}>
                      <DoneIcon />
                      <Translate value="components.basicFiltering.allowTraffic" />
                    </div>
                  )}
                </TableCell>
                <TableCell className={classes.cell}>
                  {exception.domain}
                </TableCell>
                <TableCell>
                  <IconButton
                    id={`exception-delete${i}`}
                    aria-label="View"
                    aria-owns={null}
                    aria-haspopup="true"
                    color="inherit"
                    classes={{ colorInherit: classes.delete }}
                    onClick={() =>
                      this.props.deleteHandler(exception, editSelected)
                    }
                  >
                    <DeleteIcon style={{ height: '24px', width: '24px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            )
          )}
        </EPSTableBody>
      </Table>
    );
  }
}
ExceptionTable.propTypes = {
  classes: PropTypes.object.isRequired,
  editSelected: PropTypes.string.isRequired,
  sortHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string,
      action: PropTypes.string,
    })
  ).isRequired,
};

const styles = {
  cell: {
    fontSize: '14px',
  },
  exceptionCell: {
    '& > svg': {
      height: '24px',
      width: '24px',
      float: 'left',
    },
    '& > span': {
      lineHeight: '24px',
      marginLeft: '8px',
      float: 'left',
    },
  },
  delete: {
    color: '#DD1E31',
  },
};

export default withStyles(styles, { name: 'ExceptionTable' })(ExceptionTable);
