import { call, put, takeEvery, select, all } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { I18n } from 'react-i18nify';
import * as Types from '../actions/activate';
import Api from './Api';
import Auth from '../lib/Auth';
import {
  updateSubscriptions,
  storeSubscriptions,
  storeSerials,
} from '../actions/account';

export default (
  state = {
    processing: false,
    info: {},
    filled: false,
    result: '',
    error: '',
    sso: {
      accounts: [],
    },
  },
  action
) => {
  switch (action.type) {
    case Types.LINK_SUCCESS:
      return {
        ...state,
        processing: false,
        result: 'success',
        error: '',
      };
    case Types.LINK_FAILURE:
      return {
        ...state,
        processing: false,
        result: 'failure',
        message: 'Failed to activate',
        error: action.error,
      };
    case Types.LINK_PURCHASE:
      return {
        ...state,
        message: 'Activating',
        processing: true,
        error: '',
      };
    case Types.CLEAR_INFO:
      return {
        ...state,
        filled: false,
        info: action.info,
      };
    case Types.SAVE_INFO:
      return {
        ...state,
        filled: true,
        info: action.info,
      };
    case Types.ACTIVATE_STORE_SSO_TOKEN:
      return {
        ...state,
        sso: action.dtoken,
      };
    default:
      return state;
  }
};

function* checkSubscriptions(accountId, serial, sso) {
  for (let i = 0; i < 24; i += 1) {
    try {
      const apiResponse = yield call(Api.accounts.subscriptions, accountId, {
        serial,
        sso,
      });
      return apiResponse;
    } catch (err) {
      if (i < 24) {
        yield call(delay, 10000);
      }
    }
  }
  throw new Error('Failed to activate');
}

function* linkPurchase(action) {
  try {
    const store = yield select();
    const token = Auth.get_refresh_token();

    const result = yield call(
      Api.accounts.activate,
      store.activate.info.accountId,
      {
        ...store.activate.info,
        sso: Auth.get_sso_token(false),
      }
    );
    if (result.timeout) {
      yield call(
        checkSubscriptions,
        store.activate.info.accountId,
        store.activate.info.serial,
        Auth.get_sso_token(false)
      );
    }

    yield put(Types.linkSuccess({ ...result }));
    const response = yield call(Api.auth.refresh, token);
    Auth.setup_sso(response.sso.id_token, response.sso.refresh_token);
    Auth.store_token(response.jwe);
    yield all([
      put(updateSubscriptions(response.subscriptions, response.account_subs)),
      put(storeSubscriptions(response.subscriptions, response.account_subs)),
      put(storeSerials(response.serials)),
    ]);

    action.history.push('/route_view');
  } catch (e) {
    // yield put(AppTypes.error(I18n.t('components.activate.linkFailure')));
    yield put(
      Types.linkFailure({
        error: I18n.t('components.activate.linkFailure'),
      })
    );
  }
}

export function* activateReducerFlow() {
  yield takeEvery(Types.LINK_PURCHASE, linkPurchase);
}
