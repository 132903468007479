import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { BCSIcon, UserIcon, ExpandArrowIcon } from './icons';
import { Translate } from 'react-i18nify';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';

import ActivateModal from '../containers/header/ActivateModal';

const styles = {
  wrapper: {
    height: '55px',
    background: '#444444',
    boxShadow: '0 1px 0 0 #DDDDDD',
    color: '#FFF',
    display: 'flex',
  },
  bcsIcon: {
    height: '55px',
    width: '55px',
  },
  productSection: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: '1',
    padding: '0 0 0 15px',
  },
  productName: {
    flexGrow: '2',
  },
  gutter: {
    display: 'flex',
    flexGrow: '10',
  },
  account: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '5px',
    width: '75px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  separator: {
    width: '1px',
    height: '90%',
    background: '#555555',
  },
  dropdown: {
    listStyle: 'none',
    margin: '0',
    padding: '0',
    zIndex: '999999',
    backgroundColor: '#FAFAFA',
    color: '#333333',
    position: 'absolute',
    top: '55px',
    left: '0',
    fontSize: '14px',
    lineHeight: '40px',
    marginRight: '10px',
    '& svg': {
      margin: '10px',
    },
    fontWeight: 200,
    '& a': {
      textDecoration: 'none',
      color: '#333333',
    },
    '& li:hover': {
      backgroundColor: '#EEEEEE',
      cursor: 'pointer',
    },
    '& div.header-btn-item': {
      padding: '2px 7px 0 15px',
      display: 'flex',
      alignItems: 'center',
      '& img': {
        height: '35px',
      },
      '& span': {
        marginLeft: '15px',
      },
    },
  },
  hide: {
    display: 'none',
  },
  accountBtnItem: {
    height: '37px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    lineHeight: '40px',
    paddingRight: '15px',
    paddingLeft: '15px',
    '& svg': {
      margin: '10px',
    },
    fontWeight: 200,
  },
  language: {
    marginLeft: 'auto',
    fontWeight: 500,
  },
  divider: {
    height: '1px',
    backgroundColor: '#DBDBDB',
  },
  trial: {
    border: '1px solid #555555',
    margin: '10px',
    padding: '0px',
    fontSize: '15px',
    lineHeight: '33px',
    '& > span': {
      marginLeft: '16px',
    },
  },
  trialButton: {
    marginLeft: '10px',
    color: '#FFFFFF',
    minHeight: '33px',
    height: '33px',
  },
};

class HeaderComponent extends Component {
  state = {
    anchorEl: null,
    open: false,
    rectTop: '',
    rectLeft: '',
    rectWidth: '',
    productSelected: false,
    accountTop: '',
    accountLeft: '',
    accountWidth: '',
    accountSelected: false,
    showActivateModal: false,
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  _dropdownOffset(el, dropdown) {
    var rect = el.getBoundingClientRect();
    dropdown.style.top = rect.height + rect.top + 'px';
    dropdown.style.left = rect.left + 'px';
    dropdown.style.width = rect.width + 'px';
  }

  productSelect = event => {
    const rect = document
      .getElementById('productSection')
      .getBoundingClientRect();
    this.setState({
      rectTop: rect.height + rect.top + 'px',
      rectLeft: rect.left + 'px',
      rectWidth: rect.width + 'px',
      productSelected: !this.state.productSelected,
    });
  };

  handleAccount = event => {
    const rect = document
      .getElementById('accountSection')
      .getBoundingClientRect();
    this.setState({
      accountTop: rect.height + rect.top + 'px',
      accountLeft: rect.left + 'px',
      accountWidth: rect.width + 100 + 'px',
      accountSelected: !this.state.accountSelected,
    });
  };

  showActivate = event => this.setState({ showActivateModal: true });
  hideActivate = event => this.setState({ showActivateModal: false });

  render() {
    const { logout, classes, trial } = this.props;
    const { showActivateModal } = this.state;

    const icon = {
      height: '10px',
      width: '10px',
      float: 'right',
      margin: '3px 10px 0 10px',
    };
    const dropdown = {
      display: this.state.productSelected ? 'block' : 'none',
      top: this.state.rectTop,
      left: this.state.rectLeft,
      width: this.state.rectWidth,
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
    };

    const product = {
      background: this.state.productSelected ? '#FAFAFA' : '',
      color: this.state.productSelected ? '#333333' : '',
    };

    const account = {
      background: this.state.accountSelected ? '#FAFAFA' : '',
      color: this.state.accountSelected ? '#333333' : '#FFFFFF',
    };

    const accountDropdown = {
      boxShadow: '0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24)',
      display: this.state.accountSelected ? 'block' : 'none',
      top: this.state.accountTop,
      right: 0,
      left: 'auto',
      width: '170px',
      margin: 0,
    };

    return (
      <div data-test-id="cudaHeader" className={classes.wrapper}>
        <ActivateModal
          showModal={showActivateModal}
          closeModal={this.hideActivate}
        />
        <BCSIcon className={classes.bcsIcon} />
        <div
          id="productSection"
          style={product}
          className={classes.productSection}
        >
          <div data-test-id="productName" className={classes.productName}>
            <Translate value="shared.productName" />
          </div>
          <ul className={classes.dropdown} style={dropdown}>
            <li>
              <Button>
                <div className="header-btn-item">
                  <img src="" alt="Logo" />
                  <span>
                    <Translate value="shared.productName" />
                  </span>
                </div>
              </Button>
            </li>
          </ul>
          <div className={classes.separator} style={product} />
        </div>
        <div className={classes.gutter}>
          {trial && (
            <div className={classes.trial} data-test-id="free-trial-wrapper">
              <Translate value="header.trial" data-test-id="free-trial-txt" />
              <Button
                data-test-id="activate-trial-btn"
                className={classes.trialButton}
                onClick={this.showActivate}
              >
                <Translate value="header.activate" />
              </Button>
            </div>
          )}
        </div>
        <div
          data-test-id="accountSection"
          id="accountSection"
          className={classes.account}
          style={account}
          onClick={event => this.handleAccount(event)}
        >
          <UserIcon color="inherit" />
          <ExpandArrowIcon style={icon} color="inherit" viewBox="0 0 9 9" />
        </div>
        <ul className={classes.dropdown} style={accountDropdown}>
          <li>
            <NavLink
              data-test-id="navlink"
              to={{
                pathname: '/account_settings',
                state: { from: this.props.history.location.pathname },
              }}
              onClick={event => {
                this.handleAccount(event);
              }}
            >
              <div className={classes.accountBtnItem}>
                <span>
                  <Translate value="shared.accountSettings" />
                </span>
              </div>
            </NavLink>
          </li>
          <li>
            <div
              data-test-id="logOut"
              className={classes.accountBtnItem}
              onClick={event => {
                logout();
                this.handleAccount(event);
              }}
            >
              <span>
                <Translate value="shared.signOut" />
              </span>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

const Header = withStyles(styles, { name: 'Header' })(
  withRouter(HeaderComponent)
);

export default Header;
