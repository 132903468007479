import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, I18n } from 'react-i18nify';

const SpecialValues = ({ classes }) => (
  <React.Fragment>
    <Translate
      value="blockPage.specialValues.subheader"
      tag="div"
      className={classes.subheader}
    />
    <div className={classes.specialValue}>
      %d
      <Translate
        value="blockPage.specialValues.d"
        tag="div"
        className={classes.valueText}
      />
    </div>
    <div className={classes.specialValue}>
      %t
      <Translate
        value="blockPage.specialValues.t"
        tag="div"
        className={classes.valueText}
      />
    </div>
    {/*<div className={classes.specialValue}>
      %tq
      <Translate
        value="blockPage.specialValues.tq"
        tag="div"
        className={classes.valueText}
      />
    </div>
    <div className={classes.specialValue}>
      %z
      <Translate
        value="blockPage.specialValues.z"
        tag="div"
        className={classes.valueText}
      />
    </div>*/}
  </React.Fragment>
);

const styles = {
  subheader: {
    color: '#7F7F7F',
    fontSize: '12px',
    lineHeight: '16px',
    marginBottom: '24px',
  },
  specialValue: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '16px',
    fontWeight: 500,
    fontSize: '14px',
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  valueText: {
    flexBasis: '60%',
    fontSize: '12px',
    fontWeight: 400,
  },
};

SpecialValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'SpecialValues' })(SpecialValues);
