import React, { Component } from 'react';
import { Translate, I18n } from 'react-i18nify';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import logo from '../logo-barracuda-dark.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import DocumentTitle from 'react-document-title';
import PasswordInput from './PasswordInput';
import { withRouter } from 'react-router';

import { BCSIcon } from './icons';

import Background from '../login-bg-img.jpg';

class LoginComponent extends Component {
  render() {
    const { classes, login, onClick, history } = this.props;
    const upperClass = classNames(classes.right, classes.upper);
    const lowerClass = classNames(classes.right, classes.lower);
    const submitButton = classNames(classes.button, classes.submitButton);

    let errorMsg;
    if (
      (this.props.match.params.error &&
        this.props.match.params.error === '401') ||
      (login.error && login.errorCode === 401)
    ) {
      errorMsg = I18n.t('components.login.unauthorized');
    } else if (
      (this.props.match.params.error &&
        this.props.match.params.error === '403') ||
      (login.error && login.errorCode === 403)
    ) {
      errorMsg = login.error;
    } else if (login.error) {
      errorMsg = I18n.t('components.login.error');
    }

    return (
      <DocumentTitle title={I18n.t('components.login.title')}>
        <Grid
          spacing={0}
          className={classes.container}
          id="atp-dashboard"
          container
        >
          <Grid
            className={classes.background}
            item
            xs={12}
            sm={6}
            md={8}
            lg={9}
          />
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            className={classes.rightContainer}
          >
            <div className={upperClass}>
              <BCSIcon className={classes.bcsIcon} />
              <div
                className={classes.error}
                style={{ visibility: errorMsg ? 'visible' : 'hidden' }}
              >
                <ErrorIcon className={classes.errorIcon} />
                <span className={classes.errorText}>{errorMsg}</span>
              </div>
              <form
                onSubmit={event => {
                  onClick(this.username.value, this.password.value, history);
                  event.preventDefault();
                }}
              >
                <div>
                  <PasswordInput
                    className={classes.input}
                    label={I18n.t('components.login.email')}
                    name="username"
                    fullWidth={true}
                    type="text"
                    error={login.error}
                    inputRef={input => {
                      this.username = input;
                    }}
                  />
                </div>
                <div style={{ marginTop: '18px' }}>
                  <PasswordInput
                    className={classes.input}
                    label={I18n.t('shared.password')}
                    name="password"
                    fullWidth={true}
                    error={login.error}
                    inputRef={input => {
                      this.password = input;
                    }}
                    type="password"
                  />
                </div>
                {login.isProcessing && (
                  <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progress} size={24} />
                    <Typography type="body1" className={classes.progressText}>
                      {I18n.t('components.login.signingIn')}
                    </Typography>
                  </div>
                )}
                {!login.isProcessing && (
                  <Button
                    id="login-btn"
                    variant="contained"
                    type="submit"
                    className={submitButton}
                    color="primary"
                  >
                    {I18n.t('components.login.signIn')}
                  </Button>
                )}
              </form>
              <Typography className={classes.privacy} type="caption">
                <Translate
                  value="components.login.policyText"
                  dangerousHTML
                  link="https://www.barracuda.com/company/legal/privacy"
                  linkClass={classes.link}
                />
              </Typography>
            </div>
            <div className={lowerClass}>
              <Button
                data-test-id="btn-trial"
                color="primary"
                variant="contained"
                onClick={() => this.props.history.push('/trial')}
                style={{
                  backgroundColor: '#1AB9F4',
                  margin: 0,
                }}
              >
                <Translate value="components.login.trial" />
              </Button>
              <Typography className={classes.status} type="caption">
                <img src={logo} className={classes.logo} alt="logo" />
              </Typography>
            </div>
          </Grid>
        </Grid>
      </DocumentTitle>
    );
  }
}

const styles = theme => ({
  container: {
    minHeight: '600px',
    alignItems: 'stretch',
    height: '100%',
    marginBottom: 0,
    backgroundColor: '#565456',
  },
  rightContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  right: {
    boxSizing: 'border-box',
    position: 'relative',
    padding: '35px 35px 0 35px',
  },
  upper: {
    backgroundColor: '#FFFFFF',
    flex: '0 0 425px',
    height: '55%',
  },
  lower: {
    flex: '1 0',
    backgroundColor: '#F7F7F7',
    paddingTop: '25px',
  },

  input: {
    marginBottom: '10px',
  },
  button: {
    margin: '20px 0',
    padding: '8px',
  },
  submitButton: {
    width: '50%',
  },
  link: {
    color: '#0088CF',
    textDecoration: 'none',
  },

  caption: {
    color: '#777777',
  },
  privacy: {
    color: '#777777',
    position: 'absolute',
    bottom: '10px',
  },
  status: {
    position: 'absolute',
    bottom: '15px',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  logo: {
    position: 'absolute',
    left: 'auto',
    right: '15px',
    bottom: '15px',
    width: '150px',
  },

  background: {
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
  },
  progress: {
    color: 'rgba(0,0,0,0.7)',
  },
  progressContainer: {
    padding: '28px 0',
    height: '16px',
  },
  progressText: {
    display: 'inline-block',
    marginLeft: '8px',
    verticalAlign: 'top',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.7)',
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFDDE0',
    padding: '6px',
    marginBottom: '20px',
    marginTop: '-10px',
  },
  errorText: {
    color: '#333333',
    lineHeight: '15px',
    fontSize: '13px',
    marginLeft: '10px',
  },
  errorIcon: {
    color: theme.statusColors.failure,
  },

  bcsIcon: {
    height: '55px',
    width: '55px',
  },
});

export default withStyles(styles)(withRouter(LoginComponent));
