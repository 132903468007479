import { connect } from 'react-redux';
import StartTrial from '../../components/signup/StartTrial';

const mapStateToProps = state => {
  const page =
    state.signup.sso &&
    state.signup.sso.accounts &&
    state.signup.account &&
    state.signup.sso.accounts.length === 1
      ? 2
      : state.signup.page;

  return {
    sso: state.signup.sso && state.signup.accounts.length,
    login: state.signup.login,
    page,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(StartTrial);
