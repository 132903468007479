import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';

import SelectionInput from '../../components/advanced_filtering/SelectionInput';

import { ACTION_OPTIONS, selectAction } from '../../actions/advanced_filtering';

const mapStateToProps = state => ({
  name: <Translate value="components.advancedFiltering.action" />,
  options: ACTION_OPTIONS,
  selectedIndex: state.advanced_filtering.ruleModal.selectedActionIndex,
  helpText: (
    <Translate
      value="components.advancedFiltering.actionHelpText"
      dangerousHTML
    />
  ),
  tooltipTestId: 'rule-action',
});

const mapDispatchToProps = dispatch => ({
  onChange: index => {
    dispatch(selectAction(index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionInput);
