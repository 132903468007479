import { connect } from 'react-redux';
import Rulesets from '../../components/advanced_filtering/Rulesets';
import {
  openAddRule,
  deleteRulesetRule,
} from '../../actions/advanced_filtering';

import { getFilteredRuleset } from '../../selectors/advanced_filtering';

const mapStateToProps = state => ({
  processing: state.advanced_filtering.processing,
  ruleset: getFilteredRuleset(state),
  adMap: state.account.adMap,
  search: state.advanced_filtering.search.match,
});

const mapDispatchToProps = dispatch => ({
  openAddRule: selectedAD => {
    dispatch(openAddRule(selectedAD, true));
  },
  deleteRulesetRule: rule_id => {
    dispatch(deleteRulesetRule(rule_id));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Rulesets);
