export const INIT_DEVICES_LIST = 'INIT_DEVICES_LIST';
export const DEVICES_LOADING = 'DEVICES_LOADING';
export const GET_DEVICES_DATA_SUCCESS = 'GET_DEVICES_DATA_SUCCESS';
export const GET_DEVICES_DATA_FAILURE = 'GET_DEVICES_DATA_FAILURE';
export const DEVICES_CHANGE_TIME = 'DEVICES_CHANGE_TIME';

export const initDevicesList = () => ({
  type: INIT_DEVICES_LIST,
});

export const deviceLoading = () => ({
  type: DEVICES_LOADING,
});

export const getDeviceListSuccess = result => {
  let last_threat = [];
  let agent_threats = {};

  if (result.agent_threats.aggregations) {
    last_threat = result.agent_threats.aggregations.last_threat.buckets;
  }

  const agent_info = result.agent_info.map(agent => ({
    key: agent.key,
    name: agent.last_status.hits.hits[0]._source.device_name,
    version: agent.last_status.hits.hits[0]._source.agent_version,
    os_version: agent.last_status.hits.hits[0]._source.os_version,
  }));
  for (let threat of last_threat) {
    agent_threats[threat.key] = {
      count: threat.doc_count,
      last_threat: threat.last_file.hits.hits[0]._source.timestamp,
    };
  }

  return {
    type: GET_DEVICES_DATA_SUCCESS,
    agent_info,
    agent_threats,
  };
};

export const getDeviceListFailure = result => ({
  type: GET_DEVICES_DATA_FAILURE,
  result,
});

export const changeTimeframe = (time, index) => ({
  type: DEVICES_CHANGE_TIME,
  time,
  index,
});
