import { createSelector } from 'reselect';
import { I18n } from 'react-i18nify';

import { getADUserHash, getUserList } from './account';
import { getCategoryList, getSupercategoryList } from './categories';
import { getSortedLocations } from './basic_filtering';

const getCategories = state => state.basic_filtering.catMapping.categories;
const getSupercategories = state =>
  state.basic_filtering.catMapping.supercategories;
const getSelected = state => state.account.selected;
const getTimezone = state => state.account.time_zone;
const getWFLogsState = state => state.wfLogs;

const getQueryLabels = createSelector(
  [getCategories, getSupercategories, getSelected, getWFLogsState, getTimezone],
  (categories, supercategories, selected, wfLogs, timezone) => {
    const cats = [];
    Object.keys(categories).forEach(key => {
      cats.push({
        key: parseInt(key, 10),
        label: categories[key].display.toLowerCase(),
      });
    });

    const supercats = [];
    Object.keys(supercategories).forEach(supercat => {
      supercats.push({
        key: parseInt(supercat, 10),
        label: supercategories[supercat].toLowerCase(),
      });
    });

    const locations = [];
    const locs = wfLogs.locations;
    Object.keys(locs).forEach(location => {
      locations.push({
        key: location,
        label: locs[location].toLowerCase(),
      });
    });

    return {
      cats,
      supercats,
      locations,
      selected,
      wfLogs,
      timezone,
    };
  }
);

const getQueryFieldValues = createSelector(
  [
    getCategoryList,
    getSupercategoryList,
    getUserList,
    getSortedLocations,
    getADUserHash,
  ],
  (categories, supercategories, users, locations, usersHash) => ({
    users,
    locations,
    usersHash,
    categories,
    supercategories,
    actions: [
      {
        value: 'ALLOWED',
        label: I18n.t('components.wfLogs.allowed'),
      },
      {
        value: 'DENIED',
        label: I18n.t('components.wfLogs.denied'),
      },
    ],
  })
);

const getWfLogs = createSelector(
  [getWFLogsState],
  wfLogs => ({
    wfLogs,
  })
);

export { getQueryLabels, getWfLogs, getQueryFieldValues };
