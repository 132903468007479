import { connect } from 'react-redux';
import CudaMenu from '../../components/CudaMenu';
import { getCategoryList } from '../../selectors/notifications';

const mapStateToProps = (state, ownProps) => {
  const options = getCategoryList(state);
  const selectedIndex = options.findIndex(
    option => option.key === ownProps.category
  );

  return {
    options: getCategoryList(state),
    selectedIndex: selectedIndex >= 0 ? selectedIndex : 0,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(CudaMenu);
