import * as Types from '../actions/atplogs';
import * as AppTypes from '../actions/app';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import Api from './Api';
import { I18n } from 'react-i18nify';

const initState = {
  logs: [],
  filters: {
    index: 1,
    rows: 10,
    sort: 'date',
    order: 'desc',
  },
  total: 0,
  query: '',
  loading: false,
  pdfCuid: '',
  csv: {
    loading: false,
    range: '',
  },
};

export const atplogs = (state = initState, action) => {
  switch (action.type) {
    case Types.ATP_LOGS_DOWNLOAD_PDF:
      return {
        ...state,
        pdfCuid: action.cuid,
      };
    case Types.ATP_LOGS_DOWNLOAD_PDF_SUCCESS:
    case Types.ATP_LOGS_DOWNLOAD_PDF_FAILURE:
      return {
        ...state,
        pdfCuid: '',
      };
    case Types.ATP_LOGS_DOWNLOAD_CSV:
      return {
        ...state,
        csv: {
          ...state.csv,
          range: action.range,
          loading: true,
        },
      };
    case Types.ATP_LOGS_DOWNLOAD_CSV_SUCCESS:
    case Types.ATP_LOGS_DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        csv: {
          ...state.csv,
          range: '',
          loading: false,
        },
      };
    case Types.ATP_LOG_RESET:
      return initState;
    case Types.ATP_LOGS_LOADING:
    case Types.INIT_LOGS:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_ATP_LOGS_SUCCESS:
      return {
        ...state,
        logs: action.result.hits || [],
        total: action.result.total,
        loading: false,
      };
    case Types.UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
      };
    case Types.UPDATE_ROWS:
      return {
        ...state,
        filters: {
          ...state.filters,
          index: 1,
          rows: action.rows,
        },
      };
    case Types.ATP_LOG_LAST_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index:
            Math.round(state.total / state.filters.rows) +
            (state.total % state.filters.rows > 0 ? 1 : 0),
        },
      };
    case Types.ATP_LOG_FIRST_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index: 1,
        },
      };
    case Types.NEXT_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index: state.filters.index + 1,
        },
      };
    case Types.PREV_PAGE:
      return {
        ...state,
        filters: {
          ...state.filters,
          index: state.filters.index - 1,
        },
      };
    case Types.UPDATE_SORT:
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: action.column,
          order: action.order,
        },
      };
    default:
      return state;
  }
};

function* initLogs() {
  try {
    const store = yield select();
    const result = yield call(Api.getLogs, {
      ...store.atplogs.filters,
      range: store.atp_shared.range,
      query: store.atplogs.query,
      account_id: store.account.selected,
      index: store.atplogs.filters.index - 1,
    });
    yield put(Types.getATPLogSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getATPLogFailure(e.error));
  }
}

function* fetchLogs() {
  try {
    const store = yield select();
    const result = yield call(Api.getLogs, {
      ...store.atplogs.filters,
      range: store.atp_shared.range,
      query: store.atplogs.query,
      account_id: store.account.selected,
      index: store.atplogs.filters.index - 1,
    });
    yield put(Types.getATPLogSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getATPLogFailure(e.error));
  }
}

function* queryLogs() {
  yield call(delay, 500);
  yield put(Types.logsLoading());
  yield fetchLogs();
}

function* downloadPdf() {
  try {
    const store = yield select();
    const params = {
      cuid: store.atplogs.pdfCuid,
      type: 'atp-report',
      account_id: store.account.selected,
    };

    yield call(Api.downloadReport, params);
    yield put(Types.downloadPdfSuccess());
  } catch (e) {
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
    yield put(Types.downloadPdfFailure(e));
  }
}

function* downloadCsv() {
  try {
    const store = yield select();
    const params = {
      job_type: 'atp_logs_csv',
      range: store.atplogs.csv.range,
      timezone: store.account.timezone,
    };

    const accountID = store.account.selected;
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(
          Api.csv.download,
          result.presignedURL,
          'BCS Threat Logs.csv'
        );
        break;
      }
    }

    yield put(Types.downloadCsvSuccess());
  } catch (e) {
    yield put(AppTypes.error(I18n.t('errors.downloadFailed')));
    yield put(Types.downloadCsvFailure(e));
  }
}

export function* atpLogsReducerFlow() {
  yield takeEvery(Types.INIT_LOGS, initLogs);
  yield takeLatest(Types.UPDATE_QUERY, queryLogs);
  yield takeLatest(Types.UPDATE_ROWS, queryLogs);
  yield takeLatest(Types.ATP_LOG_LAST_PAGE, queryLogs);
  yield takeLatest(Types.NEXT_PAGE, queryLogs);
  yield takeLatest(Types.PREV_PAGE, queryLogs);
  yield takeLatest(Types.ATP_LOG_FIRST_PAGE, queryLogs);
  yield takeLatest(Types.UPDATE_RANGE, queryLogs);
  yield takeLatest(Types.UPDATE_SORT, queryLogs);
  yield takeEvery(Types.ATP_LOGS_DOWNLOAD_PDF, downloadPdf);
  yield takeEvery(Types.ATP_LOGS_DOWNLOAD_CSV, downloadCsv);
}
