import React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'react-i18nify';
import PropTypes from 'prop-types';
import { CudaDialogTitle, ActionButton } from '../CudaDialog';

import CircularProgress from '@material-ui/core/CircularProgress';

const EditModal = ({
  classes,
  showModal,
  closeDialog,
  buttonAction,
  children,
  title,
  closeBtnText,
  saveBtnText,
  disabled,
  processing,
  contentClass,
  name,
  padding,
  actionBtnClass,
  overflow,
  error,
}) => (
  <Dialog
    open={showModal}
    maxWidth={false}
    onEscapeKeyDown={closeDialog}
    className={classes.dialog}
    classes={
      overflow && {
        paper: classes.overflow,
        root: classes.overflow,
      }
    }
  >
    <CudaDialogTitle onClose={closeDialog} data-test-id={`${name}-modal-title`}>
      {title}
    </CudaDialogTitle>
    <DialogContent
      classes={
        overflow && {
          root: classes.overflow,
        }
      }
    >
      <div
        className={classNames(
          classes.content,
          { [classes.padding]: padding },
          contentClass
        )}
        data-test-id={`${name}-modal-content`}
      >
        {children}
      </div>
    </DialogContent>
    <DialogActions>
      {error && <div className={classes.error}>{error}</div>}
      <div data-test-id={`${name}-modal-actions`}>
        <ActionButton
          variant="contained"
          color="secondary"
          onClick={closeDialog}
          disabled={processing}
          data-test-id={`${name}-cancel`}
        >
          {closeBtnText}
        </ActionButton>
        {buttonAction && (
          <ActionButton
            variant="contained"
            className={actionBtnClass}
            color="primary"
            data-test-id={`${name}-save`}
            disabled={disabled || processing}
            onClick={buttonAction}
          >
            {processing ? (
              <CircularProgress className={classes.progress} size={16} />
            ) : (
              saveBtnText
            )}
          </ActionButton>
        )}
      </div>
    </DialogActions>
  </Dialog>
);

const styles = theme => ({
  content: {
    maxWidth: '800px',
    minWidth: '500px',
  },
  padding: {
    padding: '16px 30px',
  },
  overflow: {
    overflow: 'visible',
  },
  error: {
    backgroundColor: '#FFDDE0',
    padding: '6px',
    color: '#333333',
    lineHeight: '15px',
    fontSize: '13px',
    marginLeft: '10px',
  },
});

EditModal.propTypes = {
  classes: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  closeBtnText: PropTypes.string,
  saveBtnText: PropTypes.string,
  processing: PropTypes.bool,
  contentClass: PropTypes.string,
  actionBtnClass: PropTypes.string,
  padding: PropTypes.bool,
  error: PropTypes.string,
};

EditModal.defaultProps = {
  closeBtnText: I18n.t('shared.cancel'),
  saveBtnText: I18n.t('shared.save'),
  processing: false,
  contentClass: '',
  actionBtnClass: '',
  padding: true,
  error: '',
};

export default withStyles(styles, { name: 'BasicFilteringEditModalStyles' })(
  EditModal
);
