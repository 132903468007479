import React, { Component } from 'react';
import { I18n, Translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '../CudaPaper';
import PasswordInput from '../PasswordInput';

const PasswordHelpBox = classes => (
  <div>
    <div style={{ fontSize: '13px', paddingTop: '10px' }}>
      <Translate value="components.account.passwordReqs" />
    </div>
    <div style={{ padding: '5px 30px 10px 10px' }}>
      <Translate value="components.account.passwordReqText" dangerousHTML />
    </div>
  </div>
);

class PasswordComponent extends Component {
  state = {
    newPassword: '',
    confirmPassword: '',
    showTooltip: false,
  };

  handleFocus = () => {
    this.setState({ showTooltip: true });
  };

  handleBlur = () => {
    this.setState({ showTooltip: false });
  };

  handleChange = (field, event) => {
    this.setState({ [field]: event.target.value });
  };

  changePassword = event => {
    this.props.changePassword(this.state.newPassword);
  };

  render() {
    const { classes, saving, disabled } = this.props;
    const { state } = this;

    return (
      <Paper
        title={I18n.t('shared.password')}
        elevation={2}
        className={classes.paper}
      >
        <Grid
          container
          justify="space-between"
          spacing={24}
          className={classes.container}
        >
          <Grid item xs={6} data-test-id="password1-wrapper">
            <Tooltip
              data-test-id="password-tooltip"
              open={this.state.showTooltip}
              title={<PasswordHelpBox />}
              placement="bottom-start"
              classes={classes.tooltip}
            >
              <PasswordInput
                disabled={disabled}
                handleFocus={this.handleFocus.bind(this)}
                handleBlur={this.handleBlur.bind(this)}
                handleChange={this.handleChange.bind(this, 'newPassword')}
                value={this.state.newPassword}
                label={I18n.t('components.account.newPassword')}
                passwordShow={true}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={6} data-test-id="password1-wrapper">
            <PasswordInput
              disabled={disabled}
              handleChange={this.handleChange.bind(this, 'confirmPassword')}
              value={this.state.confirmPassword}
              label={I18n.t('components.account.confirmPassword')}
              passwordShow={true}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              data-test-id="change-password"
              variant="contained"
              color="primary"
              className={classes.pwButton}
              onClick={this.changePassword}
              disabled={
                disabled ||
                !state.newPassword ||
                state.newPassword !== state.confirmPassword ||
                saving
              }
            >
              {I18n.t('components.account.changePassword')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const styles = theme => ({
  tooltip: {
    minWidth: '240px',
    padding: '10px',
  },
  container: {
    marginTop: '14px',
  },
  paper: {
    padding: '25px',
  },
  pwButton: {
    padding: '7px 15px',
    float: 'right',
    marginRight: 0,
  },
  savingIndicator: {
    position: 'absolute',
  },
});

export default withStyles(styles, { name: 'Password' })(PasswordComponent);
