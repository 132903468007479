import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import TextField from '../TextField';

const styles = {
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '300',
    marginBottom: '20px',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '300',
  },
  formDiv: {
    padding: 8,
    width: '400px',
  },
  actionButton: {
    width: '100%',
  },
  errorText: {
    visibility: 'hidden',
    marginLeft: '30px',
    marginTop: '4px',
  },
  errorState: {
    visibility: 'visible',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      width: '100%',
    },
  },
  buttonWrapper: {
    display: 'flex',
  },
  progress: {
    color: 'rgba(0,0,0,0.7)',
  },
  progressContainer: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  progressText: {
    display: 'inline-block',
    marginLeft: '8px',
    verticalAlign: 'top',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.7)',
  },
};

class ActivateLogin extends React.Component {
  render() {
    const { classes, handleLogin, processing } = this.props;

    return (
      <Grid container justify="center">
        <Grid item className={classes.title}>
          <Translate value="components.activate.loginTitle" />
        </Grid>
        <Grid item className={classes.subTitle}>
          <Translate value="components.activate.loginSubTitle" />
        </Grid>
        <div>
          <form
            onSubmit={event => {
              handleLogin(this.username.value, this.password.value);
              event.preventDefault();
            }}
          >
            <Grid item container direction="column" className={classes.formDiv}>
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                spacing={8}
              >
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <div className={classes.inputWrapper}>
                      <TextField
                        id="email"
                        autoFocus
                        disableUnderline
                        label={I18n.t('components.login.email')}
                        style={{
                          backgroundColor: '#FFFFFF',
                        }}
                        inputRef={input => {
                          this.username = input;
                        }}
                        placeholder="Email"
                      />
                    </div>
                    <FormHelperText
                      className={classes.errorText}
                      id="serial-number-text"
                      classes={{
                        error: classes.errorState,
                      }}
                    >
                      Required
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={10}>
                  <FormControl fullWidth>
                    <div className={classes.inputWrapper}>
                      <TextField
                        id="password"
                        type="password"
                        disableUnderline
                        label={I18n.t('shared.password')}
                        style={{
                          backgroundColor: '#FFFFFF',
                        }}
                        inputRef={input => {
                          this.password = input;
                        }}
                        placeholder="Password"
                      />
                    </div>
                    <FormHelperText
                      className={classes.errorText}
                      id="password-text"
                      classes={{
                        error: classes.errorState,
                      }}
                    >
                      Required
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={10} className={classes.buttonWrapper}>
                  {processing ? (
                    <div className={classes.progressContainer}>
                      <CircularProgress
                        className={classes.progress}
                        size={24}
                      />
                      <Typography type="body1" className={classes.progressText}>
                        {I18n.t('components.login.signingIn')}
                      </Typography>
                    </div>
                  ) : (
                    <Button
                      variant="raised"
                      color="primary"
                      className={classes.actionButton}
                      type="submit"
                    >
                      <Translate value="components.login.signIn" />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    );
  }
}

ActivateLogin.propTypes = {
  classes: PropTypes.object.isRequired,
  handleLogin: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default withStyles(styles, { name: 'ActivateLogin' })(ActivateLogin);
