import { connect } from 'react-redux';
import AuditLog from '../components/AuditLog';
import { initData, fetchData, sortData } from '../actions/audit_log';

const mapStateToProps = (state, ownProps) => ({
  accountNames: state.account.accountNames,
  userName: state.account.userName,
  logs: state.audit_log.logs,
  loading: state.audit_log.loading,
  logCount: state.audit_log.logCount,
  catMapping: state.basic_filtering.catMapping,
  adMap: state.account.adMap,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initData: () => {
    dispatch(initData());
  },
  fetchLogs: params => {
    dispatch(fetchData(params));
  },
  sortLogs: (orderBy, order, specialMap) => {
    dispatch(sortData(orderBy, order, specialMap));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditLog);
