import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';

import SectionTitle from './SectionTitle';
import TextField from '../TextField';

const NetworkAddBar = ({ updateValue, value }) => (
  <span>
    <SectionTitle
      tooltipText={
        <Translate
          value="components.basicFiltering.networkNameHelp"
          dangerousHTML
        />
      }
      titleText={I18n.t('components.basicFiltering.networkName')}
      tooltipTestId="networkNameHelp"
    />
    <TextField
      id="network-name-input"
      autoFocus
      disableUnderline
      style={{
        backgroundColor: '#FFFFFF',
      }}
      value={value}
      onChange={updateValue}
      placeholder={I18n.t('components.basicFiltering.networkNamePlaceholder')}
    />
  </span>
);

NetworkAddBar.propTypes = {
  value: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
};

export default NetworkAddBar;
