import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { I18n } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { AdvancedFilteringIcon } from './icons';
import SectionHeader from './SectionHeader';
import SearchBar from '../containers/advanced_filtering/SearchBar';
import AddPolicyModal from '../containers/advanced_filtering/AddPolicyModal';
import EditPolicyModal from '../containers/advanced_filtering/EditPolicyModal';
import Rulesets from '../containers/advanced_filtering/Rulesets';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    margin: 0,
  },
  helpLink: {
    color: '#0088CF',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  timeOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  queryButton: {
    margin: 0,
    height: '40px',
    minWidth: '85px',
  },
});

class AdvancedFilter extends Component {
  componentDidMount = () => {
    let rule = '';
    if (this.props.location.search) {
      let matches = this.props.location.search.match(/rule=([^&]*)/);

      if (matches.length > 1) {
        rule = matches[1] || 'default';
      }
    }
    this.props.initData(rule);
  };

  render() {
    const { classes, openAddRule, applySearch } = this.props;

    return (
      <DocumentTitle title={I18n.t('components.advancedFiltering.pageTitle')}>
        <div>
          <SectionHeader
            icon={<AdvancedFilteringIcon />}
            title={I18n.t('components.advancedFiltering.title')}
          />
          <Grid data-test-id="advance-filter" spacing={24} container>
            <Grid item xs={12} md={8} lg={6}>
              <SearchBar
                data-test-id="advance-filtering-search"
                height="40px"
                autoFocus={false}
                disabled={false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              lg={6}
              className={classes.timeOptionsWrapper}
            >
              <Button
                data-test-id="search-btn"
                onClick={() => {
                  applySearch();
                }}
                color="secondary"
                className={classes.queryButton}
                variant="contained"
              >
                {I18n.t('components.wfLogs.search')}
              </Button>
              <Button
                data-test-id="add-policy-btn"
                id="add-policy-btn"
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  openAddRule();
                }}
              >
                {I18n.t('components.advancedFiltering.create')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Rulesets />
            </Grid>
          </Grid>
          <AddPolicyModal />
          <EditPolicyModal />
        </div>
      </DocumentTitle>
    );
  }
}

AdvancedFilter.propTypes = {
  openAddRule: PropTypes.func.isRequired,
  applySearch: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdvancedFilter);
