import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import NavigateNext from '@material-ui/icons/NavigateNext';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import LastPage from '@material-ui/icons/LastPage';
import FirstPage from '@material-ui/icons/FirstPage';
import classNames from 'classnames';

const PaginatorStyleSheet = {
  root: {
    width: '100%',
    fontSize: 12,
    color: 'rgba(0,0,0,0.5)',
    height: 50,
    margin: 0,
    backgroundColor: '#FFFFFF',
  },
  button: {
    cursor: 'pointer',
    padding: 0,
    margin: 0,
    backgroundColor: '#FFFFFF',
    height: 13,
    '&:focus': {
      outline: 'None',
    },
    border: 'none',
  },
  paginate: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonDisabled: {
    cursor: 'not-allowed',
    border: 'none',
    padding: '0',
    backgroundColor: '#FFFFFF',
  },
  grayBackground: {
    backgroundColor: '#F7F7F7',
  },
};

const PaginatorComponent = ({
  classes,
  text,
  itemsTotal,
  pageNumber,
  itemsPerPage = 10,
  onFirstClick,
  onNextClick,
  onPreviousClick,
  onLastClick,
  onPaginateClick,
  grayBackground = false,
  showPaginationOptions = true,
  options,
}) => {
  const isFirstPage = Number(pageNumber) === 1;
  const isLastPage = pageNumber * itemsPerPage >= itemsTotal;
  const startItem = (pageNumber - 1) * itemsPerPage + 1;
  const endItem = Math.min(pageNumber * itemsPerPage, itemsTotal);

  let rootClass = classNames(classes.root);
  let buttonClass = classNames(classes.button);
  let buttonDisableClass = classNames(classes.buttonDisabled);

  if (grayBackground) {
    rootClass = classNames(classes.root, classes.grayBackground);
    buttonClass = classNames(classes.button, classes.grayBackground);
    buttonDisableClass = classNames(
      classes.buttonDisabled,
      classes.grayBackground
    );
  }

  return (
    <Grid
      container
      className={rootClass}
      alignItems="center"
      spacing={24}
      data-test-id="paginator"
    >
      <Grid item xs={6} data-test-id="paginatorOptions">
        {options}
      </Grid>
      <Grid item xs={12} md={6}>
        {showPaginationOptions ? (
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={24}
            data-test-id="paginatorShowOptions"
          >
            <Grid item data-test-id="paginatorText">
              {text}
            </Grid>
            <Grid item>
              <div
                className={classes.paginate}
                data-test-id="paginatorPageWrapper"
              >
                <span>{itemsPerPage}</span>
                <ArrowDropDown
                  onClick={onPaginateClick}
                  data-test-id="paginatorArrow"
                />
              </div>
            </Grid>
            <Grid item data-test-id="paginatorDisplay">
              {startItem}-{endItem} of {itemsTotal}
            </Grid>
            <Grid item>
              <button
                data-test-id="firstPage"
                disabled={isFirstPage}
                className={isFirstPage ? buttonDisableClass : buttonClass}
                onClick={onFirstClick}
              >
                <FirstPage disabled={isFirstPage} />
              </button>
            </Grid>
            <Grid item>
              <button
                data-test-id="beforePage"
                disabled={isFirstPage}
                className={isFirstPage ? buttonDisableClass : buttonClass}
                onClick={onPreviousClick}
              >
                <NavigateBefore disabled={isFirstPage} />
              </button>
            </Grid>
            <Grid item>
              <button
                data-test-id="nextPage"
                disabled={isLastPage}
                className={isLastPage ? buttonDisableClass : buttonClass}
                onClick={onNextClick}
              >
                <NavigateNext disabled={isLastPage} />
              </button>
            </Grid>
            <Grid item>
              <button
                data-test-id="lastPage"
                disabled={isLastPage}
                className={isLastPage ? buttonDisableClass : buttonClass}
                onClick={onLastClick}
              >
                <LastPage disabled={isLastPage} />
              </button>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};
export default withStyles(PaginatorStyleSheet, { name: 'PaginatorStyleSheet' })(
  PaginatorComponent
);

PaginatorComponent.propTypes = {
  text: PropTypes.string,
  itemsTotal: PropTypes.number.isRequired,
  pageNumber: PropTypes.number.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onPreviousClick: PropTypes.func.isRequired,
  onPaginateClick: PropTypes.func,
  onFirstClick: PropTypes.func.isRequired,
  onLastClick: PropTypes.func.isRequired,
};
