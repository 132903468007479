import { connect } from 'react-redux';
import UserLogin from '../../components/signup/UserLogin';
import { setLogin, userLogin } from '../../actions/signup';

const mapStateToProps = state => ({
  processing: state.signup.userLogin.submitting,
  error: state.signup.userLogin.error,
});

const mapDispatchToProps = dispatch => ({
  handleLogin: (username, password) => {
    dispatch(userLogin(username, password));
  },
  handleBack: () => dispatch(setLogin(false)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserLogin);
