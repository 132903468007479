import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input/Input';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import CudaTooltip from '../CudaTooltip';
import { InfoIcon, SuccessIcon } from '../icons';
import { Translate, I18n } from 'react-i18nify';

import FormControlLabel from '@material-ui/core/FormControlLabel';

const SuccessIconStyleSheet = {
  root: {
    opacity: 0,
    animation: 'show-icon 2s',
  },
  '@keyframes show-icon': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
};

const SuccessIconWrapperComponent = ({ classes, disabled }) => (
  <div className={classes.root}>
    <SuccessIcon disabled={disabled} />
  </div>
);
export const SuccessIconWrapper = withStyles(SuccessIconStyleSheet, {
  name: 'SuccessIcon',
})(SuccessIconWrapperComponent);

const SettingsInputStyleSheet = {
  inputWrapper: {
    marginRight: 17,
    paddingBottom: 0,
  },
  input: {
    backgroundColor: '#ffffff',
    border: '1px solid #dddddd',
    borderRadius: 2,
    boxShadow: 'inset 0 1px 1px 0 rgba(0,0,0,0.1)',
    width: '50px',
    padding: '0 7px',
    fontSize: 14,
  },
  inputError: {
    extend: 'input',
    borderColor: '#DD1E31',
  },
  inputErrorDisabled: {
    extend: 'inputError',
    borderColor: '#f6bcc1',
  },
  units: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    marginBottom: 3,
  },
  unitsDisabled: {
    extend: 'units',
    color: 'rgba(0,0,0,0.3)',
  },
  successIcon: {
    marginTop: 4,
    marginLeft: -14,
    width: 24,
  },
  errorLabel: {
    paddingTop: 3,
    fontSize: 12,
    color: '#DD1E31',
  },
  errorLabelDisabled: {
    extend: 'errorLabel',
    opacity: 0.5,
  },
};

const SettingsInputComponent = ({
  classes,
  onChange,
  onBlur,
  units,
  value,
  disabled,
  toggled,
  inputUnsaved,
  inputIsUpdating,
  inputUpdateError,
  inputInitialLoad,
  validationErrorMessage,
}) => {
  const className = classNames({
    [classes.input]: true,
    [classes.inputError]: validationErrorMessage && !disabled,
    [classes.inputErrorDisabled]: validationErrorMessage && disabled,
  });

  return (
    <Grid container direction="row" align="center">
      <Grid item>
        <Grid
          container
          direction="column"
          className={classes.inputWrapper}
          spacing={0}
        >
          <Grid item>
            <div
              className={
                disabled || toggled === false
                  ? classes.unitsDisabled
                  : classes.units
              }
            >
              &nbsp;{units}
            </div>
          </Grid>
          <Grid item>
            {inputIsUpdating && <Progress />}
            <Input
              className={className}
              disableUnderline={true}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={disabled || toggled === false}
            />
          </Grid>
          <Grid item>
            <div
              className={
                disabled ? classes.errorLabelDisabled : classes.errorLabel
              }
            >
              &nbsp;{validationErrorMessage}
            </div>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.successIcon}>
        {!inputIsUpdating &&
          !inputUnsaved &&
          !inputUpdateError &&
          !inputInitialLoad && <SuccessIconWrapper disabled={disabled} />}
      </div>
    </Grid>
  );
};
const SettingsInput = withStyles(SettingsInputStyleSheet, {
  name: 'SettingsInput',
})(SettingsInputComponent);

const switchStyles = {
  checked: {
    color: '#999999',
  },
  bar: {
    opacity: '0.2',
    height: '17px',
    marginTop: '-8px',
    borderRadius: '16px',
    backgroundColor: '#DDDDDD',
  },
  icon: {
    height: '12px',
    width: '12px',
  },
  switchBase: {
    color: '#999999',
    '&$checked': {
      color: '#0088CE',
      '& + $bar': {
        backgroundColor: '#BFE1F3',
      },
    },
  },
};

function OverrideSwitch(props) {
  return (
    <Switch
      classes={{
        checked: props.classes.checked,
        bar: props.classes.bar,
        icon: props.classes.icon,
        switchBase: props.classes.switchBase,
      }}
      onChange={props.onChange}
      checked={props.checked}
      disabled={props.disabled}
      color="primary"
    />
  );
}

export const CudaSwitch = withStyles(switchStyles)(OverrideSwitch);

export const SettingsToggle = ({
  onToggle,
  toggled,
  disabled,
  toggleIsUpdating,
  labels = [],
  classes,
}) => {
  const toggleOnLabel =
    labels.length > 1 ? I18n.t(labels[0]) : I18n.t('settingsItem.on');
  const toggleOffLabel =
    labels.length > 1 ? I18n.t(labels[1]) : I18n.t('settingsItem.off');
  return (
    <div>
      {toggleIsUpdating && <Progress isToggle={true} />}
      <FormControlLabel
        control={
          <CudaSwitch
            onChange={onToggle}
            checked={toggled}
            disabled={disabled}
            classes={classes}
          />
        }
        label={
          toggled ? toggleOnLabel.toUpperCase() : toggleOffLabel.toUpperCase()
        }
      />
    </div>
  );
};

const progressStyleSheet = {
  overlay: {
    position: 'absolute',
    paddingLeft: 20,
    paddingTop: 8,
    width: 70,
    display: 'flex',
    backgroundColor: 'white',
    opacity: 0.7,
    zIndex: 10,
  },
  overlayInput: {
    extend: 'overlay',
    paddingTop: 5,
  },
};

const ProgressComponent = ({ classes, isToggle }) => (
  <div className={isToggle ? classes.overlay : classes.overlayInput}>
    <CircularProgress size={20} />
  </div>
);

const Progress = withStyles(progressStyleSheet, { name: 'ProgressComponent' })(
  ProgressComponent
);

const styleSheet = {
  container: {
    minHeight: 50,
    marginTop: 5,
    '& $iconItem': {
      padding: 0,
      marginLeft: -14,
    },
  },
  text: {
    paddingLeft: 20,
    fontSize: 16,
  },
  retryLink: {
    fontSize: 14,
    margin: '0 25px 2px -20px',
    cursor: 'pointer',
    color: '#0077c8',
  },
  mainToggleText: {
    paddingLeft: 0,
    fontSize: 22,
    fontWeight: 300,
    color: 'rgba(0,0,0,0.7)',
  },
  textDisabled: {
    color: 'rgba(0,0,0,0.3)',
  },
  toggleDiv: {
    minWidth: 120,
    '& label': {
      height: 34,
    },
  },
  iconDiv: {
    marginTop: 3,
  },
  iconItem: {
    padding: 0,
  },
  labelWrapper: {
    maxWidth: '240px',
  },
};

const SettingsItem = ({ classes, ...props }) => {
  const {
    text,
    onChange,
    onBlur,
    onToggle,
    disabled = false,
    tooltipText,
    inputUnsaved,
    inputUpdateError,
    toggleUpdateError,
    mainToggle = false,
    customClasses = {},
  } = props;
  const uniqueId = 'tooltipId-' + text; //String(Math.random());

  let infoIconColor;
  if (inputUpdateError || toggleUpdateError) {
    infoIconColor = '#DD1E31';
  } else if (inputUnsaved) {
    infoIconColor = '#0088CF';
  } else {
    infoIconColor = '#888888';
  }

  let displayedTooltipText;
  if (inputUpdateError && toggleUpdateError) {
    displayedTooltipText = (
      <div>
        <Translate value="settingsItem.toggleErrorTooltipText" />
        <br />
        <Translate value="settingsItem.inputErrorTooltipText" />
      </div>
    );
  } else if (inputUpdateError) {
    displayedTooltipText = (
      <Translate value="settingsItem.inputErrorTooltipText" />
    );
  } else if (toggleUpdateError) {
    displayedTooltipText = (
      <Translate value="settingsItem.toggleErrorTooltipText" />
    );
  } else if (inputUnsaved) {
    displayedTooltipText = (
      <Translate value="settingsItem.inputUnsavedTooltipText" />
    );
  } else {
    displayedTooltipText = tooltipText;
  }

  const labelClassName = classNames({
    [classes.text]: true,
    [classes.mainToggleText]: mainToggle,
    [classes.textDisabled]: disabled,
  });

  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      className={customClasses.container || classes.container}
    >
      {text && (
        <Grid
          item
          xs={6}
          className={customClasses.labelWrapper || classes.labelWrapper}
        >
          <div className={labelClassName}>{text}</div>
        </Grid>
      )}

      {onToggle && (
        <Grid item className={classes.toggleDiv}>
          <SettingsToggle classes={classes} {...props} />
        </Grid>
      )}

      {onChange && (
        <Grid item>
          <SettingsInput {...props} />
        </Grid>
      )}

      {inputUpdateError && (
        <div className={classes.retryLink} onClick={onBlur}>
          <Translate value="settingsItem.retry" />
        </div>
      )}
      {tooltipText && (
        <Grid item className={classes.iconItem}>
          <div className={classes.iconDiv}>
            <InfoIcon color={infoIconColor} data-tip data-for={uniqueId} />
          </div>
          <CudaTooltip id={uniqueId}>
            <span dangerouslySetInnerHTML={{ __html: displayedTooltipText }} />
          </CudaTooltip>
        </Grid>
      )}
    </Grid>
  );
};

SettingsItem.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

export default withStyles(styleSheet, { name: 'SettingsItem' })(SettingsItem);
