import { connect } from 'react-redux';
import QuarantineTable from '../../components/quarantine/Table';
import {
  restoreFiles,
  deleteFiles,
  orderData,
  downloadPdf,
} from '../../actions/quarantine';

const mapStateToProps = (state, ownProps) => ({
  quarantined: state.quarantine.quarantinedFiles,
  filtered: state.quarantine.filtered,
  query: state.quarantine.query,
  count: state.quarantine.count,
  loading: state.quarantine.loading,
  timezone: state.account.time_zone,
  account_id: state.account.selected,
  order: state.quarantine.order,
  orderBy: state.quarantine.orderBy,
  columns: state.quarantine.columns,
  downloadCuid: state.quarantine.cuid,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  restoreClick: info => {
    dispatch(
      restoreFiles([
        {
          device_id: info.device_id,
          method: 'unquarantine',
          quarantine_uuid: info.quarantine_uuid,
          file_name: info.file_name,
          device_name: info.device_name,
        },
      ])
    );
  },
  deleteClick: info => {
    dispatch(
      deleteFiles([
        {
          device_id: info.device_id,
          method: 'remove',
          quarantine_uuid: info.quarantine_uuid,
          file_name: info.file_name,
          device_name: info.device_name,
        },
      ])
    );
  },
  sortData: (orderBy, order) => {
    dispatch(orderData(orderBy, order));
  },
  downloadPdf: cuid => {
    dispatch(downloadPdf(cuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuarantineTable);
