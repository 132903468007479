import { connect } from 'react-redux';
import RuleRow from '../../components/advanced_filtering/RuleRow';
import { getCategoryNames } from '../../selectors/advanced_filtering';
import {
  reorderRules,
  submitRulesReorder,
  updateRuleOrder,
  submitRuleOrder,
} from '../../actions/advanced_filtering';

import { openEditRule, deleteRule } from '../../actions/advanced_filtering';

const mapStateToProps = state => ({
  categories: getCategoryNames(state),
  adMap: state.account.adMap,
  processing: state.advanced_filtering.processing,
  highlighted: state.advanced_filtering.highlightedRule,
  search: state.advanced_filtering.search.match,
});

const mapDispatchToProps = dispatch => ({
  openEditRule: (selectedAD, rule) => {
    dispatch(openEditRule(selectedAD, rule));
  },
  deleteRule: (ruleset_id, id) => {
    dispatch(deleteRule(ruleset_id, id));
  },
  reorderRules: (dragIndex, hoverIndex, parentIndex) => {
    dispatch(reorderRules(dragIndex, hoverIndex, parentIndex));
  },
  submitReorder: (id, hoverIndex, parentIndex, originalIndex, parentId) => {
    dispatch(
      submitRulesReorder(id, hoverIndex, parentIndex, originalIndex, parentId)
    );
  },
  updateOrder: (index, order, parentIndex) => {
    dispatch(updateRuleOrder(index, order, parentIndex));
  },
  submitRuleOrder: (id, index, parentIndex, order, rulesetId) => {
    dispatch(submitRuleOrder(id, index, parentIndex, order, rulesetId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RuleRow);
