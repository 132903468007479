import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import PolicyDropdown from './PolicyDropdown';

import ExceptionTable from '../../containers/basic_filtering/ExceptionTable';
import ExceptionFilterBar from '../../containers/basic_filtering/ExceptionFilterBar';
import ExceptionAddBar from '../../containers/basic_filtering/ExceptionAddBar';

class ExceptionPolicy extends Component {
  onChange = (selected, domains) => {
    this.props.setExceptions(domains, selected);
  };

  render() {
    const { classes, customPolicies, selected } = this.props;

    return (
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          className={classes.levelContainer}
          data-test-id="exceptionBoxWrapper"
        >
          <PolicyDropdown
            custom={customPolicies}
            label="exceptionPolicy"
            customPolicyKey="domains"
            selected={selected}
            onChange={this.onChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.catContainer}>
          <ExceptionAddBar />
          <div className={classes.filterWrapper}>
            <ExceptionFilterBar />
          </div>
          <div className={classes.tableWrapper}>
            <ExceptionTable />
          </div>
        </Grid>
      </Grid>
    );
  }
}

ExceptionPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  domains: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string,
      action: PropTypes.string,
    })
  ).isRequired,
  selected: PropTypes.string.isRequired,
  editSelected: PropTypes.string,
  customPolicies: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.number),
      domains: PropTypes.arrayOf(
        PropTypes.shape({
          domain: PropTypes.string,
          action: PropTypes.string,
        })
      ),
      id: PropTypes.string,
    })
  ).isRequired,
  setExceptions: PropTypes.func.isRequired,
  updateExceptions: PropTypes.func.isRequired,
};
ExceptionPolicy.defaultProps = {
  editSelected: 'custom',
};

const styles = {
  filterWrapper: {
    marginTop: '16px',
    padding: '10px 16px',
    backgroundColor: '#F7F7F7',
    border: '1px solid #DDDDDD',
  },
  tableWrapper: {
    borderLeft: '1px solid #DDDDDD',
    borderRight: '1px solid #DDDDDD',
    marginBottom: '24px',
  },
  checkedCategory: {
    marginBottom: '24px',
  },
  catContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'ExceptionPolicy' })(ExceptionPolicy);
