const translationsObject = {
  en: {
    errors: {
      503: '503 Service Unavailable',
      500: 'Internal Server Error',
      dupDomain: 'Domain has already been configured.',
      badValueDomain:
        'Domain can only contain alphanumeric, dot (.) and dash (-) characters.',
      app: 'Application has encountered an error',
      downloadFailed: 'Failed to download file.',
      failedRefresh: 'Failed to refresh session',
    },
    shared: {
      refreshSuccess: 'Refreshed Session',
      advancedFiltering: 'Advanced Filtering',
      error: 'Error',
      notPurchased: 'Not Purchased',
      learnMore: 'Learn More',
      configurationUpdated: 'Configuration Updated',
      advancedConfigurationUpdated:
        'Configuration Updated. Policies may take up to 15 minutes to take effect.',
      sendingReport: 'Sending Report...',
      runNow: 'Run Now',
      add: 'Add',
      enabled: 'Enabled',
      disabled: 'Disabled',
      start: 'Start',
      back: 'Back',
      next: 'Next',
      move: 'Move',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      os: 'OS',
      endpointName: 'Endpoint Name',
      username: 'Username',
      yes: 'Yes',
      no: 'No',
      now: 'Now',
      last_7_days: 'Last 7 days',
      productName: 'Barracuda Content Shield',
      primaryDns: 'Primary DNS:',
      secondaryDns: 'Secondary DNS:',
      accountSettings: 'My Profile',
      downloadCSV: 'Download CSV',
      refreshLogs: 'Refresh Logs',
      signOut: 'Sign Out',
      viewReport: 'View Report',
      searchByName: 'Search by Account Name',
      cancel: 'Cancel',
      create: 'Create',
      edit: 'Edit',
      save: 'Save',
      suspend: 'Suspend',
      resume: 'Resume',
      restore: 'Restore',
      delete: 'Delete',
      active: 'Active',
      suspended: 'Suspended',
      fileType: 'File Type',
      threats: 'Threats',
      lastThreat: 'Last Threat',
      endpoints: 'Endpoints',
      endpoint: 'Endpoint',
      columns: 'Columns',
      time: 'Time',
      account: 'Account',
      user: 'User',
      ip_address: 'IP Address',
      details: 'Details',
      agentVersion: 'Agent Version',
      block: 'block',
      allow: 'allow',
      suspicious: 'Suspicious',
      password: 'Password',
      preferences: 'Preferences',
      change: 'Change',
      malicious: 'Malicious',
      expirationDate: 'Expiration Date',
      expires: 'Expires',
      close: 'Close',
      encrypted: 'Encrypted',
      unsaved: 'Unsaved changes',
      noLongerExists: 'No longer exists',
      ranges: {
        day: 'Last 24 hours',
        week: 'Last 7 days',
        month: 'Last 30 days',
        fortnight: 'Last 14 days',
      },
      trends: {
        day: 'vs previous 24 hours',
        week: 'vs previous 7 days',
        month: 'vs previous 30 days',
        fortnight: 'vs previous 14 days',
      },
      frequency: {
        hourly: 'Hourly',
        daily: 'Daily',
        weekly: 'Weekly',
        biweekly: 'Bi-Weekly',
        monthly: 'Monthly',
      },
      reports: {
        runSuccess: 'Request sent successfully',
        runFail: 'Failed to generate report',
        all: 'All Malicious Files Detected',
        endpoints: 'Malicious Files By Endpoint',
        users: 'Malicious Files By User',
        files: 'Malicious Files By File Types',
        agent_version: 'Agent Version Installed',
        'blocked-categories': 'Blocked Categories',
        'blocked-supercategories': 'Blocked Supercategories',
      },
      inactive: 'Inactive',
      agents: 'Agents',
      subscriptions: 'Subscriptions',
      users: 'Users',
      summary: 'Summary',
      devices: 'Devices',
      blockedFiles: 'Blocked Files',
      scanDetermination: 'Scan Determination',
      noResults: 'No Results',
      deviceName: 'Device Name',
      date: 'Date',
      filePath: 'File Path',
      fileName: 'File Name',
      path: 'Path',
      file: 'File',
      runReport: 'Run Report',
      downloadAgent: 'Download agent',
      download: 'Download',
      view: 'View',
      schedule: 'Schedule',
      timeFrame: 'Time Frame',
      reportType: 'Report Type',
      categoriesPlaceholder: 'Search all categories',
      locationsPlaceholder: 'Search all locations',
      supercategoriesPlaceholder: 'Search all supercategories',
      actionsPlaceholder: 'Search all action types',
      usersPlaceholder: 'Search all users',
      groupsPlaceholder: 'Search all groups',
      urlsPlaceholder: 'Search URLs',
      domainsPlaceholder: 'Search domains',
      sessionRefresh: 'Session Refresh',
      refresh: 'Refresh',
      sessionRefreshPrompt:
        'Session is about to expire, would you like to refresh?',
    },
    userAuthentication: {
      title: 'Users',
      pageTitle: 'Users',
      subheader:
        'For authentication and applying policies, you can either manually configure users locally to the service, or link your existing LDAP or AD directory services to automatically associate your users with the service.',
      configureUsers: 'Configure users',
      directoryOption: 'Directory Services',
      localOption: 'Manually Configure Users',
      configureLocal: 'Configuring Local Users',
      localUserPrompt:
        'Switching between Directory Services and Local Users will affect any existing policies that have been configured.',
      continue: 'Continue anyway',
      directory: {
        title: 'Directory Services',
        subTitle:
          'In order to configure an existing LDAP or Active Directory authentication with your Barracuda account, you will need:',
        stepIP: 'The IP Address of your authentication server',
        stepPort: 'Port of your authentication server',
        stepBaseDN: 'Base DN',
        stepBindDN: 'Bind DN',
        stepPassword: 'Password for your authentication server',
        configure: 'Configure directory services',
        configured: 'Configured',
        notConfigured: 'Not Configured',
      },
      local: {
        name: 'Name',
        userID: 'User ID',
        userName: 'User Name (optional)',
        ipAddress: 'IP Address',
        lastSeen: 'Last Seen',
        noLocalUsers: 'No local users configured',
        addUsers: 'Add Users',
        searchPlaceholder: 'Search for a user',
        addLocalUsers: 'Add Local Users',
        addUserText:
          'Add or select an existing group to which you will assign users. You can later apply policies to groups. For example, you might want to apply different policies to the Finance and Marketing departments.',
        groupName: 'Group Name',
        placeholder:
          'Enter the User ID from Windows or Mac. Separate all users with a comma.',
        groupListPlaceholder:
          'Enter a new group name or select one from the list',
        editText:
          'To more easily identify User IDs, you can add a user name which will show up on screens and reports.',
        editUser: 'Edit User',
        renameGroupText: 'Enter a new name for this group.',
        renameGroup: 'Rename Group',
        deleteGroup: 'Delete Group',
        deleteGroupText:
          "Deleting the group '%{group}' will also delete any of the associated users that are not in another group. This action cannot be undone.",
        deleteGroupSubText:
          'It is recommended that users be moved to a new group before deleting.',
        moveUser: 'Move User to Group',
        moveUserText: "Move the user '%{user}' to:",
        moveUserSubText:
          'Moving users to a new group may alter the way policies are applied. Check the user policies after moving to ensure proper configuration.',
        moveToGroup: 'Move to Group',
        removeFromGroup: 'Remove from Group',
        rename: 'Rename',
        removeFromGroupText:
          "Removing '%{user}' from '%{group}' will also delete the user if they are not in another group. This action cannot be undone.",
        removeFromGroupSubText:
          'It is recommended that the user be moved to a new group before removing.',
      },
    },
    header: {
      trial: 'Free Trial',
      activate: 'Activate',
    },
    settingsItem: {
      on: 'ON',
      off: 'OFF',
      retry: 'Retry',
      inputUnsavedTooltipText:
        'The value that is in this field has not yet been updated in the server.' +
        'Please click on a different part of the screen to update this value in the server',
      inputErrorTooltipText:
        'The update of this value was unsuccessful. The current value of the item may not ' +
        'represent the actual value in the server. Please try again',
      toggleErrorTooltipText:
        'The toggle action was unsuccessful and the toggle has been reset. Please try again',
    },
    signup: {
      barracudaMSP:
        'This page is not intended to provide evaluations for MSP\'s. If you are an MSP, please contact Barracuda MSP <a href="https://barracudamsp.com/" target="_blank">here</a> for account and trial information.',
      mspError: 'This email is associated with an MSP.',
      startTrial: {
        pageTitle: 'Start Trial',
        formTitle: 'Barracuda Content Shield 30-day trial',
        formSubTitle: 'Create a Barracuda account to start your trial.',
        alreadyHaveAccount: 'Already have an account?',
        signIn: 'Sign In',
        emailAddress: 'Email Address',
        verifyEmailTitle: 'Verify Your Email Address',
        verifyEmailText: 'We have sent a confirmation email to %{email}',
        verifyEmailTextAction:
          'Click the link in that email to complete your registration!',
      },
      login: {
        title: 'Sign in with your Barracuda account',
        subTitle:
          'Use existing credentials to start your Barracuda Content Shield trial',
        noAccount: "Don't have an account?",
      },
      selectAccount: {
        title: 'Barracuda Content Shield Trial',
        subTitle: 'Select the account for this trial',
        notAccount: 'Not %{email}?',
        submitting: 'Submitting...',
        selectAccount: 'Select account',
      },
      fillDetails: {
        pageTitle: 'Signup',
        title: 'Sign up for a 30-day trial of Barracuda Content Shield',
        textP1:
          'Barracuda Content Shield provides a highly scalable, cloud-managed and provisioned web security solution, that offers robust content filtering as well as known file based threats and malware.',
        textP2:
          "Powered by Barracuda's extensive threat intelligence network, Content Shield protects users from malicious sites, inappropriate content, as well as known file-based and zero- day threats. Barracuda Content Shield provides visibility into traffic both on-premises and for roaming users. Content Shield will work with and augment your existing AV solution, protecting against malicious software that is file based. It also prevents malware from reaching your endpoints from the network threat vector.",
        email: 'Email',
        firstName: 'First Name',
        lastName: 'Last Name',
        companyName: 'Company Name',
        phoneNumber: 'Phone Number',
        country: 'Country',
        postalCode: 'Postal Code',
        password: 'Password',
        confirmPassword: 'Confirm Password',
      },
    },
    blockPage: {
      navtext: 'Block Page',
      page_title: 'Customize Block Page',
      title: 'Customize Block Page',
      subheader:
        'Use the controls on this page to customize the message in the block page the user sees when a website is blocked due to configured policy. Choose <b>Custom HTML</b> to edit the message content via HTML, or <b>Barracuda Default</b> to use the WYSIWYG text editor.',
      specialValues: {
        title: 'Special Values',
        subheader:
          'Use any of the following dynamic values to enhance your block screen',
        d:
          'The domain of the violation which resulted in the response page to be displayed.',
        t: 'The time at which the violation occured.',
        tq: 'The time the rule is in effect.',
        z: 'The Content Category which resulted in the violation.',
      },
      editor: {
        title: 'Block Page',
        resetToDefault: 'Reset to default',
        preview: 'Preview',
      },
      template: {
        label: 'Block page template',
        default: 'Barracuda default',
        custom: 'Custom HTML',
      },
    },
    components: {
      smbAccountSettings: {
        pageTitle: 'Account Settings',
        title: 'Account Settings',
        customBranding: 'Custom Branding',
        customBrandingSub:
          'Upload a custom logo to display on reports and on blocked pages displayed to end users.',
        currentLogo: 'Current Logo',
        uploadFromFile: 'Upload from File',
        selectFile: 'Select File',
        upload: 'Upload',
        imageDimension: 'Image cannot exceed 240px height by 240px width',
        logoTypeRule:
          'Logo can be formatted in .jpg, .jpeg, .jpe, .gif, .bmp, .svg',
        logoSizeRule: 'File size must not exceed 100K',
        preview: 'Preview',
        noImage: 'No Image Uploaded',
        dropFiles: 'Drop Files Here to Upload',
        or: 'Or',
        supportAccess: 'Support Access',
        supportAccessSub:
          'Control the ability for Barracuda to log in on your behalf to troubleshoot this account.',
        updateTitle: 'Updates',
        updateSubTitle: 'Choose how agent updates will be applied.',
        applyUpdates: 'Apply Agent Updates',
        updateAt: 'Update agents at',
        localTime: 'Local device time',
        manually: 'Manually',
        reboot: 'Automatically when devices reboot',
        time: 'Automatically at a specific time',
        maxHeight: 'Logos will be constrained to a maximum height of 240px.',
        maxWidth: 'Widths will be constrained to a maximum of 240px.',
      },
      activate: {
        pageTitle: 'Activate Your Purchase',
        formTitle: 'Enter Serial Number and Linking Code',
        formSubTitle:
          'To activate a purchase, enter the serial number and linking code provided by Barracuda or your seller.',
        loginTitle: 'Login',
        loginSubTitle:
          'Please login to your Barracuda account to activate your purchase',
        linking: 'Linking',
        licenseAgreement: 'Barracuda Software License Agreement',
        linkFailure: 'Account activation failed',
      },
      dashboard: {
        skus: {
          dns: 'DNS Filtering',
          atp: 'ATP',
        },
        topBlockedCategories: 'Top Blocked Categories',
        blockedSupercategories: 'Blocked Supercategories',
        accounts: 'Accounts',
        account: 'Account',
        page_title: 'Content Shield | Dashboard',
        title: 'Dashboard',
        navtext: 'Dashboard',
        headerText:
          'This page displays threat statistics for users on the account shown on the upper left of the page.',
        overview: 'Overview',
        overviewTitle: 'Content Shield | Overview',
        auditLog: 'Audit Log',
        statusHeader: 'Status',
        blockedRequests: 'Blocked Requests',
        statusSubtext: 'Threats Blocked',
        threatGraphHeader: 'Threats Blocked',
        activityHeader: 'Activity',
        recentThreatGraphHeader: 'Top 5 Endpoints Encountering Threats',
        threatSummaryHeader: 'Threat Summary',
        view: 'View',
        threatsInQuarantine: 'Threats In Quarantine',
        remainingLicenses: 'Remaining Licenses',
        licenses: 'Licenses',
        accountName: 'Account Name',
        threatsDetected: 'Threats Detected',
        agentsDeployTooltip: 'The number of agents you have currently deployed',
        agentsInactivetip:
          'The number of agents that have not communicated to the system in the past 7 days',
        agentsDeployed: 'Agents Deployed',
        totalLicenses: 'Total Licenses',
        availableLicenses: 'Available Licenses',
        subscriptionExpires: 'Subscription expires',
        expirationDate: 'Expiration Date',
        supportAccess: 'Support Access',
        supercategory: 'Supercategory',
        trend: 'Trend',
        count: 'Count',
        insufficientData: 'insufficient data',
        topBlockedAccounts: 'Top Blocked Accounts',
        viewReport: 'View Report',
        viewAll: 'View All',
        webThreats: 'Web-Based Threats',
        phishingPrevention: 'Phishing Prevention',
        malwarePrevention: 'Malware Prevention',
      },
      audit: {
        page_title: 'Content Shield | Audit Log',
        title: 'Audit Log',
        search_placeholder: 'Search by company name, user, or IP address',
        smb_search_placeholder: 'Search by user or IP address',
        noResults: 'No audit logs',
        'login-successful': 'Login Successful',
        'login-unsuccessful': 'Login Unsuccessful',
        'support-login-successful': 'Support Login Successful',
        'support-login-unsuccessful': 'Support Login Unsuccessful',
        'logout-successful': 'Logout',
        filetypes: 'ATP Policy Scan Type Changed. %{field}: %{value}',
        file_exclusion: 'ATP Policy Exemption %{field}. Filename: %{value}',
        path_exclusion: 'ATP Policy Exemption %{field}. Path: %{value}',
        process_exclusion: 'ATP Policy Exemption %{field}. Process: %{value}',
        suspicious: 'ATP Scan Policy Changed. Suspicious Files: %{value}',
        scan_removable_drive:
          'ATP Policy Scan Policy Changed. Removable Drives: %{value}',
        block_encrypted_archives:
          'ATP Policy Changed. Encrypted Files: %{value}',
        block_password_protected_doc:
          'ATP Policy Changed. Password Protected Files: %{value}',
        'delete-notification': 'Deleted "%{type}" alert',
        'create-notification': 'Created "%{type}" alert',
        suspended: 'Suspended "%{type}" alerts',
        resumed: 'Resumed "%{type}" alerts',
        email_change:
          'Changed subscribed emails to "%{emails}" for "%{type}" alert',
        threshold_change:
          'Changed threshold to "%{threshold}" for "%{type}" alert',
        'quarantine-unquarantine':
          'Restored file "%{file_name}" to device "%{device_name}',
        'quarantine-remove':
          'Removed file "%{file_name}" from device "%{device_name}',
        'location-policy-create': 'Create Basic Policy',
        addresses: 'Address(es): ',
        exceptions: 'Exceptions: ',
        allowExceptions: 'Allow Exceptions: ',
        deniedExceptions: 'Denied Exceptions: ',
        categories: 'Categories: ',
        'location-policy-remove': 'Remove Basic Policy %{name}',
        'location-policy-location-update': 'Update Basic Policy',
        'location-policy-domain-patch': 'Update Domain Exceptions',
        oldName: 'Old Name',
        newName: 'New Name',
        addAddresses: 'Add Address(es)',
        removeAddresses: 'Remove Address(es)',
        'location-policy-categories-update': 'Update Categories',
        addCategories: 'Add Categories',
        removeCategories: 'Remove Categories',
        'location-policy-exceptions-update': 'Update Exceptions',
        addAllowDomains: 'Add Allow Domains',
        removeAllowDomains: 'Remove Allowed Domains',
        addDenyDomains: 'Add Denied Domains',
        removeDeniedDomains: 'remove Denied Domains',
        oldVal: 'Old Values:',
        newVal: 'New Values:',
        'account-update-timezone': 'Update Account Timezone',
        'account-update-password': 'Change Account Password',
        'ruleset-create': 'Created new User/Group Advanced Filtering Rule',
        'ruleset-remove': 'Removed User/Group Advanced Filtering Rule',
        'ruleset-rule-create': 'Created Advanced Filtering Rule',
        'ruleset-rule-create-user': 'Created Advanced Filtering User Rule',
        'ruleset-rule-create-group': 'Created Advanced Filtering Group Rule',
        'ruleset-rule-remove': 'Removed Advanced Filtering Rule',
        'ruleset-rule-update': 'Updated Advanced Filtering Rule',
        'update-account': 'BCS Settings Updated',
        'create-local-group': 'Created Local Group',
        'create-local-user': 'Created Local User',
        groupAdded: 'Group: %{group}',
        group: 'Group',
        users: 'Users',
        'delete-local-group': 'Removed local group',
        'update-local-group': 'Updated Group',
        'update-local-user': 'Updated User',
        'delete-local-group-user': 'Removed user from group',
      },
      account: {
        page_title: 'My Profile',
        title: 'My Profile',
        subheader:
          'Make changes to your preferences and password for all of your Barracuda cloud products.',
        language: 'Language',
        timezone: 'Timezone',
        passwordReqs: 'Password Requirements',
        passwordReqText:
          'Must be greater than 8 characters <br /> Must be less than 72 characters <br /> Not a top 10 common password <br /> Confirmed password must match',
        newPassword: 'New Password',
        confirmPassword: 'Confirm New Password',
        changePassword: 'Change Password',
        passwordUpdateSuccess: 'Password updated.',
        passwordUpdateFailure:
          'Password not updated. Password does not meet requirements.',
        timezoneUpdateSuccess: 'Timezone updated.',
        timezoneUpdateFailure: 'Timezone failed to update.',
        updating: 'Updating',
      },
      atp: {
        file: 'File',
        path: 'Path',
        process: 'Process',
        title: 'Threat Policy',
        fileType: 'File Type',
        fileTypes: 'File Types',
        navtext: 'Threat Policy',
        atdTooltip:
          'Click to disable or enable malware scanning on your endpoints.',
        headerText:
          'Use the settings in this page to select actions to take with harmful files, specify file types to scan, allow or block password-protected or encrypted files, whether to scan removable drives, and to exclude specific files, paths or processes from scanning.',
        scanTypeHeader: 'Scan Policy',
        advancedThreatDetection: 'Malware Prevention',
        scanMsofficeFile: 'Scan Microsoft Office Files',
        msofficeFileHelpIcon:
          'Set to <b>YES</b> if you want Microsoft Office files scanned for threats.',
        scanExecutables: 'Scan Executable Files',
        executablesHelpIcon:
          'Set to <b>YES</b> if you want executable files scanned for threats.',
        scanPdfFiles: 'Scan PDF Files',
        pdfFilesHelpIcon:
          'Set to <b>YES</b> if you want PDF documents scanned for threats.',
        scanCompressFile: 'Scan Compressed Files',
        compressFileHelpIcon:
          'Set to <b>YES</b> if you want compressed files scanned for threats.',
        autoRemediate: 'Action For Suspicious Files',
        autoRemediateHelpIcon:
          'Set to <b>QUARANTINE</b> if you want suspicious files quarantined for later inspection by an administrator. Set to <b>ALLOW</b> to have suspicious files be reported only.',
        pwProtectedFile: 'Password Protected Files',
        pwProtectedFileHelpIcon:
          'Set to <b>QUARANTINE</b> to block password protected files. If <b>ALLOW</b>, these files are allowed.',
        encryptedFiles: 'Encrypted Files',
        encryptedFilesHelpIcon:
          'Set to <b>QUARANTINE</b> to block encrypted files. If <b>ALLOW</b>, these files are allowed.',
        removableDrive: 'Removable Drives',
        scanRemovableDrive: 'Scan Removable Drives',
        scanRemovableHelp:
          'Set to <b>YES</b> if you want to scan removable media / drives when they are connected to your system. If set to <b>NO</b>, removable drives will only be scanned when accessed.',
        scanExclusion: 'Scan Exclusions',
        noCustomExclusion: 'No Custom Exclusions',
        addExclusion: 'Add Exclusion',
        customExclusion: 'Custom Exclusion',
        customExclusions: 'Custom Exclusions',
        customExclusionHelp:
          'To exclude one or more files, paths, or processes from scanning, click <b>+Add Exclusion</b>.',
        scanTypeHelp:
          'Set to <b>YES</b> for each file type you want to scan for threats. If set to <b>NO</b>, those file types will not be scanned.',
        encryptedAndProtectedFiles: 'Encrypted and Password Protected Files',
        encryptedAndProtectedFilesHelp:
          'The scanner may not always be able to access encrypted or password protected files. Set to <b>QUARANTINE</b> if you want to block these files. Set to <b>ALLOW</b> to allow these files. Recommended: <b>QUARANTINE</b>',
        type: 'Type',
        value: 'Value',
        customExclusionText:
          'To exclude a file, path, or process from scanning, select the <b>Exception Type</b>, and then enter the filename, path, or process. For a process, enter the executable name (example: explorer.exe) or the full path (C:Program Filesexplorer.exe).',
        quarantine: 'Quarantine',
      },
      atpLogs: {
        pageTitle: 'Content Shield | ATP Logs',
        title: 'Threat Logs',
        navtext: 'Threat Logs',
        headerText:
          'Use the settings in this page to select file types to block or allow password-protected files, to scan removable media, and to exclude specific paths or files from scanning.',
        search: 'Search by endpoint or filename',
      },
      deploy: {
        installManually: 'Install Manually',
        installRMM: 'Install using RMM or GPO',
        accountConfiguration: 'Account Configuration File',
        currentVersion: 'Current Agent Version',
        olderVersions: 'Older Agent Versions (%{count})',
        navtext: 'Downloads',
        releaseNotes: 'Release Notes',
        deployAgent: 'Deploy Agent',
        deployInstructions: 'Deployment Instructions',
        deployHelp:
          'Download a copy of the Content Shield software and use the Account Configuration File to associate the installation with your account',
        pageTitle: 'Content Shield | Downloads',
        title: 'Downloads',
        deployOptions: 'Deployment Options',
        deployDesc:
          'The quickest and easiest way to set up endpoints is by downloading or emailing a copy of the Barracuda Content Shield software which has one of your keycodes automatically applied.',
        deploy: 'Deploy using',
        email:
          'Follow the 3 simple steps below to install Barracuda Content Shield on your endpoint:<br/><br/><ol><li>Download the Barracuda Content Shield software from the following link:<br /><a href="%{url}">%{url}</a></li><li>When prompted, select Save.</li><li>Run the download file</li></ol><br /> This will silently install the software and you will be immediately be protected.',
        advanced: 'Advanced Deployment Options',
        install: 'Install using RMM',
        rmm1: 'On the endpoint, download the installer above.',
        rmm2:
          'Run the installer using the instruction listed in the <a href="http://www.eps.com/help">deployment help</a>',
        download: 'Download',
        downloadWindows: 'Download Windows Agent (%{version})',
        pending: 'Pending Build',
        emailTemplate: 'Email Template',
        copy: 'Copy and Paste the text below into the body of the email:',
        windowsAgent: 'Windows Agent',
        manualStepDownload:
          'Click on Windows Agent <b>Download</b> (the installer), then click on the <b>Account Configuration</b> download (the configuration file). The configuration file used by the installer. Put these two files in the same folder.',
        manualStepRun: 'Run the <b>BarracudaContentShieldSetup</b> executable.',
        manualStepLicense: 'Accept the license agreement.',
        manualStepWizard: 'Follow prompts in the wizard to install.',
        rmmStepCampus:
          '<a class=%{linkClass} href="https://campus.barracuda.com/doc/79463711/">See Barracuda Campus</a>',
      },
      login: {
        title: 'Content Shield | Login',
        email: 'Email',
        signIn: 'Sign In',
        viewDemo: 'View Demo',
        systemStatus: 'Barracuda System Status',
        lowerText:
          'If you would like to take a free look at the Barracuda Content Shield demo, click the <b>View Demo</b> button.',
        signingIn: 'Signing In...',
        error: 'The email or password is invalid. Please try again.',
        unauthorized: 'This account is not authorized to use BCS services.',
        trial: 'Free 30-Day Trial',
        policyText:
          'By clicking “SIGN IN”, you agree to our <a class=%{linkClass} href=%{link} target="_blank">Privacy Policy</a>',
      },
      quarantine: {
        navtext: 'Quarantine',
        page_title: 'Content Shield | Quarantine',
        title: 'Quarantine',
        search: 'Search by Device, User, File Name, or File Extension',
        restore: 'Restore',
        delete_pending: 'Delete Pending',
        restore_pending: 'Restore Pending',
        modalText:
          'A command will be sent to the endpoint. This action cannot be undone.',
        fileName: 'File Name',
        restoreFile: 'Restore File',
        deleteFile: 'Delete File',
        restoreTooltip:
          'A restore command was sent to the endpoint. This action can take up to 2 hours to complete.',
        deleteTooltip:
          'A delete command was sent to the endpoint. This action can take up to 2 hours to complete.',
        maliciousTooltip:
          'This file was determined to be suspicious.  Review the scan report before releasing this file.',
        noResults: 'No files quarantined.',
        detected_on: 'Detected On',
      },
      footer: {
        copyright: '© 2019 Barracuda Networks, Inc. All rights reserved.',
        privacy: 'Privacy Policy',
        terms: 'Terms of Service',
        accountID: 'Account ID: %{accountID} ',
        serial: 'Serial Number: %{serial} ',
        version: 'Version: %{version} ',
      },
      reporting: {
        pageTitle: 'Content Shield | Reports',
        title: 'Reports',
        timeFrame: 'Time Frame',
        navtext: 'Reports',
        reportType: 'Report Type',
        lastSent: 'Last Sent',
        frequency: 'Frequency',
        emailTooltip:
          'At least one email address is required to create a scheduled report.',
        recipients: 'Email Recipients',
        headerText:
          'This page provides a list of reports with the following information, based on the <b>Time Frame</b> you select in the upper right of the page: Last 24 hours, Last 7 days, or Last 30 days.',
        monthlyHeader: 'Monthly on day %{day} at %{hour}:00 (%{tz})',
        weeklyHeader: 'Weekly on %{weekday} at %{hour}:00 (%{tz})',
        biweeklyHeader: 'Bi-weekly on %{weekday} at %{hour}:00 (%{tz})',
        dailyHeader: 'Daily at %{hour}:00 (%{tz})',
        hourlyHeader: 'Hourly',
        all: 'All Threats Seen',
        endpoints: 'Threats by Endpoints',
        users: 'Threats by Users',
        files: 'Threats by File Types',
        generated: 'Generated',
        atpReport: 'Malware Prevention Reports',
        basicReport: 'DNS Filtering Reports',
        blockedCategories: 'Blocked Categories',
        blockedSupercategories: 'Blocked Supercategories',
        superCategory: 'Supercategory',
        blockedRequests: 'Blocked Requests',
        pdfReport: 'PDF Report',
        csvReport: 'CSV Report',
      },
      notifications: {
        add: 'Add Alert',
        page_title: 'Content Shield | Alerts',
        navtext: 'Alerts',
        title: 'Alerts',
        create: 'Add Alert',
        edit: 'Edit Alert',
        noResults: 'No alerts configured',
        type: 'Type',
        recipients: 'Recipients',
        status: 'Status',
        more: 'More',
        category: 'Category',
        email_recipients: 'Email Recipients',
        alert_type: 'Alert Type',
        slack_hook: 'Slack Web Hook',
        notification_type: 'Notification Type',
        email_placeholder:
          'Enter one or more email recipients on separate lines.',
        slack_placeholder: 'Example: (URL)',
        delete_notification: 'Delete Alert',
        confirm_delete: 'Are you sure you want to delete this alert?',
        notificationTypeHelp:
          'Select the action from the drop-down for which you want to send out an alert; for example "Agent Installed".',
        emailRecipientsHelp:
          'Enter one or more <b>Email Recipients</b>, with each email address on a separate line in the text box.',
        device_installed: 'Agent Installed',
        threat_detected: 'Threat Detected',
        command_control: 'Command and Control Communications',
        blocked_category: 'Blocked Category',
        basicFiltering: 'DNS Filtering',
        advancedThreat: 'Advanced Threat Filtering',
        alertCondition: 'Alert Condition',
        agentsInstalled: 'Agents Installed',
        blockedCategory: 'Blocked Category',
        commandControl: 'Command and Control Communications',
        requests24Hours: 'Requests in 24 hours',
        threats24Hours: 'Threats in 24 hours',
        agentsInstalled24Hours: 'Agents Installed in 24 hours',
        addRecipients: 'Add Recipients',
        anyCategory: 'Any Category',
      },
      devices: {
        pageTitle: 'Content Shield | Devices',
        navtext: 'Endpoints',
        title: 'Endpoints',
        search: 'Search by endpoint name',
        info: 'Endpoint Info',
        name: 'Endpoint Name',
        lastSeen: 'Last Seen',
        version: 'Agent Version',
        device: 'Endpoint',
        os: 'Operating System',
        protection: 'Protection',
        threatsDetected: 'Threats Detected',
        lastThreatSeen: 'Last Threat Seen',
      },
      advancedFiltering: {
        createErrors: 'Failed to create one or more resources: (%{resources})',
        pageTitle: 'Content Shield | Advance Filtering',
        navtext: 'Advanced Filtering',
        title: 'Advanced Filtering',
        create: 'Add Rule',
        edit: 'Edit',
        type: 'Type',
        typeHelpText:
          'Choose to base filtering policies on categories, domains, or URLs.',
        action: 'Action',
        actionHelpText:
          'Choose the action (<i>block</i> or <i>allow</i>) to take with filtered traffic for the chosen <b>Type</b>.',
        domains: 'Domains',
        groups: 'Groups',
        urls: 'URLs',
        block: 'Block',
        allow: 'Allow',
        default: 'Default',
        allTraffic: 'All Traffic',
        categories: 'Categories',
        order: 'Order',
        rule: 'Rule',
        more: 'More',
        groupOrUser: 'Group or User',
        adPlaceholder:
          'Select one or more users or groups this rule should apply to',
        editRule: 'Edit Rule',
        moveTop: 'Move to Top',
        moveBottom: 'Move to Bottom',
        noRules: 'No Rules Configured',
        createRule: 'Create Rule',
        delete: 'Delete',
        editUsersGroups: 'Edit User or Group',
        errors: {
          emptyDomain: 'A domain is required',
          emptyUrl: 'A URL is required',
          emptyCategories: 'A Category is required',
        },
      },
      basicFiltering: {
        navtext: 'DNS Filtering',
        page_title: 'Content Shield | DNS Filtering',
        title: 'DNS Filtering',
        create: 'Add Location',
        headerText:
          'Create a Location to configure web security and content filtering settings. Choose from three preset policies (Low, Medium, and High filtering) or create a Custom policy by selecting individual categories that meet your organization\'s filtering requirements. Create Block and Allow exceptions for specific domains to manage them separately. For more information, refer to <a class=%{linkClass} href="https://campus.barracuda.com/doc/77401148/ " target="_blank">Barracuda Content Shield documentation</a> in Barracuda Campus.',
        outboundAddress: 'Outbound IP Address',
        cidr: 'CIDR',
        name: 'Name',
        type: 'Type',
        exception: 'Exception',
        domain: 'Domain',
        remove: 'Remove',
        categories: 'Categories',
        exceptions: 'Exceptions',
        noResults: 'Add a network to start using domain filtering',
        categoryPolicy: 'Category Policy',
        categoryPolicyHelp:
          "Select a filtering strategy, or <b>Category Policy</b>, depending on your organization's requirements. Select either one of the pre-configured <i>Low</i>, <i>Medium</i>, or <i>High</i> levels from the <b>Category Policy</b> dropdown, or select <i>Custom</i> to start from scratch. Any category that is CHECKED will be blocked.",
        exceptionPolicy: 'Exception Policy',
        exceptionPolicyHelp:
          'Optionally create block or allow exceptions to policies you have configured, by domain. For example, you might want to block the <i>Finance and Investments</i> category as your basic filtering policy, but allow access to specific domains your Finance department needs to access for work.',
        network: 'Network',
        policy: 'Web Filtering Policy',
        waiting: 'Waiting for internet traffic',
        noTraffic: 'No Traffic Seen',
        errorHelp:
          'Barracuda DNS nameservers have not detected traffic from this network. Configure the DNS nameserver IP addresses on your router or clients',
        warningHelp:
          'Barracuda DNS nameservers have not detected traffic in the last 5 days. Check your network DNS Nameservers to enforce the configured policies.',
        categoriesLink: 'Categories (%{cats})',
        exceptionLink: 'Exceptions (%{domains})',
        'adult-information': 'Adult Information',
        'commerce-shoping': 'Commerce & Shopping',
        education: 'Education',
        entertainment: 'Entertainment',
        'illegal-improper': 'Illegal or Improper',
        'news-information': 'News & Information',
        'online-communication': 'Online Communication',
        security: 'Security',
        'society-lifestyle': 'Society and Lifestyle',
        technology: 'Technology',
        'violence-weapons': 'Violence & Weapons',
        low:
          'Low (%{count}) - Security, Illegal Activity, Violence, Pornography, and Adult Content',
        medium:
          'Medium (%{count}) - Security, Illegal Activity, Violence, Media Sharing, Pornography',
        high:
          'High (%{count}) - Security, Illegal Activity, Violence, Gaming, Media Sharing, and Pornography',
        custom: 'Custom',
        recommendDefaults: 'Recommended Defaults',
        noExceptions: 'No domain exceptions configured.',
        blockTraffic: 'Block Traffic',
        allowTraffic: 'Allow Traffic',
        allExceptions: 'All Exceptions',
        domainPlaceholder: 'Search by domain',
        addDomainPlaceholder: 'Enter a domain name',
        show: 'Show',
        addDomain: 'Add Domain',
        networkName: 'Name (Optional)',
        networkNamePlaceholder: 'Enter a common name for this network',
        prefix: 'Prefix',
        configureDNS: 'Configure DNS',
        addOutboundPlaceholder: 'Example: 200.200.200.200',
        addOutbound: 'Add Outbound IP Address',
        noOutbound: 'Add one or more Outbound IP Addresses',
        networkNameHelp:
          'Optionally enter a name you want to use to identify the network; for example, <i>Finance Dept.</i>, <i>Students</i>, etc.',
        outboundAddressHelp:
          'The Outbound IP Address (also known as a "public IP address") can commonly be found on the <i>Status</i> or similar screen of most routers.',
        prefixHelp:
          'The Prefix shows the number of bits set in the subnet mask; for instance, if the subnet mask is 255.255.255.0, then there are 24 bits in the binary version of the subnet mask, so the prefix length is 24 bits.',
        dynamicIPLabel: 'Automatically update the outbound IP addresses',
        dynamicIPSub:
          'Install the dynamic IP updater on a single device in the network to sync changes to the outbound IP address. Recommended for environments with an outbound DHCP IP address.',
        staticIPLabel: 'Manually configure outbound IP addresses',
        staticIPSub:
          'Enter the IP address for each outbound IP address manually. Recommended for environments with static outbound IP addresses.',
        gettingStarted: 'Getting Started',
        configureInstructions:
          'In order to enforce your policy, send traffic from your network to the Content Shield DNS servers. Open the preferences for your router or device and change your DNS server settings to the Content Shield primary and secondary DNS servers listed below.',
        nameserverLabel: 'Barracuda DNS Nameserver',
        dnsBackupText:
          'NOTE:  Before changing both the primary and secondary DNS servers it is recommended that you make note of the original settings.',
        primary: 'Primary',
        secondary: 'Secondary',
        primaryDNSServer: 'Primary DNS Server',
        secondaryDNSServer: 'Secondary DNS Server',
        copyToClipboard: 'Copy to Clipboard',
        dynamicIPUpdater: 'Dynamic IP Updater',
        installationInstructions: 'Installation Instructions',
        installerTitle: 'Locally run the installer',
        localDownload: 'On the endpoint, download the installer.',
        localRun:
          'Run the installer on a single device in the network you are configuring.',
        localKey: 'When prompted, enter the key below.',
        rmmTitle: 'Install using RMM',
        rmmDownload: 'On the endpoint, download the installer.',
        rmmRun:
          'Run the installer, using the key found below. For more information see the <a class=%{linkClass} href="https://campus.barracuda.com/product/ContentShield/doc/78156683/dynamic-ip-address-updater-tool/" target="_blank">deployment help</a>.',
        windowsLink: 'Windows Dynamic IP Updater',
        downloadKey: 'Download Key',
        downloadInstaller: 'Download Installer',
        installerKey: 'Installer Key',
        dynamicIP: 'Dynamic IP',
        staticIP: 'Static IP',
        downloadUpdater: 'Download Dynamic IP Updater',
        activityLastSeen: 'Activity Last Seen',
        dnsInstructions:
          'Need instructions? <a class=%{linkClass} href="https://campus.barracuda.com/product/ContentShield/doc/77401148/how-to-configure-dns-filtering-policies/" target="_blank">Check our list of specific device instructions</a>',
      },
      wfLogs: {
        pageTitle: 'Content Shield | Web Filtering Logs',
        navtext: 'Web Filtering Logs',
        title: 'Web Filtering Logs',
        noResults: 'No Web Filtering Logs',
        action: 'Action',
        userId: 'User ID',
        rule: 'Rule',
        size: 'Size',
        date: 'Date',
        url: 'URL',
        search: 'Search',
        viewRule: 'View Rule',
        sourceIP: 'Source IP',
        userAgent: 'User Agent',
        contentType: 'Content Type',
        referrer: 'Referrer',
        destinationIP: 'Destination IP',
        destinationDomain: 'Destination Domain',
        supercategories: 'Supercategories',
        categories: 'Categories',
        supercategory: 'Supercategory',
        category: 'Category',
        location: 'Location',
        locationName: 'Location Name',
        denied: 'Blocked',
        allowed: 'Allowed',
        logDetails: 'Log Details',
        sourceDetails: 'Source Details',
        destinationDetails: 'Destination Details',
        userOrLocation: 'User ID/Location',
      },
    },
  },
};

export default translationsObject;
