import React from 'react';
import PropTypes from 'prop-types';
import { Translate, I18n } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { EmailIcon } from '../icons';
import FormField from './FormField';
import SessionAction from './SessionAction';
import Box from './Box';
import ActionButton from './ActionButton';

const styles = {
  subTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '300',
  },
  emailSent: {
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '15px',
  },
  formDiv: {
    padding: 8,
    width: '400px',
  },
  actionButton: {
    width: '100%',
    marginLeft: '30px',
    marginRight: '0',
  },
  signInLabel: {
    lineHeight: '20px',
    display: 'inline-block',
  },
  loginButton: {
    padding: '0 8px',
    minHeight: '20px',
  },
};

const NewUserTrial = ({
  classes,
  handleSubmit,
  updateField,
  handleLogin,
  form,
}) => (
  <Box
    data-test-id="new-user-trial-container"
    title={
      form.succeeded
        ? I18n.t('signup.startTrial.verifyEmailTitle')
        : I18n.t('signup.startTrial.formTitle')
    }
    subTitle={!form.succeeded && I18n.t('signup.startTrial.formSubTitle')}
  >
    {form.succeeded ? (
      <React.Fragment>
        <Grid item className={classes.emailSent} data-test-id="verify-text">
          <Translate
            value="signup.startTrial.verifyEmailText"
            email={form.fields.email}
          />
        </Grid>
        <Grid
          item
          className={classes.subTitle}
          data-test-id="verify-text-action"
        >
          <Translate value="signup.startTrial.verifyEmailTextAction" />
        </Grid>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <form onSubmit={handleSubmit}>
          <Grid item container justify="center" className={classes.formDiv}>
            <Grid item xs={11}>
              <FormField
                data-test-id="formfield-email"
                name="email"
                placeholder={I18n.t('signup.startTrial.emailAddress')}
                field={{
                  value: form.fields.email,
                  error: form.errors.email,
                }}
                icon=<EmailIcon />
                onChange={updateField}
              />
            </Grid>
            <Grid item xs={11}>
              <ActionButton
                data-test-id="btn-submit"
                type="submit"
                inProgress={form.submitting}
                inProgressText={I18n.t('components.selectAccount.submitting')}
                className={classes.actionButton}
              >
                <Translate value="common.submit" />
              </ActionButton>
            </Grid>
          </Grid>
        </form>
        <Grid item className={classes.loginTerms}>
          <SessionAction
            data-test-id="link-signup"
            label={I18n.t('signup.startTrial.alreadyHaveAccount')}
            buttonLabel={I18n.t('signup.startTrial.signIn')}
            buttonAction={handleLogin}
          />
        </Grid>
      </React.Fragment>
    )}
  </Box>
);

NewUserTrial.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'NewUserTrial' })(NewUserTrial);
