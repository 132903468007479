import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import CustomizedAxisTick from './CustomizedAxisTick';
import CustomizedTooltip from './CustomizedTooltip';

import moment from 'moment-timezone';

const styles = theme => ({
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const AreaComponent = ({
  classes,
  data,
  height = 400,
  graphType = 'month',
  sparseTick = true,
  toolTipName = 'Area',
}) => {
  const graphData = data.map((entry, i) => {
    return {
      ...entry,
      key_as_string: moment
        .tz(entry.key_as_string, 'America/Los_Angeles')
        .format('YYYY-MM-DD HH:mm:ss'),
      'Threat Count': entry['doc_count'],
      name: toolTipName,
    };
  });
  return (
    <ResponsiveContainer height={height}>
      {graphData.length > 0 ? (
        <AreaChart
          height={height}
          data={graphData}
          margin={{ top: 30, right: 30, left: 0, bottom: 0 }}
          stackOffset="expand"
          padding="20"
        >
          <XAxis
            fontSize="12"
            padding={{ left: 30, right: 50 }}
            dataKey="key_as_string"
            tick={
              <CustomizedAxisTick
                graphType={graphType}
                sparseTick={sparseTick}
              />
            }
            tickCount={1}
            interval={0}
          />
          <YAxis
            fontSize="12"
            tick={{ transform: 'translate(0, -7)' }}
            stroke="rgba(0,0,0,.2)"
            orientation={'right'}
            mirror={true}
            axisLine={false}
            allowDecimals={false}
          />
          <CartesianGrid vertical={false} />
          <Tooltip content={<CustomizedTooltip />} />
          <Area
            dataKey="Threat Count"
            stroke="#E34050"
            strokeWidth="2"
            fill="#E34050"
            fillOpacity="0.3"
            dot={{ strokeWidth: '1', fillOpacity: 1, fill: 'white' }}
            type="linear"
          />
        </AreaChart>
      ) : (
        <div className={classes.noResults}>
          <Translate value="shared.noResults" />
        </div>
      )}
    </ResponsiveContainer>
  );
};

AreaComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_count: PropTypes.number,
      key_as_string: PropTypes.string,
    })
  ).isRequired,
};

export default withStyles(styles, { name: 'AreaChart' })(AreaComponent);
