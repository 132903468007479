import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { I18n, Translate } from 'react-i18nify';

import TimeSelect from './TimeSelection';
import BlockedAccountsTable from '../../containers/overview/BlockedAccountsTable';

class TopBlockedAccounts extends Component {
  state = {
    selected: 'week',
    selectedIndex: 1,
  };

  changeRange = index => {
    this.props.onChange(this.options[index].key);
    this.setState({
      selected: this.options[index].key,
      selectedIndex: index,
    });
  };

  options = [
    {
      key: 'day',
      label: I18n.t('shared.ranges.day'),
    },
    {
      key: 'week',
      label: I18n.t('shared.ranges.week'),
    },
    {
      key: 'fortnight',
      label: I18n.t('shared.ranges.fortnight'),
    },
  ];

  render() {
    const { options, changeRange } = this;
    const { selected, selectedIndex } = this.state;
    const { classes } = this.props;
    const rangeValue = `shared.ranges.${selected}`;

    return (
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.titleWrapper}>
          <Translate
            tag="div"
            value="components.dashboard.topBlockedAccounts"
            className={classes.title}
          />
          <Translate
            tag="div"
            value={rangeValue}
            className={classes.subtitle}
          />
        </Grid>
        <Grid item xs={12} className={classes.tableWrapper}>
          <BlockedAccountsTable />
        </Grid>
        <Grid item xs={6} data-test-id="topBlockedSelectionWrapper">
          <TimeSelect
            options={options}
            selectedIndex={selectedIndex}
            onChange={changeRange}
          />
        </Grid>
        <Grid item xs={6} style={{ borderTop: '1px solid #DDDDDD' }} />
      </Grid>
    );
  }
}
TopBlockedAccounts.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  titleWrapper: {
    padding: '20px 20px 0 20px',
  },
  tableWrapper: {
    paddingTop: '40px',
    height: '300px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.5)',
  },
};

export default withStyles(styles, { name: 'TopBlockedAccounts' })(
  TopBlockedAccounts
);
