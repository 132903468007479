import { connect } from 'react-redux';
import Table from '../../components/dashboard/Table';
import { sortLogs, updateModule, downloadCsv } from '../../actions/dashboard';

const mapStateToProps = state => ({
  logs: state.dashboard.threatLogs,
  timeLabel: state.dashboard.timeLabel,
  account_id: state.account.selected,
  timezone: state.account.time_zone,
  loading: state.dashboard.threatLogsLoading,
  csvLoading: state.dashboard.csv.loading,
});

const mapDispatchToProps = dispatch => ({
  onSort: (order, property) => {
    dispatch(sortLogs(order, property));
  },
  changeTimerange: timeRange => {
    dispatch(updateModule('threatLogs', timeRange));
  },
  downloadCsv: range => {
    dispatch(downloadCsv(range));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
