import React from 'react';
import { Translate } from 'react-i18nify';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ForwardIcon from '@material-ui/icons/Forward';
import MoreVertIcon from '@material-ui/icons/MoreVert';

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, user, editUser, moveUser, removeUser, group } = this.props;
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <IconButton
          data-test-id="more-icon"
          aria-label="More"
          aria-owns={null}
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            data-test-id="edit-user"
            className={classes.menuOptions}
            onClick={() => {
              this.handleClose();
              editUser(user);
            }}
          >
            <EditIcon />
            <Translate value="userAuthentication.local.editUser" />
          </MenuItem>
          <MenuItem
            data-test-id="move-user"
            className={classes.menuOptions}
            onClick={() => {
              this.handleClose();
              moveUser(user, group.id);
            }}
          >
            <ForwardIcon />
            <Translate value="userAuthentication.local.moveToGroup" />
          </MenuItem>
          <MenuItem
            data-test-id="remove-user"
            className={classNames(classes.menuOptions, classes.deleteOption)}
            onClick={() => {
              this.handleClose();
              removeUser(user, group);
            }}
          >
            <DeleteIcon />
            <Translate value="userAuthentication.local.removeFromGroup" />
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  menuOptions: {
    color: '#5F5F5F',
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  deleteOption: {
    color: theme.statusColors.failure,
  },
});

export default withStyles(styles, { name: 'UserMenu' })(UserMenu);
